import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { Box, CircularProgress } from '@mui/material';
import ContentNode from '../../ContentNode';

const ChatColumnWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  will-change: ${props => props.isLoading ? 'contents' : 'auto'};
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  padding: 10px 0px;
  background-color: #121212;
  color: #ffffff;
  transition: all 0.1s;
  scroll-behavior: smooth;

`;

const LoadingIndicator = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 10px;
`;

const Footer = styled(Box)`
  height: 100px; // Adjust this value based on your needs
  width: 100%;
  flex-shrink: 0;
`;

const ThreadContentContainer = ({ thread }) => {
  const chatColumnRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [hasUserRecentlyScrolledUp, setHasUserRecentlyScrolledUp] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const previousScrollPositionRef = useRef(0);
  const lastChildrenCountRef = useRef(0);
  const [showFooter, setShowFooter] = useState(false);

  const isLoading = thread?.isLoading;

  const checkIfAtBottom = useCallback(() => {
    if (!chatColumnRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatColumnRef.current;
    const scrollThreshold = 150; // Increased threshold
    setIsAtBottom(scrollHeight - scrollTop - clientHeight <= scrollThreshold);
  }, []);

  const scrollToBottom = useCallback(() => {
    const chatColumn = chatColumnRef.current;
    if (chatColumn) {
      chatColumn.scrollTop = chatColumn.scrollHeight - chatColumn.clientHeight;
    }
  }, []);

  const handleUserScroll = useCallback(() => {
    const chatColumn = chatColumnRef.current;
    if (!chatColumn) return;

    const currentScrollPosition = chatColumn.scrollTop;
    if (currentScrollPosition < previousScrollPositionRef.current) {
      setHasUserRecentlyScrolledUp(true);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      scrollTimeoutRef.current = setTimeout(() => {
        setHasUserRecentlyScrolledUp(false);
      }, 500); // Reduced from 1000ms to 500ms
    }
    previousScrollPositionRef.current = currentScrollPosition;

    checkIfAtBottom();
  }, [checkIfAtBottom]);

  const updateFooterVisibility = useCallback(() => {
    if (!chatColumnRef.current) return;
    const { scrollHeight, clientHeight } = chatColumnRef.current;
    setShowFooter(scrollHeight > clientHeight * 0.9); // Show footer if content fills 90% of visible height
  }, []);

  useEffect(() => {
    const chatColumn = chatColumnRef.current;
    if (!chatColumn) return;

    checkIfAtBottom();
    updateFooterVisibility();

    const currentChildrenCount = thread.children.size;
    const hasNewMessages = currentChildrenCount > lastChildrenCountRef.current;
    lastChildrenCountRef.current = currentChildrenCount;

    if ((isAtBottom && !hasUserRecentlyScrolledUp) || hasNewMessages) {
      scrollToBottom();
    }

    chatColumn.addEventListener('scroll', handleUserScroll);
    const resizeObserver = new ResizeObserver(() => {
      updateFooterVisibility();
    });
    resizeObserver.observe(chatColumn);

    return () => {
      chatColumn.removeEventListener('scroll', handleUserScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [thread.children, isLoading, isAtBottom, hasUserRecentlyScrolledUp, checkIfAtBottom, scrollToBottom, handleUserScroll, updateFooterVisibility]);

  return (
    <ChatColumnWrapper ref={chatColumnRef} isLoading={isLoading} className="thread-content-container-column-wrapper">
      {Array.from(thread.children.values()).map((node) => (
        <ContentNode
          key={node.node_id}
          node={node}
        />
      ))}
      {isLoading && (
        <LoadingIndicator>
          <CircularProgress size={24} />
        </LoadingIndicator>
      )}
      {showFooter && <Footer />}
    </ChatColumnWrapper>
  );
};

ThreadContentContainer.displayName = 'ThreadContentContainer';

export default ThreadContentContainer;