import React, { useRef, useLayoutEffect, useState } from 'react';
import { Box } from '@mui/material';
import ContentNode from '../ContentNode';
import styled from '@emotion/styled';
import { getNodeDepth } from '../../../lib/nodeUtils';
import { useCollectionFunctions } from '../../../contexts/ChatContext';

const OuterContainer = styled(Box)`
  display: flex;
  justify-content: ${props => props.totalWidth < props.width ? 'center' : 'flex-start'};
  width: ${props => props.width};
  will-change: width;
  transform: translateZ(0);
  max-width: 100%;
  max-height: ${props => `calc(100vh - 55px - ${props.depthAdjustment}vh)`};
  min-height: 600px;
  height: 100%;
  flex-grow: 1;
  transition: all 0.1s;
`;

const ScrollableBox = styled(Box)`
  scrollbar-color: #555 #333;
  will-change: width;
  transform: translateZ(0);
  transition: all 0.1s;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
    border: 2px solid #333;
  }
`;

const InnerScrollableContainer = styled(ScrollableBox)`
  display: flex;
  overflow-x: auto;
  max-width: 100%;
  scroll-behavior: smooth;
  will-change: width;
  transform: translateZ(0);
  transition: all 0.1s;
`;

const ContentContainer = styled(Box)`
  display: flex;
  min-width: fit-content;
  will-change: width;
  transform: translateZ(0);
  transition: all 0.1s;
`;

const ThreadContainerNode = React.memo(({ node }) => {
    const scrollContainerRef = useRef(null);
    const [prevChildrenCount, setPrevChildrenCount] = useState(0);

    const { deleteNode } = useCollectionFunctions();

    const totalWidth = node?.children ? Array.from(node.children.values()).reduce((sum, child) => {
        return sum + (child?.width || 0);
    }, 0) : 0;

    const visibleWidth = Math.min(totalWidth, node?.width || 0);

    const depth = getNodeDepth(node?.node_id || '');

    const depthAdjustment = (() => {
        if (depth === 0) return 0;
        const deltaPerLevel = 15;
        return Math.min((depth - 1) * deltaPerLevel, 60);
    })();

    useLayoutEffect(() => {
        if (!node?.children) return;
        const currentChildrenCount = node.children.size;
        if (currentChildrenCount > prevChildrenCount && scrollContainerRef.current) {
            // Wait for transition to finish
            setTimeout(() => {
                if (scrollContainerRef.current) {
                    scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth;
                }
            }, 150); // Adjust this delay as needed
        }
        setPrevChildrenCount(currentChildrenCount);

        if (currentChildrenCount === 0 && depth > 1) {
            deleteNode(node.node_id);
        }
    }, [node?.children, prevChildrenCount, depth, deleteNode, node.node_id]);

    if (!node || !node.children || node.children.size === 0) {
        return null;
    }

    return (
        <OuterContainer
            width={node.width}
            totalWidth={totalWidth}
            depth={depth}
            depthAdjustment={depthAdjustment}
            className="thread-container-outer"
        >
            <InnerScrollableContainer
                ref={scrollContainerRef}
                visibleWidth={visibleWidth}
                className="thread-container-scrollable"
            >
                <ContentContainer className="thread-container-content">
                    {Array.from(node.children.entries()).map(([childId, childNode]) => (
                        childNode ? (
                            <ContentNode
                                key={childId}
                                node={childNode}
                            />
                        ) : null
                    ))}
                </ContentContainer>
            </InnerScrollableContainer>
        </OuterContainer>
    );
});

ThreadContainerNode.displayName = 'ThreadContainerNode';

export default ThreadContainerNode;