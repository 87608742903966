export const logEvent = async (level, event, message, data) => {
    try {
        const logEntry = {
            level,
            event,
            message,
            data,
        };

        await fetch(process.env.REACT_APP_LOGGING_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logEntry),
        });
    } catch (error) {
        console.error(`Failed to log ${event} event:`, error);
    }
};



// // In a new file, e.g., logger.js
// export const LogLevel = {
//     DEBUG: 0,
//     INFO: 1,
//     WARN: 2,
//     ERROR: 3
//   };
  
//   let CURRENT_LOG_LEVEL = LogLevel.INFO;
  
//   export const setLogLevel = (level) => {
//     CURRENT_LOG_LEVEL = level;
//   };
  
//   export const log = (level, ...args) => {
//     if (level >= CURRENT_LOG_LEVEL) {
//       console.log(...args);
//     }
//   };
  
//   // Usage in ChatContext.js
//   import { log, LogLevel, setLogLevel } from './logger';
  
//   // Set log level based on environment
//   setLogLevel(process.env.NODE_ENV === 'production' ? LogLevel.ERROR : LogLevel.DEBUG);
  
//   // Use throughout the code
