import React from 'react';
import ClusterNode from './ClusterNode/ClusterNode';
import SearchResultNode from './ResultNode/SearchResultNode';

const SearchNode = ({ node }) => {
    if (node.type.startsWith('content.search.cluster')) {
        return <ClusterNode cluster={node} />;
    } else if (node.type.startsWith('content.search.result')) {
        return <SearchResultNode result={node} />;
    } else {
        return null;
    }
};

export default SearchNode;