import React from 'react';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';

const SearchBarIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
  background-color: #1e1e1e;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
`;

const SearchBarIcon = ({ onClick }) => {
    return (
        <SearchBarIconWrapper onClick={onClick}>
            <SearchIcon />
        </SearchBarIconWrapper>
    );
};

export default SearchBarIcon;