
const levels = {
    trace: 0,
    debug: 1,
    info: 2,
    warn: 3,
    error: 4,
    fatal: 5
};

const getDefaultLevel = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return 'debug';
        case 'test':
            return 'info';
        case 'production':
        default:
            return 'warn';
    }
};

const debugLoggingConfig = {
    enabled: true,
    level: getDefaultLevel(),
};

export const debugLogger = {
    log: (level, message, payload = null) => {
        if (!debugLoggingConfig.enabled || levels[level] < levels[debugLoggingConfig.level]) {
            return;
        }

        const prettyPrintNestedMaps = (data) => {
            if (data instanceof Map) {
                const obj = {};
                for (const [key, value] of data) {
                    obj[key] = prettyPrintNestedMaps(value);
                }
                return obj;
            } else if (Array.isArray(data)) {
                return data.map(prettyPrintNestedMaps);
            } else if (typeof data === 'object' && data !== null) {
                const obj = {};
                for (const key in data) {
                    obj[key] = prettyPrintNestedMaps(data[key]);
                }
                return obj;
            } else {
                return data;
            }
        };

        const formattedPayload = payload ? prettyPrintNestedMaps(payload) : null;
        const output = formattedPayload
            ? `${level.toUpperCase()}: ${message} - ${JSON.stringify(formattedPayload, null, 2)}`
            : `${level.toUpperCase()}: ${message}`;
        switch (level) {
            case 'trace':
            case 'debug':
                console.debug(`[${level.toUpperCase()}]`, output);
                break;
            case 'info':
                console.info(`[INFO]`, output);
                break;
            case 'warn':
                console.warn(`[WARN]`, output);
                break;
            case 'error':
            case 'fatal':
                console.error(`[${level.toUpperCase()}]`, output);
                break;
            default:
                console.log(`[${level.toUpperCase()}]`, output);
        }
    }
};