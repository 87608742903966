import React, { memo, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, Tooltip, Typography, Icon, Upload } from '@mui/material';
import { useChatContext } from '../../../../contexts/ChatContext';
import { createCollection, createBlankDocumentNode, createThreadContainerNode, createThread } from '../../../../graph/utils/nodeConstructors';
import URLUploadPopover from './URLUploadPopover';
import { useUIContext } from '../../../../contexts/UIContext';
import { useCollectionActionbarFunctions } from '../../../../contexts/ChatContext';
const ActionBarWrapper = styled.div`
  position: absolute;
  bottom: ${props => props.bottom || '0px'};
  right: ${props => props.right || '0px'};
  display: flex;
  background-color: rgba(30, 30, 30, 0.8);
  border-radius: 10px;
  padding: 4px;
  z-index: 10;
`;

const ButtonLabel = styled(Typography)`
  font-size: 0.65rem;
  margin-top: 0px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0px;
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 1.5rem;
  color: white;
`;

const ThreadBottomActionBar = memo(({ threadId, bottom, right }) => {
    const { isFounderModeEnabled } = useUIContext();
    const [urlPopoverAnchorEl, setUrlPopoverAnchorEl] = useState(null);

    const { addNode, selectNode, handleFileUpload, handleProjectUpload } = useCollectionActionbarFunctions();

    const handleAddThread = () => {
        const newThreadContainer = createThreadContainerNode(threadId);
        const newThread = createThread(newThreadContainer.node_id);
        newThreadContainer.children.set(newThread.node_id, newThread);
        addNode(threadId, newThreadContainer);
        selectNode(newThread.node_id);
    };

    const handleAddCollection = () => {
        const newCollection = createCollection(threadId);
        addNode(threadId, newCollection);
        selectNode(newCollection.node_id);
    };

    const handleAddDocument = () => {
        const newDocument = createBlankDocumentNode(threadId);
        addNode(threadId, newDocument);
        selectNode(newDocument.node_id);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        handleFileUpload(files, threadId);
    };

    const triggerHandleProjectUpload = () => {
        // This function will be implemented in ChatContext
        handleProjectUpload(threadId);
    };

    const handleOpenUrlPopover = (event) => {
        setUrlPopoverAnchorEl(event.currentTarget);
    };

    const handleCloseUrlPopover = () => {
        setUrlPopoverAnchorEl(null);
    };

    return (
        <ActionBarWrapper bottom={bottom} right={right}>
            <URLUploadPopover
                anchorEl={urlPopoverAnchorEl}
                onClose={handleCloseUrlPopover}
                threadId={threadId}
            />


            <Tooltip title="New Collection">
                <StyledIconButton onClick={handleAddCollection} size="small">
                    <StyledIcon className="material-symbols-outlined">create_new_folder</StyledIcon>
                    <ButtonLabel>Collection</ButtonLabel>
                </StyledIconButton>
            </Tooltip>
            <Tooltip title="New Document">
                <StyledIconButton onClick={handleAddDocument} size="small">
                    <StyledIcon className="material-symbols-outlined">edit_stylus</StyledIcon>
                    <ButtonLabel>Snippet</ButtonLabel>
                </StyledIconButton>
            </Tooltip>
            <Tooltip title="Upload Files">
                <StyledIconButton component="label" size="small">
                    <StyledIcon className="material-symbols-outlined">upload</StyledIcon>
                    <ButtonLabel>Files</ButtonLabel>
                    <input type="file" multiple hidden onChange={handleFileChange} />
                </StyledIconButton>
            </Tooltip>
            <Tooltip title="Upload URLs">
                <StyledIconButton onClick={handleOpenUrlPopover} size="small">
                    <StyledIcon className="material-symbols-outlined">captive_portal</StyledIcon>
                    <ButtonLabel>URL</ButtonLabel>
                </StyledIconButton>
            </Tooltip>
            {(isFounderModeEnabled) && (
                <>
                    <Tooltip title="Upload Project">
                        <StyledIconButton onClick={triggerHandleProjectUpload} size="small">
                            <StyledIcon className="material-symbols-outlined">folder_open</StyledIcon>
                            <ButtonLabel>Project</ButtonLabel>
                        </StyledIconButton>
                    </Tooltip>
                    <Tooltip title="New Thread">
                        <StyledIconButton onClick={handleAddThread} size="small">
                            <StyledIcon className="material-symbols-outlined">add_comment</StyledIcon>
                            <ButtonLabel>Thread</ButtonLabel>
                        </StyledIconButton>
                    </Tooltip>
                </>
            )}
        </ActionBarWrapper>
    );
});

export default ThreadBottomActionBar;