import React, { useContext, useRef, useEffect, useLayoutEffect, useMemo, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { throttle, debounce } from 'lodash';
import styled from '@emotion/styled';

import ChatNode from '../StickshiftNode/Chat/ChatNode';
import ThreadContainerNode from '../StickshiftNode/Chat/ThreadContainerNode';
import ChatRightActionBar from './RightActionBar/ChatRightActionBar';
import { UIContext } from '../../contexts/UIContext';
import { useRootThreadContainer } from '../../contexts/RootThreadContainerContext';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { debugLogger } from '../../lib/debugLogger';

const EXPANDED_SIDEBAR_WIDTH = 300;
const COLLAPSED_SIDEBAR_WIDTH = 50;

const ChatComponentWrapper = styled.div`
  position: relative;
  transition: all 0.1s;
`;

const FlexContainer = styled(Box)`
  display: flex;
  height: calc(100vh - 55px);
  width: 100%;
  transition: all 0.1s;
`;

const LeftContainer = styled(Box)`
  flex-grow: 1;
  overflow: hidden;
  max-width: 100%;
  transition: all 0.1s;
`;

const RightSidebar = styled(Box)`
  width: ${props => props.sidebarWidth}px;
  flex-shrink: 0;
  transition: width 0.3s ease-in-out, all 0.1s;
`;

const ChatComponent = () => {
    const { isMobile, isChatRightActionBarOpen, setChatAvailableWidth, setChatAvailableHeight } = useContext(UIContext);
    const leftContainerRef = useRef(null);
    const { dimensions: { width: leftContainerWidth, height: leftContainerHeight }, isResizing } = useResizeObserver(leftContainerRef);
    const { rootThreadContainerState, updateNodeWidths } = useRootThreadContainer();
    const initialDimensionsSetRef = useRef(false);

    const sidebarWidth = isChatRightActionBarOpen ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH;

    const setDimensions = useCallback((width, height) => {
        setChatAvailableWidth(width);
        setChatAvailableHeight(height);
        // console.log('[ChatComponent] setDimensions', { width, height });
    }, [setChatAvailableWidth, setChatAvailableHeight]);

    const debouncedSetDimensions = useCallback(
        debounce(setDimensions, 100),
        [setDimensions]
    );

    useEffect(() => {
        return () => {
            debouncedSetDimensions.cancel();
        };
    }, [debouncedSetDimensions]);

    useLayoutEffect(() => {
        if (!initialDimensionsSetRef.current && leftContainerWidth && leftContainerHeight) {
            setDimensions(leftContainerWidth - 3, leftContainerHeight - 3); // leave a bit of padding to avoid clipping
            initialDimensionsSetRef.current = true;
            // console.log('[ChatComponent] initial useLayoutEffect to set dimensions', { leftContainerWidth, leftContainerHeight });
        } else {
            // setDimensions(leftContainerWidth, leftContainerHeight);

            debouncedSetDimensions(leftContainerWidth, leftContainerHeight);
        }

        // TODO: not actually sure this is necessary, seems like we have a useEffect on chatAvailableWidth in the state context
        updateNodeWidths();
    }, [leftContainerWidth, leftContainerHeight, setDimensions, debouncedSetDimensions, updateNodeWidths]);

    return (
        <ChatComponentWrapper className="chat-component-wrapper">
            <FlexContainer>
                <LeftContainer ref={leftContainerRef} className="chat-component-thread-container-wrapper">
                    {rootThreadContainerState && (
                        <ThreadContainerNode
                            node={rootThreadContainerState}
                        />
                    )}
                </LeftContainer>
                {!isMobile && (
                    <RightSidebar sidebarWidth={sidebarWidth}>
                        <ChatRightActionBar />
                    </RightSidebar>
                )}
            </FlexContainer>
        </ChatComponentWrapper>
    );
};

export default ChatComponent;