import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const LogoComponent = ({ showText = true }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      <img
        src="/logo_alpha.png"
        alt="Logo"
        loading="lazy"
        style={{
          margin: '0px 5px 0px 5px',
          height: '3rem',
          width: '3rem',
          objectFit: 'contain',
        }}
      />
      {showText && (
        <Typography variant="h6" style={{
          fontFamily: 'Space Grotesk, sans-serif',
          letterSpacing: '0.25rem',
          fontWeight: 'bold',
          fontSize: '1.25rem',
          paddingTop: '3px',
        }}>
          STICKSHIFT
        </Typography>
      )}
    </Box>
  );
};

export default LogoComponent;