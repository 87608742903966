// components/ui/src/components/LLMJudgementRankAdjuster.js

import React, { useContext, useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';

import { SearchResultsContext } from '../../../../contexts/SearchResultsContext';
import { useRankAdjusterData } from '../../../../hooks/useRankAdjusterData';

import VerticalSlider from './VerticalSlider';

const StyledRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  font-size: 0.5rem;
`;

const StyledSlider = styled(Slider)`
  height: 120px;
`;

const LLMJudgementRankAdjusterComponent = () => {
  const [expanded, setExpanded] = useState(true);

  // TODO maybe isRankAdjusterLoading should be in useRankAdjusterData
  const { urls, rankAdjustments, setRankAdjustments } = useRankAdjusterData();
  const { getLLMJudgements, isRankAdjusterLoading, setIsRankAdjusterLoading } = useContext(SearchResultsContext);

  const [conditionString, setConditionString] = useState('');

  const [placeholder, setPlaceholder] = useState('uprank results that meet a certain criteria');
  const placeholders = ['must not be some spammy SEO crap', 'serious academic discussion',
    'must have a casual style', 'must contain a list of countries ranked by GDP',
    'must not be some generic undergrad tier shit'];

  const handleSliderChange = (event, newValue) => {
    // TODO: fix this- broke it after the refactor to split up context anr reranking.js
    // const newRankAdjustments = { ...rankAdjustments, passes_llm_judgement: newValue };
    // setRankAdjustments(newRankAdjustments);
  };

  const handleTextChange = (event) => {
    setConditionString(event.target.value);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await getLLMJudgements(urls, conditionString);
    console.log("done getting judgements")
  };

  // Update placeholder every few seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholder(prevPlaceholder => {
        const currentIndex = placeholders.indexOf(prevPlaceholder);
        const nextIndex = (currentIndex + 1) % placeholders.length;
        return placeholders[nextIndex];
      });
    }, 7000); // Change placeholder every 3 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  const name = "passes_llm_judgement"

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="more info"
              sx={{ color: '#fff', textTransform: 'none' }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" sx={{ fontSize: '0.95rem' }} color="textPrimary">OpenAI</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            {/* <Typography variant="h6" color="textPrimary">{rankAdjustments[name] >= 0 ? `+${rankAdjustments[name]}` : rankAdjustments[name]}</Typography> */}
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <form onSubmit={handleSubmit}>
              <StyledTextField
                multiline
                rows={4}
                variant="outlined"
                inputProps={{ style: { fontSize: '0.8rem' } }}
                fullWidth
                value={conditionString}
                placeholder={placeholder}
                onChange={handleTextChange}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleSubmit(ev);
                    ev.preventDefault();
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'blue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'blue',
                    },
                  },
                }}
              />
              <Button type="submit" disabled={isRankAdjusterLoading[name]}>
                Submit
              </Button>
              {isRankAdjusterLoading[name] && <CircularProgress size={24} />}
            </form>
          </Collapse>
        </Grid>
        <Grid item xs={2}>
          <VerticalSlider
            // initialValue={rankAdjustments['passes_llm_judgement']}
            initialValue={0}
            onChange={handleSliderChange}
          />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default LLMJudgementRankAdjusterComponent;