import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextField, Button, Typography, Container, Box, Paper, Link } from '@mui/material';
import { spacing } from '@mui/system';
import axios from 'axios';

import PasswordStrengthBar from './components/Account/PasswordStrengthBar';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #121212;
  padding: ${spacing(4)}px;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing(6)}px;
  max-width: 400px;
  width: 100%;
  background-color: #1e1e1e;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${spacing(4)}px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: ${spacing(3)}px;
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing(4)}px;
  padding: ${spacing(1.5)}px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background-color: #3f51b5;
  border-radius: 24px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #303f9f;
  }
`;

const StyledLink = styled(Link)`
  margin-top: ${spacing(2)}px;
  color: #3f51b5;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;


const SignUpPage = () => {
    const history = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { register } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await register({ name, email, password });
            history.push('/login');
        } catch (error) {
            console.error('Error registering user:', error);
        }
    };

    return (
        <StyledContainer>
            <StyledPaper>
                <Typography variant="h4" align="center" gutterBottom>
                    Sign Up
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary" paragraph>
                    Create an account to get started
                </Typography>
                <StyledForm onSubmit={handleSubmit}>
                    <StyledTextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="name"
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                        InputLabelProps={{
                            style: { color: '#999' },
                        }}
                    />
                    <StyledTextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        type="email"
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                        InputLabelProps={{
                            style: { color: '#999' },
                        }}
                    />
                    <StyledTextField
                        label="Password"
                        variant="outlined"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="new-password"
                        type="password"
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                        InputLabelProps={{
                            style: { color: '#999' },
                        }}
                    />
                    <PasswordStrengthBar password={password} />
                    <Typography variant="body2" color="textSecondary" align="center" paragraph>
                        For enhanced security, consider using multiple words and a password manager.
                    </Typography>

                    <StyledButton type="submit" fullWidth>
                        Sign Up
                    </StyledButton>
                </StyledForm>
                <Box mt={4}>
                    <Typography variant="body2" align="center" style={{ color: '#ccc' }}>
                        Already have an account?{' '}
                        <StyledLink href="/login" color="primary">
                            Log In
                        </StyledLink>
                    </Typography>
                </Box>
            </StyledPaper>
        </StyledContainer>
    );
};

export default SignUpPage;