import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LandingSearchBar from './components/LandingPage/LandingSearchBar';
import LandingChatBar from './components/LandingPage/LandingChatBar';
import { UIContext } from './contexts/UIContext';
import { ChatContext } from './contexts/ChatContext';
import { Link, Tooltip } from '@mui/material';


const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #121212;
    min-height: 100vh;
    max-width: 100vw;
    padding: 2rem 1rem;
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 600px;
    width: 100%;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

const Logo = styled.img`
    max-width: 4rem;
    margin-right: 0.5rem;
`;

const Title = styled.h1`
    font-family: 'Space Grotesk', sans-serif;
    color: #ffffff;
    font-weight: bold;
    font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '3rem')};
    letter-spacing: 0.75rem;
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
`;

const SearchArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatArea = styled.div`
    width: 100%;
`;

const Subtitle = styled.h3`
    color: #ffffff;
    margin-bottom: 0.5rem;
    font-weight: 300;

`;

const SearchModeToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

const ToggleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
  padding: 0.125rem;
  width: 250px;
`;

const StyledToggleButton = styled(ToggleButton)`
  text-transform: none;
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  &.Mui-selected {
    background-color: #4a4a4a;
    color: #ffffff;
  }
`;

const BottomLink = styled(Link)`
  margin-top: 2rem;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const LandingPage = () => {
    const history = useHistory();
    const { isMobile } = useContext(UIContext);
    const { initialPromptRef } = useContext(ChatContext);
    const chatMode = 'chat';
    const defaultSearchMode = 'search';
    const [selectedSearchMode, setSelectedSearchMode] = useState(defaultSearchMode);

    const handleSearch = (query) => {
        history.replace(`/engine?query=${encodeURIComponent(query)}&mode=${selectedSearchMode}`);
    };

    const handleChat = (initialPrompt) => {
        initialPromptRef.current = initialPrompt;
        history.replace(`/engine?mode=${chatMode}`);
    };

    const handleToggle = () => {
        setSelectedSearchMode(selectedSearchMode === 'search' ? 'search_plus_chat' : 'search');
    };

    const handleFancyClick = () => {
        history.replace(`/engine?mode=${chatMode}`);
    };

    return (
        <LandingPageContainer>
            <MainContent>
                {/* Logo Area */}
                <LogoContainer>
                    <Logo src="/logo_alpha.png" alt="Logo" loading="lazy" />
                    <Title isMobile={isMobile}>STICKSHIFT</Title>
                </LogoContainer>

                {/* Search Area */}
                <SearchArea>
                    <SearchHeader>
                        <Subtitle>Search Google + Bing</Subtitle>
                        {!isMobile && (
                            <SearchModeToggle>
                                <ToggleBox>
                                    <ToggleButtonGroup
                                        value={selectedSearchMode}
                                        exclusive
                                        onChange={handleToggle}
                                        aria-label="search mode"
                                    >
                                        <StyledToggleButton value="search">Just search</StyledToggleButton>
                                        <StyledToggleButton value="search_plus_chat">Initialize Chat Panel</StyledToggleButton>
                                    </ToggleButtonGroup>
                                </ToggleBox>
                            </SearchModeToggle>
                        )}
                    </SearchHeader>
                    <LandingSearchBar onSearch={handleSearch} />
                </SearchArea>

                {/* Chat Area */}
                <ChatArea>
                    <Subtitle>Chat with an AI</Subtitle>
                    <LandingChatBar onSubmit={handleChat} />
                </ChatArea>


                <BottomLink onClick={handleFancyClick}>
                    Skip to main page
                </BottomLink>
            </MainContent>
        </LandingPageContainer>
    );
};

export default LandingPage;