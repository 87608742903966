import React, { useContext, useCallback, useMemo } from 'react';
import { ToggleButtonGroup, ToggleButton as MuiToggleButton, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import ActionButton from '../../common/ActionButton';
import { ChatContext } from '../../../contexts/ChatContext';
import { UIContext } from '../../../contexts/UIContext';
import CollapsibleSection from './CollapsibleSection';

const StyledToggleButton = styled(MuiToggleButton)`
  max-height: 2.5rem;
  flex-grow: 1;
`;

const ActionButtonsBox = styled(Box)`
  max-width: 140px; // Adjust this value as needed
  width: 100%;
`;

const ChatActions = React.memo(() => {
    const { clearMessages, addThread } = useContext(ChatContext);
    const {
        isFounderModeEnabled,
        globalWidthMultiplierIndex,
        updateGlobalWidthMultiplier,
        globalWidthMultiplierOptions
    } = useContext(UIContext);

    const handleNewThread = useCallback(() => {
        addThread();
    }, [addThread]);

    const handleWidthMultiplierChange = useCallback((event, newIndex) => {
        if (newIndex !== null) {
            updateGlobalWidthMultiplier(newIndex);
        }
    }, [updateGlobalWidthMultiplier]);

    // Memoize WidthMultiplierControls
    const WidthMultiplierControls = useMemo(() => {
        return (
            <CollapsibleSection title="Width" openByDefault={true}>
                <ToggleButtonGroup
                    value={globalWidthMultiplierIndex}
                    exclusive
                    onChange={handleWidthMultiplierChange}
                    aria-label="width multiplier"
                    fullWidth
                >
                    {Array.from(globalWidthMultiplierOptions.keys()).map((index) => (
                        <StyledToggleButton key={index} value={index} aria-label={`multiplier ${index}`}>
                            {index}
                        </StyledToggleButton>
                    ))}
                </ToggleButtonGroup>
            </CollapsibleSection>
        );
    }, [globalWidthMultiplierIndex, handleWidthMultiplierChange, globalWidthMultiplierOptions]);

    return (
        <>
            <CollapsibleSection title="Chat actions" openByDefault={true}>
                <ActionButtonsBox>
                    <ActionButton onClick={clearMessages}>
                        <span className="material-symbols-outlined">delete_forever</span> Clear all
                    </ActionButton>
                    {false && isFounderModeEnabled && (
                        <>
                            <ActionButton>
                                <span className="material-symbols-outlined">density_small</span> View outline
                            </ActionButton>
                            <ActionButton>Compress context</ActionButton>
                        </>
                    )}
                    <ActionButton onClick={handleNewThread}>
                        <span className="material-symbols-outlined">splitscreen_vertical_add</span> New thread
                    </ActionButton>
                </ActionButtonsBox>
            </CollapsibleSection>

            {WidthMultiplierControls}
        </>
    );
});

export default ChatActions;