import React from 'react';
import { Box, Typography } from '@mui/material';

const ResultSiteInfo = ({ result, showDate }) => {
    const showFullURL = false;

    const cleanUrl = (url) => {
        const maxChars = 70;
        let trimmedUrl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        trimmedUrl = trimmedUrl.length > maxChars ? url.substring(0, maxChars) + "..." : url;
        return trimmedUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
                display: 'flex', alignItems: 'center', backgroundColor: '#dfe0ed',
                borderRadius: '6px', width: 'fit-content', paddingRight: '4px'
            }}>
                <img
                    src={`https://www.google.com/s2/favicons?domain=${result.url}`}
                    alt="Favicon"
                    loading="lazy"
                    style={{ width: '14px', height: '14px', marginRight: '8px' }}
                />
                <Typography sx={{
                    fontSize: "0.7rem",
                    margin: "0px",
                    padding: "0px 0px",
                    color: '#484b51',
                    '& > *': {
                        fontSize: '0.7rem',
                    },
                }}>
                    {showFullURL ? cleanUrl(result.url) : result.domain}
                </Typography>
            </Box>
            {(showDate && result.datePublishedDisplayText || result.datePublishedFreshnessText) && (
                <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.7rem', marginLeft: '8px' }}>
                    {result.datePublishedFreshnessText || result.datePublishedDisplayText}
                </Typography>
            )}
        </Box>
    );
};

export default ResultSiteInfo;