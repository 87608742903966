import React, { useState, useRef } from 'react';
import Slider from '@mui/material/Slider';

// used to apply postive boosts or negative demotions to the search results
// green track when positive weight selected, red track when negative weight selected
const VerticalSlider = ({ value, onChange, min = -500, max = 500, step = 100 }) => {
  const sliderRef = useRef(null);
  const [localValue, setLocalValue] = useState(value);

  const handleChange = (e, newValue) => {
    setLocalValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleChangeCommitted = (e, newValue) => {
    if (onChange) onChange(newValue);
  };


  const handleClick = (e) => {
    if (sliderRef.current && !e.target.classList.contains('MuiSlider-thumb')) {
      const sliderRect = sliderRef.current.getBoundingClientRect();
      const clickY = e.clientY - sliderRect.top;
      const sliderHeight = sliderRect.height;
      const clickPosition = (sliderHeight - clickY) / sliderHeight;

      let newValue;
      if (clickPosition < 0.4) {
        newValue = min;
      } else if (clickPosition > 0.6) {
        newValue = max;
      } else {
        newValue = 0;
      }

      setLocalValue(newValue);
      if (onChange) onChange(newValue);
    }
  };

  const trackContainerStyle = {
    display: 'flex',
    flexDirection: 'column', // Align items vertically
    alignItems: 'center', // Center align the children
    height: '125px', // Adjusted height to accommodate + and - indicators
    position: 'relative',
    width: '40px', // Adjusted width for the custom thumb and indicators
    background: 'transparent',
    cursor: 'pointer', // Add cursor style to indicate clickability

  };

  const indicatorStyle = {
    width: '10px', // Width of the indicators
    position: 'absolute',

  };

  const isPositive = value > 0;
  const isNegative = value < 0;

  let displayValue = value;
  if (isPositive) {
    displayValue = `+${value}`;
  }

  // Calculate the height of the positive and negative indicators on the track
  const trackContainerHeight = 125; // Total height of the track container
  const symbolHeight = 20 * 0.8; // Height of the + or - symbol including any margin (hacky- was 20 with 1rem, now using 0.8rem)
  const thumbHeight = 10; // Height of the thumb

  // Available height for the slider track itself
  const sliderTrackHeight = (trackContainerHeight - (symbolHeight * 2)) / 2

  // Calculate the height as a percentage of the slider track height
  let heightFactor = (sliderTrackHeight - thumbHeight) / 100;
  if (min === 0) {
    heightFactor = heightFactor * 2;
  }
  // TODO: else totally separate math for the only positivie case

  // const valueDivider = 5 
  const valueDivider = max / 100; // Make it range from 0-100ish or whatever it is (for the height math to work out)
  const positiveIndicatorHeight = isPositive ? `${(value / valueDivider) * heightFactor}px` : '0px';
  const negativeIndicatorHeight = isNegative ? `${Math.abs((value / valueDivider)) * heightFactor}px` : '0px';

  let positiveIndicatorBottom;
  if (min === 0) {
    positiveIndicatorBottom = '18%'; // TODO this is awful and hacky. recalculate the bottom the right way
  } else {
    positiveIndicatorBottom = '50%';
  }
  const minValueColor = min < 0 ? 'red' : 'rgb(212, 215, 221)';

  return (
    <div style={trackContainerStyle} onClick={handleClick} ref={sliderRef}>
      <div style={{ color: 'green', marginBottom: '5px', fontSize: '0.8rem' }}>+{max}</div>
      <Slider
        orientation="vertical"
        getAriaValueText={displayValue => `${displayValue}`}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        track={false}
        sx={{
          'padding': '0px',
          '& .MuiSlider-thumb': {
            borderRadius: '3px',
            height: '10px',
            width: '20px',
            backgroundColor: 'rgb(95, 95, 95)',
            zIndex: '1',
          },
          '& .MuiSlider-track': {
            backgroundColor: 'black',
          },
          '& .MuiSlider-rail': {
            opacity: 0,
          },
        }}
      />
      <div style={{ color: minValueColor, marginTop: '5px', fontSize: '0.8rem' }}>{min}</div>
      <div style={{
        position: 'absolute',
        width: '10px',
        height: `${sliderTrackHeight * 2}px`,
        backgroundColor: 'rgb(2, 2, 2)',
        top: `${symbolHeight}px`,
        zIndex: '0',
        borderRadius: 3
      }}
      ></div>
      <div style={{ ...indicatorStyle, height: positiveIndicatorHeight, backgroundColor: 'green', bottom: positiveIndicatorBottom }} />
      <div style={{ ...indicatorStyle, height: negativeIndicatorHeight, backgroundColor: 'red', top: '50%' }} />
    </div>
  );
};

export default VerticalSlider;