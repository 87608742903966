import React, { useState, useContext, useEffect, memo } from 'react';
import { Box } from '@mui/material';
import ResultExpandedCard from './ResultExpandedCard';
import ResultCollapsedCard from './ResultCollapsedCard';

const SearchResultNode = memo(({ result }) => {
    // TODO: need to add node_id etc, maybe call it node too instead of result
    const [isHovering, setIsHovering] = useState(false);


    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 8px 0px 8px',
                margin: result.is_collapsed ? '4px 0px' : '8px 0px',
                position: 'relative',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', zIndex: '3' }}>
                {result.is_collapsed ? (
                    <ResultCollapsedCard result={result} />
                ) : (
                    <ResultExpandedCard
                        result={result}
                        isHovering={isHovering}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}

                    />
                )}
            </Box>
        </Box>
    );
}, (prevProps, nextProps) => {
    return prevProps.result.ranking.total_rank_adjustment === nextProps.result.ranking.total_rank_adjustment &&
        prevProps.result.is_collapsed === nextProps.result.is_collapsed &&
        prevProps.result.id === nextProps.result.id;
});

export default SearchResultNode;