import React, { useContext } from 'react';
import { Button, Box } from '@mui/material';
import { ChatContext } from '../../../../contexts/ChatContext';



const ResultRowActionBar = ({ result }) => {
    const { addWebsiteToChat } = useContext(ChatContext);

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 4px',
        fontSize: '0.6rem',
        padding: '4px 4px',
        minWidth: 'unset',
        border: '1px solid',
    };

    const handleAddTextToChat = () => {
        if (result) {
            addWebsiteToChat(result);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: '#dfe0ed',
                borderRadius: '6px',
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '8px',
                height: '1.0rem'
            }}
        >

            <Button
                onClick={handleAddTextToChat}
                style={{
                    ...buttonStyle,
                    color: '#484b51',
                    borderColor: 'transparent',
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                    padding: '0px 4px'
                }}
            >
                Add to chat
            </Button>
        </Box>
    );
};

export default ResultRowActionBar;
