// components/ui/src/components/GoogleSignInButton.js
import React, { useEffect, useRef } from 'react';

const GoogleSignInButton = ({ onSignIn, inviteCode }) => {
  const buttonRef = useRef();

  useEffect(() => {
    console.log("Current origin: " + window.location.origin); // Log the current origin

    // Load the Google Identity Services script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => handleCredentialResponse(response, inviteCode),
        cancel_on_tap_outside: false,
      });
      window.google.accounts.id.renderButton(buttonRef.current, {
        theme: 'outline',
        size: 'large',
      });
      window.google.accounts.id.prompt();
    };
    document.body.appendChild(script);
  }, [inviteCode]);

  const handleCredentialResponse = (response, inviteCode) => {
    // Parse the credential response
    // onSignIn(response.getBackchannelPayload().id_token, inviteCode); only available on one tap
    onSignIn(response.credential, inviteCode);
};

  return (
    <div ref={buttonRef}></div>
  );
};

export default GoogleSignInButton;