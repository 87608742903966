import { getParentId, getNodeContent } from './nodeUtils';
import { generateNodeId } from '../graph/utils/idGenerator';
import { debugLogger } from './debugLogger';

export const threadToList = (node) => {

    const list = [];
    const traverse = (currentNode, depth = 0) => {
        if (!currentNode || typeof currentNode !== 'object') {
            debugLogger.log('warn', '[threadToList] Invalid node encountered', { currentNode });
            return;
        }

        if (currentNode.type && typeof currentNode.type === 'string' && currentNode.type.startsWith('content.chat.message.')) {
            list.push(currentNode);

        }

        if (currentNode.children && currentNode.children instanceof Map) {
            for (const childNode of currentNode.children.values()) {
                traverse(childNode, depth + 1);
            }
        }
    };
    traverse(node);

    return list;
};

export const getThreadContent = (thread) => {
    return threadToList(thread)
        .map(node => getNodeContent(node))
        .join('\n\n');
};


export const cloneThreadUpToNodeId = (originalThread, endpointNodeId, parentContainerId) => {
    const newThreadId = generateNodeId(parentContainerId);
    const newThread = {
        ...originalThread,
        node_id: newThreadId,
        children: new Map(),
        timestamp: new Date().toISOString(),
        isMinimized: false
    };

    const cloneNode = (node, parentId) => {
        const clonedNode = { ...node };
        delete clonedNode.isLoading;
        delete clonedNode.error;

        const newNodeId = generateNodeId(parentId);
        clonedNode.node_id = newNodeId;

        if (node.children && node.children instanceof Map) {
            clonedNode.children = new Map();
            for (const [childId, childNode] of node.children) {
                const clonedChild = cloneNode(childNode, newNodeId);
                clonedNode.children.set(clonedChild.node_id, clonedChild);
                if (childId === endpointNodeId) {
                    return clonedNode;
                }
            }
        }

        return clonedNode;
    };

    for (const [childId, childNode] of originalThread.children) {
        const clonedChild = cloneNode(childNode, newThreadId);
        newThread.children.set(clonedChild.node_id, clonedChild);
        if (childId === endpointNodeId) {
            break;
        }
    }

    return newThread;
};
// TODO: add back getThreadWordCount, count code blocks, clean thread once the refactor is done


export const getThreadWordCount = (thread) => {
    return threadToList(thread).reduce((total, node) => {
        const content = getNodeContent(node);
        return total + (content ? content.split(/\s+/).length : 0);
    }, 0);
};

export const countCodeBlocks = (thread) => {
    const codeBlockRegex = /```[\s\S]*?```/g;
    return threadToList(thread).reduce((total, node) => {
        const content = getNodeContent(node);
        if (content) {
            const matches = content.match(codeBlockRegex);
            total += matches ? matches.length : 0;
        }
        return total;
    }, 0);
};

export const cleanThread = (thread) => {
    const newThreadId = generateNodeId(getParentId(thread.node_id));
    const clonedThread = cloneThreadWithUpdatedIds(thread, thread.node_id, newThreadId);
    const codeBlockRegex = /```[\s\S]*?```/g;

    const cleanNode = (node) => {
        const cleanedNode = { ...node };
        if (node.content) {
            cleanedNode.content = node.content.replace(codeBlockRegex, '[OLD CODE REDACTED FOR BREVITY]');
        }
        if (node.children) {
            cleanedNode.children = new Map();
            for (const [childId, childNode] of node.children) {
                const cleanedChild = cleanNode(childNode);
                cleanedNode.children.set(cleanedChild.node_id, cleanedChild);
            }
        }
        return cleanedNode;
    };

    return cleanNode(clonedThread);
};