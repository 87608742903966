import React, { useState, useEffect } from 'react';
import { Popover, Box, TextField, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { createCollection } from '../../../graph/utils/nodeConstructors';
import { useSlotFunctions } from '../../../contexts/ChatContext';

const StyledPopover = styled(Popover)`
  transition: all 0.1s;
  & .MuiPopover-paper {
    border-radius: 16px;
  }
`;

const PopoverContent = styled(Box)`
    transition: all 0.1s;
    width: 600px;
    max-width: 100vw;
    padding: 8px;
    background-color: #1e1e1e;
`;

const defaultPromptTemplate = "Analyze the following text in detail: {item}";

const MapBuilderPopover = React.memo(({ anchorEl, open, onClose, node }) => {
    const [promptTemplate, setPromptTemplate] = useState(defaultPromptTemplate);
    const [error, setError] = useState('');
    const { executeMap } = useSlotFunctions();

    const itemCount = node.children ? node.children.size : 1;

    useEffect(() => {
        if (itemCount === 0) {
            setError("Must have at least one item to map");
        } else {
            validatePrompt(promptTemplate);
        }
    }, [promptTemplate, itemCount]);

    const validatePrompt = (prompt) => {
        if (itemCount === 0) {
            setError("Must have at least one item to map");
        } else if (!prompt.includes('{item}')) {
            setError("The prompt template must include the {item} placeholder.");
        } else {
            setError('');
        }
    };

    const handleSubmit = () => {
        if (!error) {
            if (!node.children) {
                const collectionNode = createCollection(node.node_id, 'Mapped Collection');
                collectionNode.children.set(node.node_id, node);
                executeMap(collectionNode, promptTemplate);
            } else {
                executeMap(node, promptTemplate);
            }
            onClose();
        }
    };

    return (
        <StyledPopover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <PopoverContent>
                <Typography variant="h6" component="h2" gutterBottom>
                    Map Prompt Builder
                </Typography>
                <Typography variant="body2" gutterBottom>
                    For each item ({itemCount} items), create a new thread that says:
                </Typography>
                <TextField
                    multiline
                    maxRows={20}
                    fullWidth
                    value={promptTemplate}
                    onChange={(e) => setPromptTemplate(e.target.value)}
                    placeholder="Enter your prompt template here..."
                    margin="normal"
                    error={!!error}
                    helperText={error}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" disabled={!!error}>
                        Submit
                    </Button>
                </Box>
            </PopoverContent>
        </StyledPopover>
    );
});

MapBuilderPopover.displayName = 'MapBuilderPopover';

export default MapBuilderPopover;