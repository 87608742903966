import React, { memo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Close, Minimize, OpenInFull, Download } from '@mui/icons-material';
import styled from '@emotion/styled';

const ThreadHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? '2px 4px' : '4px 8px')};
  background-color: #1e1e1e;
  border-bottom: 1px solid #555;
  border-radius: 8px 8px 0 0;
  transition: all 0.1s;
  max-width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ThreadTitle = styled.h3`
  margin: 0;
  font-size: ${({ isMobile }) => (isMobile ? '0.9rem' : '1.0rem')};
  font-weight: 500;
  color: #d6d6dc;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const ThreadHeader = memo(({
    thread,
    onClose,
    onToggleMinimize,
    onDownload,
    isMinimized,
    isFounderModeEnabled,
    isMobile
}) => {
    const threadId = thread.node_id;
    const threadWordCount = thread.wordCount || 0;

    let threadHeaderText = isFounderModeEnabled ? `Thread ${threadId}` : 'Thread';
    if (threadWordCount > 500) {
        threadHeaderText += ` (${threadWordCount} words)`;
    }

    return (
        <ThreadHeaderWrapper isMobile={isMobile}>
            {!isMinimized && (
                <LeftContainer>
                    <ThreadTitle isMobile={isMobile}>{threadHeaderText}</ThreadTitle>
                    <Tooltip title="Download Thread">
                        <StyledIconButton onClick={onDownload} size="small">
                            <Download sx={{ color: '#d6d6dc', '&:hover': { color: 'blue' } }} />
                        </StyledIconButton>
                    </Tooltip>
                </LeftContainer>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isFounderModeEnabled && (
                    <Tooltip title={isMinimized ? "Expand" : "Minimize"}>
                        <StyledIconButton onClick={onToggleMinimize} size="small">
                            {isMinimized ? (
                                <OpenInFull sx={{ color: 'white', '&:hover': { color: 'green' } }} />
                            ) : (
                                <Minimize sx={{ color: 'white', '&:hover': { color: 'yellow' } }} />
                            )}
                        </StyledIconButton>
                    </Tooltip>
                )}

                {!isMobile && (
                    <Tooltip title="Close">
                        <StyledIconButton onClick={onClose} size="small">
                            <Close sx={{ color: 'white', '&:hover': { color: 'red' } }} />
                        </StyledIconButton>
                    </Tooltip>
                )}
            </Box>
        </ThreadHeaderWrapper>
    );
});

export default ThreadHeader;