import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography, LinearProgress } from '@mui/material';
import { spacing } from '@mui/system';
import zxcvbn from 'zxcvbn';

const StyledContainer = styled.div`
  margin-top: ${spacing(2)}px;
`;

const StyledLinearProgress = styled(LinearProgress)`
  height: 8px;
  border-radius: 4px;
  margin-top: ${spacing(1)}px;
`;

const PasswordStrengthBar = ({ password }) => {
  const result = zxcvbn(password);
  const score = result.score;
  const strengthText = ['Weak', 'Fair', 'Good', 'Strong', 'Very Strong'];
  const strengthColor = ['#ff4136', '#ff851b', '#ffdc00', '#2ecc40', '#2ecc40'];

  return (
    <StyledContainer>
      <Typography variant="body2" color="textSecondary">
        Password Strength: {strengthText[score]}
      </Typography>
      <StyledLinearProgress
        variant="determinate"
        value={(score + 1) * 25}
        style={{ backgroundColor: '#e0e0e0' }}
        color="secondary"
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: strengthColor[score],
          },
        }}
      />
      {score < 3 && (
        <Typography variant="caption" color="textSecondary" paragraph>
          {result.feedback.warning || 'Consider using a stronger password.'}
        </Typography>
      )}
    </StyledContainer>
  );
};

PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordStrengthBar;