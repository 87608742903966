import axios from 'axios';

export const fetchSuggestions = async (query) => {
    if (query && query.trim() !== '') {
        const url = `${process.env.REACT_APP_BING_AUTOCOMPLETE_ENDPOINT}?q=${query}`;

        try {
            let response = false;

            try {
                response = await axios.get(url);
            } catch (error) {
                response = false;
            }

            const apiSuggestions = response ? response.data.suggestionGroups[0].searchSuggestions : false;

            if (apiSuggestions) {
                const queryExistsInSuggestions = apiSuggestions.some(suggestion => suggestion.displayText === query);
                const newSuggestions = queryExistsInSuggestions ? apiSuggestions : [{ displayText: query }, ...apiSuggestions];

                return newSuggestions;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    } else {
        return [];
    }
};