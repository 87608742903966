import React, { useContext, useState, useEffect, useRef, forwardRef, memo } from 'react';
import { useSlotFunctions } from '../../../../../contexts/ChatContext';
import { Box, IconButton, TextField } from '@mui/material';
import styled from '@emotion/styled';
import SendIcon from '@mui/icons-material/Send';
import { generateNodeId } from '../../../../../graph/utils/idGenerator';

const ChatBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 10px;
  border: 1px solid rgb(98, 98, 98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #1e1e1e;
  color: #fff;
  width: 100%;
  min-width: 300px;
  min-height: 3.5rem;
  transition: box-shadow 0.1s, content 0.1s;
  &:focus-within {
    border: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
    border-radius: 6px;  
  }
`;

const CursorChatBar = memo(forwardRef(({ appendsMessagesTo, selectedFollowup, onSend }, ref) => {
    const { addUserMessageToThread } = useSlotFunctions();
    const [message, setMessage] = useState('');
    const textFieldRef = useRef(null);

    useEffect(() => {
        if (selectedFollowup) {
            setMessage(selectedFollowup.content);
        }
    }, [selectedFollowup]);

    useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [selectedFollowup]);

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleSendMessage = () => {
        if (message.trim()) {
            const nodeId = generateNodeId(appendsMessagesTo);
            addUserMessageToThread(appendsMessagesTo, {
                content: message,
                role: 'user',
                timestamp: new Date().toISOString(),
                node_id: nodeId,
            });
            onSend();
            setMessage('');
        }
    };

    return (
        <ChatBarContainer>
            <TextField
                multiline
                inputRef={(input) => {
                    textFieldRef.current = input;
                    if (ref) {
                        ref.current = input;
                    }
                }}
                value={message}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                variant="outlined"
                fullWidth
                maxRows={30}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#1e1e1e',
                        borderRadius: '4px',
                        padding: '10px',
                        '& fieldset': {
                            border: 'none',
                        },
                    },
                    '& .MuiInputBase-input': {
                        overflowY: 'auto',
                    },
                }}
            />
            <IconButton onClick={handleSendMessage} sx={{ color: '#fff' }}>
                <SendIcon />
            </IconButton>
        </ChatBarContainer>
    );
}));

export default CursorChatBar;