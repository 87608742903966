import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from '@mui/material';
import CursorActionButton from './CursorActionButton';
import MapBuilderPopover from '../../MapBuilderPopover';
import { useSlotFunctions } from '../../../../../contexts/ChatContext';

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 900;
`;

export const LeafRightActionBar = ({ node }) => {
    const { forkThread, forkAndResubmitMessage } = useSlotFunctions();
    const [mapAnchorEl, setMapAnchorEl] = useState(null);

    const handleForkThread = () => {
        forkThread(node.node_id);
    };

    const handleRegenerate = () => {
        forkAndResubmitMessage(node.node_id, node.content);
    };

    const handleMapClick = (event) => {
        setMapAnchorEl(event.currentTarget);
    };

    const handleMapClose = () => {
        setMapAnchorEl(null);
    };

    return (
        <ActionBarContainer>
            <CursorActionButton
                icon="arrow_split"
                text="Fork"
                backgroundColor="#4a90e2"
                color="white"
                onClick={handleForkThread}
            />
            {node.type === 'content.chat.message.user' && (
                <CursorActionButton
                    icon="refresh"
                    text="Resubmit"
                    backgroundColor="#4a90e2"
                    color="white"
                    onClick={handleRegenerate}
                />
            )}
            {/* <CursorActionButton
                icon={
                    <Icon className="material-symbols-outlined" sx={{ color: 'white' }}>
                        function
                    </Icon>
                }
                text="Map"
                backgroundColor="#4a90e2"
                color="white"
                onClick={handleMapClick}
            />
            <MapBuilderPopover
                anchorEl={mapAnchorEl}
                open={Boolean(mapAnchorEl)}
                onClose={handleMapClose}
                node={node}
            /> */}
        </ActionBarContainer>
    );
};

LeafRightActionBar.displayName = 'LeafRightActionBar';

export default LeafRightActionBar;