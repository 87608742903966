import React, { useState, useContext, useEffect } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from '@emotion/styled';
import { UIContext } from '../../../../contexts/UIContext';
import { FeatureFlagsContext } from '../../../../contexts/FeatureFlagsContext';
import { ChatContext } from '../../../../contexts/ChatContext';
import RankingDetailsChip from './RankingDetailsChip/RankingDetailsChip';
import ResultRowActionBar from './ResultRowActionBar';
import ResultSiteInfo from './ResultSiteInfo';

const StyledCard = styled(Card)`
  background-color: rgb(240, 240, 244);
  width: 100%;
  box-shadow: 0 0 2px 2px rgb(10, 10, 10, 0.06);
  border-radius: 6px;

  &.flashGreen {
    animation: flashGreen 1s;
  }

  &.flashRed {
    animation: flashRed 1s;
  }
`;

const Title = styled(Typography)`
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: #1b0daa;
`;


const Snippet = styled(Typography)`
  margin: 0;
  padding: 0;
  color: #484b51 !important;

  & > * {
    color: #484b51 !important;
  }
`;

const Content = styled(CardContent)`
  position: relative;
  padding: 6px !important;
`;

const FlexContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const ResultExpandedCard = ({ result, onAddSummaryToChat, onAddTextToChat, isHovering, handleMouseEnter, handleMouseLeave }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const { featureFlags } = useContext(FeatureFlagsContext);
    const { isMobile, isSidebarOpen } = useContext(UIContext);
    const [flashClass, setFlashClass] = useState('');

    const showRankingDetailsChip = !isMobile || (isMobile && isSidebarOpen);
    const showResultRowActionBar = !isMobile || (isMobile && !isSidebarOpen);

    useEffect(() => {
        if (result.ranking && result.ranking.position) {
            const { previous, current } = result.ranking.position;
            if (previous !== null && current !== null) {
                if (current < previous) {
                    setFlashClass('flashGreen');
                } else if (current > previous) {
                    setFlashClass('flashRed');
                }
                const timer = setTimeout(() => setFlashClass(''), 1000);
                return () => clearTimeout(timer);
            }
        }
    }, [result.ranking]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const excludedKeywords = ['snippet_annotations'];

    const replacer = (key, value) => {
        if (excludedKeywords.includes(key)) {
            return undefined;
        }
        return value;
    };

    return (
        <StyledCard
            className={`${flashClass}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Content>
                <FlexContainer>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ResultSiteInfo result={result} showDate={true} />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {showRankingDetailsChip && <RankingDetailsChip result={result} />}
                        {showResultRowActionBar && (
                            <ResultRowActionBar
                                onAddSummaryToChat={onAddSummaryToChat}
                                onAddTextToChat={onAddTextToChat}
                                result={result}
                            />
                        )}
                        <IconButton onClick={handleClick} style={{ marginLeft: '4px', padding: 0 }}>
                            <MoreVertIcon sx={{ fontSize: '0.3rem' }} />
                        </IconButton>
                    </Box>
                </FlexContainer>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={handleOpenDialog}>View JSON</MenuItem>
                </Menu>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{'Result JSON'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <pre>{JSON.stringify(result, replacer, 2)}</pre>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Title gutterBottom>
                    <a href={result.url} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{ __html: result.title }}></a>
                </Title>
                <Snippet variant="body2" component="p" dangerouslySetInnerHTML={{ __html: result.snippet }} />
            </Content>
        </StyledCard>
    );
};

export default ResultExpandedCard;