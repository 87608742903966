import React, { useState, useContext } from 'react';
import { IconButton, Menu, MenuItem, Button } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import SignInModal from './SignInModal';

const UserMenu = () => {
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [signInModalOpen, setSignInModalOpen] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSignInClick = () => {
        setSignInModalOpen(true);
        handleMenuClose();
    };

    const handleSignOutClick = () => {
        logout();
        handleMenuClose();
    };

    return (
        <>
            {isAuthenticated ? (
                <>
                    <IconButton onClick={handleMenuOpen}>
                        <span className="material-symbols-outlined">account_circle</span>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleMenuClose}>Account Info</MenuItem>
                        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                        <MenuItem onClick={handleSignOutClick}>Sign Out</MenuItem>
                    </Menu>
                </>
            ) : (
                <Button variant="contained" color="primary" onClick={handleSignInClick}>
                    Sign In
                </Button>
            )}
            <SignInModal
                open={signInModalOpen}
                onClose={() => setSignInModalOpen(false)}
            />
        </>
    );
};

export default UserMenu;