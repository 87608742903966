const SELF = "'self'";
const STICKSHIFT_AI = 'https://stickshift.ai';
const STICKSHIFT_AI_WSS = 'wss://stickshift.ai';
const LOCALHOST_HTTP = 'http://localhost';
const LOCALHOST_HTTPS = 'https://localhost';
const LOCALHOST_WS = 'ws://localhost';
const LOCALHOST_WSS = 'wss://localhost';

const SCRIPT_SRC = [
  SELF,
  "'unsafe-inline'",
  "'unsafe-eval'",
  STICKSHIFT_AI,
  LOCALHOST_HTTP,
  LOCALHOST_HTTPS,
];

const STYLE_SRC = [SELF, "'unsafe-inline'", 'https://fonts.googleapis.com'];

const CONNECT_SRC = [
  SELF,
  STICKSHIFT_AI,
  STICKSHIFT_AI_WSS,
  LOCALHOST_WSS,
  LOCALHOST_WS,
  LOCALHOST_HTTP,
  LOCALHOST_HTTPS,
  'https://fonts.gstatic.com',
  'http://localhost:3001',
  'ws://localhost:5000',
  'http://localhost:5000',
  'ws://localhost:8181',
  'http://localhost:8181',
  'http://localhost:9881',
  'http://localhost:5002',
  'http://localhost:8451',
];

const FONT_SRC = [SELF, 'https://fonts.gstatic.com'];

const IMG_SRC = [
  SELF,
  'https://www.google.com',
  'https://*.gstatic.com',
  'data:',
];

const BASE_URI = [
  SELF,
  STICKSHIFT_AI,
  LOCALHOST_HTTP,
  LOCALHOST_HTTPS,
];

const FORM_ACTION = [
  SELF,
  STICKSHIFT_AI,
  LOCALHOST_HTTP,
  LOCALHOST_HTTPS,
];

export const CSP_DIRECTIVES = `
  default-src ${SELF} ${STICKSHIFT_AI} ${STICKSHIFT_AI_WSS} ${LOCALHOST_WS} ${LOCALHOST_WSS} ${LOCALHOST_HTTP} ${LOCALHOST_HTTPS};
  script-src ${SCRIPT_SRC.join(' ')};
  style-src ${STYLE_SRC.join(' ')};
  connect-src ${CONNECT_SRC.join(' ')};
  font-src ${FONT_SRC.join(' ')};
  img-src ${IMG_SRC.join(' ')};
  object-src 'none';
  base-uri ${BASE_URI.join(' ')};
  form-action ${FORM_ACTION.join(' ')};
`;