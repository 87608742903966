import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, Typography, Tooltip, Divider } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import { useUserSessionContext } from '../../contexts/UserSessionContext';
import { useUIContext } from '../../contexts/UIContext';
import { debugLogger } from '../../lib/debugLogger';


const formatDate = (date) => {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const seconds = d.getSeconds();
    return `${month}/${day} ${hours}:${minutes}`;
};

const SessionMenuItem = ({ session, isCurrentSession, onSelect, onDelete }) => (
    <MenuItem onClick={() => onSelect(session?.id)}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography
                variant="body2"
                style={{
                    fontWeight: isCurrentSession ? 'bold' : 'normal',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <span style={{
                    marginRight: '8px',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: isCurrentSession ? 'green' : 'transparent',
                    display: 'inline-block',
                }}></span>
                ID: {session?.id || 'N/A'}, {session?.messageCount || 0} messages, last updated {formatDate(session?.lastUpdated)}
            </Typography>
            <IconButton
                size="small"
                onClick={(e) => { e.stopPropagation(); onDelete(session?.id); }}
                disabled={isCurrentSession}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </div>
    </MenuItem>
);


const SessionManagementDropdown = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [hasPreviousSession, setHasPreviousSession] = useState(false);
    const {
        createNewSession,
        switchSession,
        deleteAllSessions,
        listSessions,
        saveSession,
        deleteSession,
        currentSessionData,
    } = useUserSessionContext();

    const { isFounderModeEnabled } = useUIContext();

    useEffect(() => {
        loadSessions();
    }, []);

    const loadSessions = async () => {
        try {
            const savedSessions = await listSessions();
            setSessions(savedSessions || []);
            setHasPreviousSession((savedSessions?.length || 0) > 1);
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to load sessions', { error });
            setSessions([]);
            setHasPreviousSession(false);
        }
    };

    const handleClick = (event) => {
        loadSessions();

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSessionSelect = async (id) => {
        console.log('handleSessionSelect', id);
        try {
            await saveSession(); // Save the current session first
            await new Promise(resolve => setTimeout(resolve, 100)); // Small delay
            await switchSession(id);
            await loadSessions();
            setAnchorEl(null);
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to switch session', {
                id,
                error: error.message,
                stack: error.stack
            });
        }
    };

    const saveCurrentSession = async () => {
        try {
            await saveSession();
            // await updateCurrentSession({ title: `Session ${new Date().toLocaleString()}` });
            await loadSessions();
            debugLogger.log('debug', 'SessionManagementDropdown: Session saved successfully');
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to save session', {
                error: error.toString(),
                message: error.message,
                name: error.name,
                stack: error.stack
            });
        }
        handleClose();
    };

    const handleCreateNewSession = async () => {
        debugLogger.log('debug', 'SessionManagementDropdown: Creating new session');
        try {
            await createNewSession();
            await loadSessions();
            debugLogger.log('debug', 'SessionManagementDropdown: New session created successfully');
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to create new session', { error });
        }
        handleClose();
    };

    const handleClearSavedSessions = async () => {
        debugLogger.log('debug', 'SessionManagementDropdown: Clearing saved sessions');
        try {
            await deleteAllSessions();
            await loadSessions();
            debugLogger.log('debug', 'SessionManagementDropdown: All sessions cleared successfully');
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to clear sessions', { error });
        }
        handleClose();
    };

    const handleDeleteSession = async (id) => {
        debugLogger.log('debug', 'SessionManagementDropdown: Deleting session', { id });
        try {
            await deleteSession(id);
            await loadSessions();
            debugLogger.log('debug', 'SessionManagementDropdown: Session deleted successfully');
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to delete session', { error });
        }
    };

    const handleLoadLastSession = async () => {
        debugLogger.log('debug', 'SessionManagementDropdown: Loading last session');
        try {
            const lastSession = sessions.find(s => s?.id !== currentSessionData?.id);
            if (lastSession?.id) {
                await switchSession(lastSession.id);
                debugLogger.log('debug', 'SessionManagementDropdown: Last session loaded successfully');
            }
        } catch (error) {
            debugLogger.log('error', 'SessionManagementDropdown: Failed to load last session', { error });
        }
        handleClose();
    };

    return (
        <>
            <Tooltip title="Manage Sessions">
                <IconButton onClick={handleClick} size="small">
                    <HistoryIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleCreateNewSession}>
                    <AddIcon fontSize="small" style={{ marginRight: '8px' }} />
                    <Typography variant="body2">New Session</Typography>
                </MenuItem>
                <MenuItem onClick={saveCurrentSession}>
                    <Typography variant="body2">Save Current Session</Typography>
                </MenuItem>
                {hasPreviousSession && (
                    <MenuItem onClick={handleLoadLastSession}>
                        <RestoreIcon fontSize="small" style={{ marginRight: '8px' }} />
                        <Typography variant="body2">Load Last Session</Typography>
                    </MenuItem>
                )}
                <Divider />
                {sessions.map((session) => (
                    session && (
                        <SessionMenuItem
                            key={session.id || Math.random().toString()}
                            session={session}
                            isCurrentSession={session.id === currentSessionData?.id}
                            onSelect={handleSessionSelect}
                            onDelete={handleDeleteSession}
                        />
                    )
                ))}
                <Divider />
                <MenuItem onClick={handleClearSavedSessions}>
                    <Typography variant="body2" color="error" style={{ display: 'flex', alignItems: 'center' }}>
                        <DeleteForeverIcon fontSize="small" style={{ marginRight: '8px' }} />
                        Clear Saved Sessions (not including current)
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default SessionManagementDropdown;