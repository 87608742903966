import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import VerticalSlider from './VerticalSlider';

const Root = styled(Box)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(0.625)};
  padding-right: ${({ theme }) => theme.spacing(0.625)};
`;

const TextColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const Title = styled(Typography)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-top: 0.25rem;
  align-items: center;
`;

const ProbeInputWrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  flex-grow: 1;
`;

const ProbeInput = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.text.primary};

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.divider};
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.text.primary};
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const ProbeChipList = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const ProbeChip = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  color: ${({ theme }) => theme.palette.text.primary};
  border-radius: 12px;
  padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
  font-size: 0.8rem;
`;

const ProbeChipDeleteButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(0.25)};
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const ProbeRankAdjuster = ({ value, onChange }) => {
    const theme = useTheme();
    const [probes, setProbes] = useState([]);
    const [inputValue, setInputValue] = useState('');

    // TODO: add probes, updateProbes in chatContext
    // Add functions to update probe scores and attach the scores for all probes to the results

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            const newProbe = inputValue.trim();
            if (newProbe) {
                const newProbeItem = {
                    id: uuidv4(),
                    text: newProbe,
                };
                setProbes([...probes, newProbeItem]);
                setInputValue('');
            }
        }
    };

    const handleDeleteProbe = (probeToDelete) => {
        setProbes(probes.filter((probe) => probe.id !== probeToDelete.id));
    };


    return (
        <Root>
            <FlexBox>
                <TextColumn>
                    <Title variant="h6">Probe Rank Adjuster</Title>
                    <ProbeInputWrapper>
                        <ProbeInput
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            placeholder="Enter probes..."
                            fullWidth
                            InputProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                },
                            }}
                        />
                        <ProbeChipList>
                            {probes.map((probe) => (
                                <ProbeChip key={probe.id}>
                                    {probe.text}
                                    <ProbeChipDeleteButton
                                        size="small"
                                        onClick={() => handleDeleteProbe(probe)}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </ProbeChipDeleteButton>
                                </ProbeChip>
                            ))}
                        </ProbeChipList>
                    </ProbeInputWrapper>
                </TextColumn>
                <VerticalSlider value={value} onChange={onChange} />
            </FlexBox>
        </Root>
    );
};


export default ProbeRankAdjuster;