export const readFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
    });
};

export const processFileContent = (content, fileType) => {
    // Implement content processing based on file type
    // For now, we'll just return the content as-is
    return content;
};

export const getLanguageFromExtension = (extension) => {
    switch (extension) {
        case 'js':
        case 'jsx':
            return 'javascript';
        case 'ts':
        case 'tsx':
            return 'typescript';
        case 'py':
            return 'python';
        case 'html':
        case 'htm':
            return 'html';
        case 'css':
        case 'scss':
        case 'less':
            return 'css';
        case 'json':
            return 'json';
        case 'java':
            return 'java';
        case 'c':
            return 'c';
        case 'cpp':
        case 'cc':
        case 'cxx':
            return 'cpp';
        case 'cs':
            return 'csharp';
        case 'php':
            return 'php';
        case 'rb':
            return 'ruby';
        case 'go':
            return 'go';
        case 'swift':
            return 'swift';
        case 'kt':
        case 'kts':
            return 'kotlin';
        case 'rs':
            return 'rust';
        case 'scala':
        case 'sc':
            return 'scala';
        case 'sql':
            return 'sql';
        case 'xml':
            return 'xml';
        case 'yaml':
        case 'yml':
            return 'yaml';
        case 'md':
        case 'markdown':
            return 'markdown';
        case 'sh':
        case 'bash':
            return 'shell';
        case 'bat':
        case 'cmd':
            return 'bat';
        case 'ps1':
        case 'psm1':
            return 'powershell';
        case 'dockerfile':
            return 'dockerfile';
        case 'ini':
        case 'conf':
            return 'ini';
        case 'csv':
            return 'csv';
        case 'tsv':
            return 'tsv';
        case 'txt':
        case 'log':
            return 'plaintext';
        case 'dart':
            return 'dart';
        case 'erl':
            return 'erlang';
        case 'fs':
        case 'fsx':
            return 'fsharp';
        case 'groovy':
        case 'gvy':
        case 'gy':
        case 'gsh':
            return 'groovy';
        case 'lua':
            return 'lua';
        case 'm':
            return 'objective-c';
        case 'pl':
        case 'pm':
            return 'perl';
        case 'r':
            return 'r';
        case 'vb':
            return 'vb';
        case 'vue':
            return 'vue';
        case 'xaml':
            return 'xaml';
        // Add more mappings as needed
        default:
            return 'plaintext';
    }
};