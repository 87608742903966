import { debugLogger } from '../lib/debugLogger';
import { threadToList } from '../lib/threadUtils';
import { getParentId } from '../lib/nodeUtils';

import * as NodeConstructors from '../graph/utils/nodeConstructors';

class StreamManager {
    constructor(dependencies) {
        this.dependencies = dependencies;
        this.streams = new Map();
    }

    createEventStream = async (newMessageId, thread, llmConfigName) => {
        debugLogger.log('debug', '[StreamManager] Creating event stream', { newMessageId, llmConfigName });

        const threadMessages = threadToList(thread);

        const initResponse = await this.fetchWithRetry(
            `${process.env.REACT_APP_CHAT_API_SSE_BASE_URL}/chat/init`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    node_id: newMessageId,
                    messages: threadMessages,
                    llm_args: this.dependencies.llmConfigs[llmConfigName],
                }),
            }
        );

        if (!initResponse.ok) throw new Error(`HTTP error! status: ${initResponse.status}`);

        const { task_id } = await initResponse.json();
        debugLogger.log('debug', '[StreamManager] Task ID received:', task_id);

        const eventSource = new EventSource(`${process.env.REACT_APP_CHAT_API_SSE_BASE_URL}/chat/stream?task_id=${task_id}`);

        this.streams.set(newMessageId, {
            eventSource,
            isCancelled: false,
            accumulatedTokens: [],
        });

        return eventSource;
    };

    consumeEventStream = (eventSource, thread, newMessageId) => {
        debugLogger.log('debug', '[StreamManager] Starting to consume event stream', { newMessageId });

        const listeners = {
            assistant_response_token: (event) => {
                const data = JSON.parse(event.data);
                this.streams.get(newMessageId).accumulatedTokens.push(data.content);
                if (!this.streams.get(newMessageId).isCancelled) {
                    this.handleAssistantResponseToken(thread.node_id, data.node_id, data.content);
                }
            },
            usage_data: (event) => {
                const data = JSON.parse(event.data);
                if (!this.streams.get(newMessageId).isCancelled) {
                    this.handleUsageData(data.node_id, data.content);
                }
            },
            suggested_followup: (event) => {
                const data = JSON.parse(event.data);
                if (!this.streams.get(newMessageId).isCancelled) {
                    this.handleSuggestedFollowup(data.node_id, data.content);
                }
            },
            chat_response_complete: (event) => {
                if (!this.streams.get(newMessageId).isCancelled) {
                    this.handleChatResponseComplete(thread.node_id, newMessageId);
                }
                this.closeStream(newMessageId);
            },
            error: (event) => {
                debugLogger.log('error', '[StreamManager] Error event received', { event });
                this.handleError(thread.node_id, newMessageId, new Error(event.data), eventSource);
                this.closeStream(newMessageId);
            }
        };

        Object.entries(listeners).forEach(([event, handler]) => {
            eventSource.addEventListener(event, handler);
        });

        return { eventSource, listeners };
    };

    initializeStream = async (newMessageId, thread, llmConfigName) => {
        debugLogger.log('debug', '[StreamManager] Starting initializeStream', { newMessageId, llmConfigName });

        try {
            const eventSource = await this.createEventStream(newMessageId, thread, llmConfigName);
            const streamInfo = this.consumeEventStream(eventSource, thread, newMessageId);
            this.streams.get(newMessageId).listeners = streamInfo.listeners;
        } catch (error) {
            debugLogger.log('error', '[StreamManager] Failed to initialize stream', {
                error: error.message,
                stack: error.stack,
                url: process.env.REACT_APP_CHAT_API_SSE_BASE_URL
            });
            this.dependencies.updateNode(newMessageId, { isLoading: false, error: `Failed to initialize the response stream: ${error.message}` });
        }
    };

    cancelGeneration = (messageId) => {
        debugLogger.log('debug', '[StreamManager] Cancelling generation', { messageId });
        const streamInfo = this.streams.get(messageId);
        if (streamInfo) {
            streamInfo.isCancelled = true;
        }
        this.dependencies.updateNode(messageId, { isLoading: false });
        this.dependencies.updateNode(getParentId(messageId), { isLoading: false });
    };

    closeStream = (messageId) => {
        const streamInfo = this.streams.get(messageId);
        if (streamInfo) {
            const { eventSource, listeners } = streamInfo;
            if (eventSource) {
                Object.entries(listeners).forEach(([event, handler]) => {
                    eventSource.removeEventListener(event, handler);
                });
                eventSource.close();
            }
            this.streams.delete(messageId);
        }
    };

    getAccumulatedTokens = (messageId) => {
        const streamInfo = this.streams.get(messageId);
        return streamInfo ? streamInfo.accumulatedTokens : [];
    };

    handleAssistantResponseToken = (threadId, nodeId, content) => {
        const parentId = getParentId(nodeId);
        let node = this.dependencies.getNode(nodeId);
        if (!node) {
            node = NodeConstructors.createAssistantMessage(parentId);
            node.node_id = nodeId;
            node.content = content;
            node.isLoading = true;
            this.dependencies.addNode(parentId, node);
            this.dependencies.selectNode(nodeId);
        } else {
            // node.content = (node.content || '') + content;
            // node.isLoading = true;
            // use immer
            this.dependencies.updateNode(nodeId, (node) => {
                node.content = (node.content || '') + content;
                node.isLoading = true;
                return node;
            });

        }
    };

    handleUsageData = (nodeId, usageData) => {
        // Implement token usage tracking logic here
        debugLogger.log('info', `[StreamManager] Token usage data for node ${nodeId}:`, usageData);
    };

    handleSuggestedFollowup = (nodeId, followup) => {
        this.dependencies.updateNode(nodeId, (node) => ({
            ...node,
            suggestedFollowups: node.suggestedFollowups ? [...node.suggestedFollowups, followup] : [followup],
        }));
    };


    handleChatResponseComplete = (threadId, messageNodeId) => {
        const parentThreadId = getParentId(messageNodeId);
        this.dependencies.updateNode(messageNodeId, { isLoading: false });
        this.dependencies.updateNode(parentThreadId, { isLoading: false });
    };

    handleError = (threadId, messageNodeId, error, eventSource) => {
        debugLogger.log('error', '[StreamManager] Error in stream', { threadId, messageNodeId, error });
        this.dependencies.updateNode(messageNodeId, { isLoading: false, error: error.message });
        if (eventSource) {
            eventSource.close();
        }
    };

    fetchWithRetry = async (url, options, maxRetries = 3, baseDelay = 4000) => {
        for (let i = 0; i < maxRetries; i++) {
            try {
                const response = await fetch(url, options);
                if (response.ok) return response;
                if (response.status !== 503) throw new Error(`HTTP error! status: ${response.status}`);
            } catch (err) {
                debugLogger.log('error', '[StreamManager] Error: retrying fetch', { url, options, maxRetries, baseDelay, i });
                if (i === maxRetries - 1) throw err;
            }
            debugLogger.log('error', '[StreamManager] Retrying fetch', { url, options, maxRetries, baseDelay, i });
            const delay = baseDelay * Math.pow(2, i);
            await new Promise(resolve => setTimeout(resolve, delay));
        }
        throw new Error('Max retries reached');
    };


}

export default StreamManager;