import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

const StyledActionButton = styled(Button)`
  text-transform: none;
  justify-content: flex-start;
  color: #fff;
  // background-color: #424242;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 2px 0;
  transition: background-color 0.1s;
  min-width: 100%;

  &:hover {
    // background-color: #616161;
    background-color: #424242;
  }

  &:active {
    // background-color: #757575;
    background-color: #616161;
  }

  .MuiButton-startIcon {
    margin-right: 8px;
  }
`;

const ActionButton = ({ children, startIcon, onClick }) => {
  return (
    <StyledActionButton onClick={onClick} startIcon={startIcon}>
      {children}
    </StyledActionButton>
  );
};

export default ActionButton;