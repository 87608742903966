import React, { createContext, useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { produce } from 'immer';
import { debounce } from 'lodash';

import { useUserSessionContext } from './UserSessionContext';
import { useUIContext } from './UIContext';

import * as TreeLayout from '../graph/utils/treeLayout';

const RootThreadContainerContext = createContext();

export const RootThreadContainerProvider = ({ children }) => {
    const { rootThreadContainerRef, currentSessionData, isSessionReady } = useUserSessionContext();
    const {
        chatAvailableWidth,
        globalWidthMultiplier,
        isSearchOpen,
        isChatOpen,
        baseThreadWidthRef,
        updateGlobalWidthMultiplier,
        setBaseThreadWidth
    } = useUIContext();

    const [rootThreadContainerState, setRootThreadContainerState] = useState(rootThreadContainerRef.current);
    const prevTopLevelThreadCountRef = useRef(0);

    const updateBaseThreadWidth = useCallback(() => {
        const fullWidth = Math.min(chatAvailableWidth - 30, 1500);
        const newWidth = fullWidth * globalWidthMultiplier;
        baseThreadWidthRef.current = newWidth;
        setBaseThreadWidth(newWidth);
    }, [chatAvailableWidth, globalWidthMultiplier, setBaseThreadWidth, baseThreadWidthRef]);


    const updateNodeWidths = useCallback(() => {
        if (!rootThreadContainerRef.current) return;

        updateBaseThreadWidth();

        const updatedContainer = produce(rootThreadContainerRef.current, draft => {
            TreeLayout.updateNodeWidths({
                rootThreadContainer: draft,
                node: draft,
                basisWidth: baseThreadWidthRef.current,
                rootAvailableWidth: chatAvailableWidth
            });
        });

        rootThreadContainerRef.current = updatedContainer;
    }, [rootThreadContainerRef, baseThreadWidthRef, chatAvailableWidth, updateBaseThreadWidth]);


    const debouncedUpdateNodeWidths = useMemo(() => debounce(updateNodeWidths, 100, { leading: true }), [updateNodeWidths]);


    // effect for updating globalWidthMultiplier on session init
    useEffect(() => {
        if (isSessionReady && currentSessionData && rootThreadContainerRef.current) {
            const numTopLevelThreads = rootThreadContainerRef.current.children?.size;
            if (numTopLevelThreads !== prevTopLevelThreadCountRef.current) {
                const newMultiplier = Math.max(2, Math.ceil(6 / numTopLevelThreads));
                updateGlobalWidthMultiplier(newMultiplier);
                prevTopLevelThreadCountRef.current = numTopLevelThreads;
            }
        }
    }, [isSessionReady, currentSessionData?.id, updateGlobalWidthMultiplier, rootThreadContainerRef]);


    // Separate effect for updating widths on session init
    useEffect(() => {
        if (isSessionReady && currentSessionData && rootThreadContainerRef.current) {
            updateBaseThreadWidth();
            debouncedUpdateNodeWidths();
        }
    }, [isSessionReady, currentSessionData?.id, updateBaseThreadWidth, debouncedUpdateNodeWidths]);


    // primary effect for syncing rootThreadContainerState
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (rootThreadContainerRef.current !== rootThreadContainerState) {
                setRootThreadContainerState(rootThreadContainerRef.current);
            }
        }, 14);

        return () => clearInterval(intervalId);
    }, [rootThreadContainerRef, rootThreadContainerState]);


    useEffect(() => {
        const WIDTH_UPDATE_INTERVAL = 300;
        const widthUpdateIntervalId = setInterval(updateNodeWidths, WIDTH_UPDATE_INTERVAL);
        return () => clearInterval(widthUpdateIntervalId);
    }, [updateNodeWidths]);


    useEffect(() => {
        debouncedUpdateNodeWidths();
    }, [debouncedUpdateNodeWidths, chatAvailableWidth, globalWidthMultiplier, isSearchOpen, isChatOpen, isSessionReady, currentSessionData?.id]);

    const contextValue = useMemo(() => ({
        rootThreadContainerState,
        updateBaseThreadWidth,
        updateNodeWidths
    }), [rootThreadContainerState, updateBaseThreadWidth, updateNodeWidths]);

    return (
        <RootThreadContainerContext.Provider value={contextValue}>
            {children}
        </RootThreadContainerContext.Provider>
    );
};

RootThreadContainerContext.displayName = 'RootThreadContainerContext';

export const useRootThreadContainer = () => {
    const context = useContext(RootThreadContainerContext);
    if (!context) {
        throw new Error('useRootThreadContainer must be used within a RootThreadContainerProvider');
    }
    return context;
};