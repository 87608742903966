import React, { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { SearchQueryContext } from '../../../contexts/SearchQueryContext';

const Root = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const CustomTextField = styled(TextField)`
  width: 100%;
  font-size: 0.5rem;
`;

const CustomArgsComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const { customArgs, setCustomArgs } = useContext(SearchQueryContext);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleArgsChange = (event) => {
    setCustomArgs(event.target.value);
  };

  useEffect(() => {
    const defaultArgs = "";
    setCustomArgs(defaultArgs);
  }, [setCustomArgs]);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ color: '#fff', textTransform: 'none' }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" color="textPrimary" sx={{ fontSize: '1.0rem' }}>
            Forbidden powers
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box>
              <CustomTextField
                id="outlined-multiline-flexible"
                multiline
                maxRows={8}
                value={customArgs}
                onChange={handleArgsChange}
                variant="outlined"
                inputProps={{ style: { fontSize: '0.8rem' } }}
                fullWidth
              />
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </Root>
  );
};

export default CustomArgsComponent;