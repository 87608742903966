import React, { memo } from 'react';
import styled from '@emotion/styled';
import { InputBase } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 4px;
  padding: 2px 8px;
  margin-bottom: 16px;
  border: 1px solid transparent;

  &:focus-within {
    border: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
  }
`;

const StyledInputBase = styled(InputBase)`
  color: #d6d6dc;
  width: 100%;

  & .MuiInputBase-input {
    &:focus {
      background-color: transparent;
    }
  }
`;

const SearchBar = memo(({ value, onChange, onFocus }) => (
  <SearchWrapper>
    <Search sx={{ color: '#d6d6dc', marginRight: '8px' }} />
    <StyledInputBase
      placeholder="Search files..."
      value={value}
      onChange={onChange}
      onFocus={onFocus}
    />
  </SearchWrapper>
));

export default SearchBar;