import React, { useState } from 'react';
import { Popover, TextField, Button, Box } from '@mui/material';
import styled from '@emotion/styled';
import { useChatContext } from '../../../../contexts/ChatContext';

const StyledPopover = styled(Popover)`
  transition: all 0.1s;
  .MuiPopover-paper {
    background-color: #1e1e1e;
    border-radius: 12px;
    padding: 16px;
  }
`;

const URLTextArea = styled(TextField)`
  background-color: #1e1e1e;
  margin-bottom: 10px;
  width: 100%;

  .MuiInputBase-input {
    font-size: 0.8rem;
    white-space: pre-wrap;
    overflow-y: auto;
    color: #d6d6dc;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(255, 255, 255, 0.23);
    }
    &:hover fieldset {
      border-color: rgba(255, 255, 255, 0.5);
    }
    &.Mui-focused fieldset {
      border-color: #4a90e2;
    }
  }
`;

const SubmitButton = styled(Button)`
  background-color: #4a90e2;
  &:hover {
    background-color: #3a7bc8;
  }
`;

const URLUploadPopover = ({ anchorEl, onClose, threadId }) => {
  const [urls, setUrls] = useState('');
  const { addWebsiteToChat } = useChatContext();

  const handleSubmit = () => {
    const urlList = urls.split('\n').filter((url) => url.trim() !== '');
    urlList.forEach((url) => addWebsiteToChat(url, threadId));
    setUrls('');
    onClose();
  };

  return (
    <StyledPopover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box sx={{ width: 300 }}>
        <URLTextArea
          multiline
          rows={3}
          rowsMax={20}
          value={urls}
          onChange={(e) => setUrls(e.target.value)}
          placeholder="Enter URLs, one per line"
        />
        <SubmitButton
          variant="contained"
          onClick={handleSubmit}
          fullWidth
        >
          Submit
        </SubmitButton>
      </Box>
    </StyledPopover>
  );
};


export default URLUploadPopover;