import React, { createContext, useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import db from '../services/DexieDB';
import { debugLogger } from '../lib/debugLogger';
import * as NodeUtils from '../lib/nodeUtils';
import * as NodeConstructors from '../graph/utils/nodeConstructors';

const UserSessionContext = createContext();

/*
There seems to be a bug where we lose sessions

Potentially to session switching or restarting the app when we killed it in between? Or maybe mismatch between currentSessionData and rootThreadContainerRef.current?

Overall very confused, hard to repro, seems fine in lightweight testing

Okay was able to repro by doing something like this:

1. Create a new session
2. Leave it for a while
3. Come back and add messages

IIRC we just deleted the aute-clear thingy so now it's not a problem

*/

export const UserSessionProvider = ({ children }) => {
    const [currentSessionData, setCurrentSessionData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const rootThreadContainerRef = useRef(null);
    const lastSavedRef = useRef(null);
    const [isSessionReady, setIsSessionReady] = useState(false);

    const serializeRootThreadContainer = useCallback((rootThreadContainer) => {
        const serializeNode = (node) => {
            const serializedNode = { ...node };
            if (node.children instanceof Map) {
                serializedNode.children = Array.from(node.children.entries());
                serializedNode.children.forEach(([childId, childNode], index) => {
                    serializedNode.children[index] = [childId, serializeNode(childNode)];
                });
            }
            return serializedNode;
        };
        return serializeNode(rootThreadContainer);
    }, []);

    const deserializeRootThreadContainer = useCallback((serializedContainer) => {
        const deserializeNode = (node) => {
            const deserializedNode = { ...node };
            if (Array.isArray(node.children)) {
                deserializedNode.children = new Map(node.children.map(([childId, childNode]) => [childId, deserializeNode(childNode)]));
            }
            return deserializedNode;
        };

        return deserializeNode(serializedContainer);
    }, []);

    const createNewSession = useCallback(async (initialData = {}) => {
        debugLogger.log('debug', '[UserSessionContext] Creating new session', { initialData });
        try {
            setIsLoading(true);
            const newRootThreadContainer = NodeConstructors.initRootThreadContainer();
            rootThreadContainerRef.current = newRootThreadContainer;

            const newSessionData = {
                createdAt: new Date(),
                lastUpdated: new Date(),
                title: initialData.title || `Session ${new Date().toLocaleString()}`,
                systemPrompt: initialData.systemPrompt || '',
                rootThreadContainer: serializeRootThreadContainer(newRootThreadContainer),
                messageCount: 0
            };

            const id = await db.chatSessions.add(newSessionData);
            newSessionData.id = id;

            setCurrentSessionData(newSessionData);
            lastSavedRef.current = JSON.stringify(newSessionData.rootThreadContainer);

            debugLogger.log('info', '[UserSessionContext] New session created', { id });
            return id;
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to create new session', { error, stack: error.stack });
            setError('Failed to create new session');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [serializeRootThreadContainer]);

    const saveSession = useCallback(async () => {
        if (isSaving || !currentSessionData || !rootThreadContainerRef.current) {
            // debugLogger.log('debug', '[UserSessionContext] Skipping save - already saving, no current session, or no root thread container');
            return;
        }

        const currentSerialized = JSON.stringify(serializeRootThreadContainer(rootThreadContainerRef.current));
        if (currentSerialized === lastSavedRef.current) {
            debugLogger.log('trace', '[UserSessionContext] Skipping save - no changes detected');
            return;
        }

        try {
            setIsSaving(true);
            // debugLogger.log('info', '[UserSessionContext] Auto-saving session', { sessionId: currentSessionData.id });
            const messageCount = NodeUtils.countMessages(rootThreadContainerRef.current);
            const sessionData = {
                ...currentSessionData,
                lastUpdated: new Date(),
                rootThreadContainer: serializeRootThreadContainer(rootThreadContainerRef.current),
                messageCount: messageCount
            };

            await db.chatSessions.update(currentSessionData.id, sessionData);
            lastSavedRef.current = currentSerialized;

            setCurrentSessionData(sessionData);
            // debugLogger.log('debug', '[UserSessionContext] Session auto-saved successfully', { sessionId: currentSessionData.id, messageCount: sessionData.messageCount });
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to auto-save session', { error, stack: error.stack, sessionId: currentSessionData.id, messageCount: currentSessionData.messageCount });
            setError('Failed to save session');
        } finally {
            setIsSaving(false);
        }
    }, [currentSessionData, isSaving, serializeRootThreadContainer]);

    const switchSession = useCallback(async (id) => {
        try {
            setIsLoading(true);
            const session = await db.chatSessions.get(id);

            if (!session) {
                debugLogger.log('error', '[UserSessionContext] Session not found', { id });
                throw new Error('Session not found');
            }

            const deserializedRootThreadContainer = deserializeRootThreadContainer(session.rootThreadContainer);
            rootThreadContainerRef.current = deserializedRootThreadContainer;
            lastSavedRef.current = JSON.stringify(session.rootThreadContainer);

            setCurrentSessionData(session);

            debugLogger.log('debug', '[UserSessionContext] Successfully switched to session', { id });
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to switch session', { id, error, stack: error.stack });
            setError('Failed to switch session');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [deserializeRootThreadContainer]);

    const updateSession = useCallback(async (updateData) => {
        try {
            setIsLoading(true);
            await db.transaction('rw', db.chatSessions, async () => {
                const currentSession = await db.chatSessions.get(currentSessionData.id);
                if (!currentSession) throw new Error('Session not found');
                const updatedSessionData = { ...currentSession, ...updateData, lastUpdated: new Date() };
                await db.chatSessions.update(currentSession.id, updatedSessionData);
                setCurrentSessionData(updatedSessionData);
            });
            // debugLogger.log('info', '[UserSessionContext] Session updated', { id: currentSessionData.id });
        } catch (error) {
            setError('Failed to update session');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [currentSessionData]);

    const deleteSession = useCallback(async (id) => {
        debugLogger.log('debug', '[UserSessionContext] Deleting session', { id });
        try {
            await db.chatSessions.delete(id);
            if (currentSessionData && currentSessionData.id === id) {
                const newSession = await db.chatSessions.orderBy('lastUpdated').last();
                if (newSession) {
                    await switchSession(newSession.id);
                } else {
                    await createNewSession();
                }
            }
            debugLogger.log('info', '[UserSessionContext] Session deleted', { id });
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to delete session', { id, error, stack: error.stack });
            setError('Failed to delete session');
            throw error;
        }
    }, [currentSessionData, switchSession, createNewSession]);

    const deleteAllSessions = useCallback(async () => {
        debugLogger.log('info', '[UserSessionContext] Deleting all sessions');
        try {
            setIsLoading(true);
            await db.chatSessions.clear();
            // await createNewSession();
            debugLogger.log('info', '[UserSessionContext] All sessions deleted, new session created');
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to delete all sessions', { error, stack: error.stack });
            setError('Failed to delete all sessions');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [createNewSession]);

    const deleteEmptySessions = useCallback(async () => {
        // debugLogger.log('debug', '[UserSessionContext] Attempting to delete empty sessions');
        try {
            const emptySessionIds = await db.chatSessions
                .where('messageCount').equals(0)
                .and(session => session.id !== currentSessionData?.id)
                .primaryKeys();

            debugLogger.log('info', '[UserSessionContext] Found empty sessions', { count: emptySessionIds.length });

            if (emptySessionIds.length > 0) {
                await db.chatSessions.bulkDelete(emptySessionIds);
                debugLogger.log('info', '[UserSessionContext] Deleted empty sessions', { count: emptySessionIds.length, deletedIds: emptySessionIds });
            } else {
                debugLogger.log('debug', '[UserSessionContext] No empty sessions to delete');
            }
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to delete empty sessions', { error, stack: error.stack });
        }
    }, [currentSessionData]);

    const listSessions = useCallback(async (limit = 100, offset = 0) => {
        try {
            const sessions = await db.chatSessions.orderBy('lastUpdated').reverse().offset(offset).limit(limit).toArray();
            return sessions;
        } catch (error) {
            debugLogger.log('error', '[UserSessionContext] Failed to list sessions', { error, stack: error.stack });
            setError('Failed to list sessions');
            throw error;
        }
    }, []);

    useEffect(() => {
        const saveInterval = setInterval(() => {
            saveSession();
        }, 10000);

        return () => clearInterval(saveInterval);
    }, [saveSession]);

    useEffect(() => {
        const initializeSession = async () => {
            await createNewSession();
            setIsSessionReady(true);
        };
        initializeSession();
    }, [createNewSession]);

    // useEffect(() => {
    //     const cleanupInterval = setInterval(() => {
    //         deleteEmptySessions();
    //     }, 120000); // Run every 2 minutes

    //     return () => clearInterval(cleanupInterval);
    // }, [deleteEmptySessions]);

    const contextValue = useMemo(() => ({
        currentSessionData,
        rootThreadContainerRef,
        isLoading,
        error,
        isSaving,
        createNewSession,
        switchSession,
        saveSession,
        updateSession,
        deleteSession,
        deleteAllSessions,
        listSessions,
        deleteEmptySessions,
        isSessionReady
    }), [
        currentSessionData,
        isLoading,
        error,
        isSaving,
        createNewSession,
        switchSession,
        saveSession,
        updateSession,
        deleteSession,
        deleteAllSessions,
        listSessions,
        deleteEmptySessions,
        isSessionReady
    ]);

    return (
        <UserSessionContext.Provider value={contextValue}>
            {children}
        </UserSessionContext.Provider>
    );
};

export const useUserSessionContext = () => useContext(UserSessionContext);