import React, { createContext, useContext, useState, useRef, useCallback, useEffect, useLayoutEffect, useMemo } from 'react'; import { debounce, throttle } from 'lodash';
import { produce } from 'immer';

import {
  findNode,
} from '../lib/nodeUtils';

import * as NodeUtils from '../lib/nodeUtils'
import { debugLogger } from '../lib/debugLogger';

import * as FileProjectService from '../services/FileProjectService';
import { fetchWebsiteChatMessage } from '../services/ChatWebsiteService';
import { useUIContext } from './UIContext';
import { useLLMConfig } from './LLMConfigContext';
import { useUserSessionContext } from './UserSessionContext';

import { readFile, processFileContent, getLanguageFromExtension } from '../lib/fileUtils';
import { threadToList, cloneThreadUpToNodeId } from '../lib/threadUtils';
import * as NodeConstructors from '../graph/utils/nodeConstructors';
import { generateNodeId } from '../graph/utils/idGenerator';
import StreamManager from '../graph/StreamManager';


export const ChatContext = createContext();

export const ChatProvider = ({ children, }) => {
  // console.log('ChatProvider rendering');
  const { rootThreadContainerRef, currentSessionData, isSessionReady } = useUserSessionContext();
  // wtf is update scheduled?
  const updateScheduled = useRef(false);
  const [isChatReady, setIsChatReady] = useState(false);

  // Core data structure with both state and ref
  // UI-related states
  const [systemPrompt, setSystemPrompt] = useState('');
  const cursorNodeIdRef = useRef(null);
  const activeThreadIdRef = useRef(null);
  const activeCollectionIdRef = useRef(null);
  const activeNodePathIdsRef = useRef([]);
  const selectedNodeIdsRef = useRef(new Set());
  const tokenUsageRef = useRef({}); // nodeId ->{model, inputTokens, outputTokens, totalTokens} (I think? IIRC at least)

  // Refs for values that don't need to trigger re-renders
  const initialPromptRef = useRef('');

  const streamManagerRef = useRef(null);

  const {
    baseThreadWidthRef,
  } = useUIContext(); // TODO: chatAvailableWidth should be a ref
  const { llmConfigs } = useLLMConfig();

  // Update function that updates only the ref
  const updateRootThreadContainer = useCallback((updater) => {
    if (!rootThreadContainerRef.current) {
      debugLogger.log('error', '[ChatContext] rootThreadContainerRef is null');
      return;
    }
    rootThreadContainerRef.current = produce(rootThreadContainerRef.current, updater);
  }, [rootThreadContainerRef]);



  // Add this effect to sync when the session changes
  useEffect(() => {
    if (isSessionReady && currentSessionData && rootThreadContainerRef.current) {
      setIsChatReady(true);
    }
  }, [isSessionReady, currentSessionData?.id]);

  const updateNode = useCallback((nodeId, updatedContent) => {
    updateRootThreadContainer(draft => {
      NodeUtils.updateNode(draft, nodeId, node => {
        return typeof updatedContent === 'function'
          ? updatedContent(node)
          : { ...node, ...updatedContent };
      });
    });
  }, [updateRootThreadContainer]);

  // Initialize the StreamManager if it hasn't been created yet
  const getStreamManager = useCallback(() => {
    if (!streamManagerRef.current) {
      streamManagerRef.current = new StreamManager({
        selectNode,
        getNode,
        llmConfigs,
        updateNode,
        addNode,
      });
    }
    return streamManagerRef.current;
  }, [llmConfigs, updateNode]);

  const initializeStream = useCallback((newMessageId, thread, llmConfigName) => {
    getStreamManager().initializeStream(newMessageId, thread, llmConfigName);
  }, [getStreamManager]);

  const cancelGeneration = useCallback((messageId) => {
    getStreamManager().cancelGeneration(messageId);
  }, [getStreamManager]);

  const selectNode = useCallback((nodeId) => {
    updateRootThreadContainer(draft => {
      let newNode = null;

      const traverseAndUpdate = (node, isAncestorOfNewNode = false) => {
        if (node.node_id === nodeId) {
          newNode = node;
          isAncestorOfNewNode = true;
        }

        // Update node selection status
        node.isSelected = node.node_id === nodeId;
        node.isActiveThread = isAncestorOfNewNode && node.type === 'content.chat.thread';
        node.isActiveCollection = isAncestorOfNewNode && node.type === 'content.chat.collection';

        // Traverse children
        if (node.children) {
          for (const childNode of node.children.values()) {
            traverseAndUpdate(childNode, isAncestorOfNewNode);
          }
        }

        return isAncestorOfNewNode;
      };

      traverseAndUpdate(draft);

      if (!newNode) {
        console.warn(`Node with id ${nodeId} not found`);
      }
    });

    // TODO update activeThreadIdRef and activeCollectionIdRef etc

    cursorNodeIdRef.current = nodeId;
  }, [updateRootThreadContainer]);

  const getNode = useCallback((nodeId) => {
    return rootThreadContainerRef.current ? findNode(rootThreadContainerRef.current, nodeId) : null;
  }, []);

  const getAllThreads = useCallback(() => {
    return rootThreadContainerRef.current ? NodeUtils.getAllThreads(rootThreadContainerRef.current) : [];
  }, []);

  const addNode = useCallback((parentId, node) => {
    updateRootThreadContainer(draft => {
      NodeUtils.addNodeToParent(draft, parentId, node);
    });
    selectNode(node.node_id);
  }, [updateRootThreadContainer, selectNode]);

  const addThread = useCallback((parentId = null) => {
    const newThread = NodeConstructors.createThread(parentId || rootThreadContainerRef.current.node_id);
    addNode(parentId || rootThreadContainerRef.current.node_id, newThread);
  }, [addNode]);

  const addCollection = useCallback((parentId, title) => {
    const newCollection = NodeConstructors.createCollection(parentId, title);
    addNode(parentId, newCollection);
  }, [addNode]);


  const deleteNode = useCallback((nodeId) => {
    updateRootThreadContainer(draft => {
      NodeUtils.removeNodeFromParent(draft, nodeId);
      // cleanupCursorState logic
      if (cursorNodeIdRef.current === nodeId) {
        cursorNodeIdRef.current = null;
        activeThreadIdRef.current = null;
        activeCollectionIdRef.current = null;
        activeNodePathIdsRef.current = [];
      } else {
        activeNodePathIdsRef.current = activeNodePathIdsRef.current.filter(id => id !== nodeId);
        if (activeThreadIdRef.current === nodeId) activeThreadIdRef.current = null;
        if (activeCollectionIdRef.current === nodeId) activeCollectionIdRef.current = null;
      }
    });
  }, [updateRootThreadContainer]);


  const setActiveThread = useCallback((threadId) => {
    activeThreadIdRef.current = threadId;
    cursorNodeIdRef.current = null;
    activeNodePathIdsRef.current = threadId ? [threadId] : [];
    activeCollectionIdRef.current = null;
  }, []);

  const toggleNodeSelection = useCallback((nodeId) => {
    const newSet = new Set(selectedNodeIdsRef.current);
    if (newSet.has(nodeId)) {
      newSet.delete(nodeId);
    } else {
      newSet.add(nodeId);
    }
    selectedNodeIdsRef.current = newSet;
  }, []);

  const clearSelection = useCallback(() => {
    selectedNodeIdsRef.current = new Set();
  }, []);

  const toggleThreadMinimize = useCallback((threadId) => {
    updateRootThreadContainer(draft => {
      const thread = NodeUtils.findNode(draft, threadId);
      if (thread) {
        thread.isMinimized = !thread.isMinimized;
        thread.width = thread.isMinimized ? 80 : baseThreadWidthRef.current; // Assuming 80px for minimized width
      }
    });
  }, [updateRootThreadContainer, baseThreadWidthRef]);

  const getExistingThreadId = useCallback(() => {
    if (!rootThreadContainerRef?.current) return null;
    for (const [nodeId, node] of rootThreadContainerRef.current.children) {
      if (node.type === 'content.chat.thread') {
        return nodeId;
      }
    }
    return null;
  }, [rootThreadContainerRef]);

  // TODO: make separate function to addUserMessage, then rename this submitUserMessage (which calls both)

  const addUserMessageToThread = async (parentId, message) => {
    if (!rootThreadContainerRef?.current) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!rootThreadContainerRef?.current) {
        debugLogger.log('error', '[ChatContext] rootThreadContainer is still null after 1 second');
        return null;
      }
    }

    let thread = NodeUtils.findNode(rootThreadContainerRef.current, parentId);
    if (!thread) {
      debugLogger.log('debug', '[ChatContext] Parent thread not found, creating new thread', { parentId });
      const newThread = NodeConstructors.createThread(parentId);
      addNode(parentId, newThread);
      thread = newThread;
    }

    const newMessage = NodeConstructors.createUserMessage(parentId, message.content);
    addNode(parentId, newMessage);

    if (message.role === 'user') {
      await triggerAssistantMessage(parentId, 'default_chat');
    }

    return newMessage.node_id;
  };

  // TODO: callback
  const triggerAssistantMessage = async (parentId, llmConfigName) => {
    const newMessageId = generateNodeId(parentId);
    const parentThread = NodeUtils.findNode(rootThreadContainerRef.current, parentId);

    updateNode(parentThread.node_id, {
      isLoading: true,
    });

    try {
      await initializeStream(newMessageId, parentThread, llmConfigName);
    } catch (error) {
      debugLogger.log('error', '[ChatContext] Error in initializeStream', { error: error.message, stack: error.stack });
      updateNode(newMessageId, {
        isLoading: false,
        error: 'Failed to initialize stream'
      });
    }
    selectNode(newMessageId);
  };


  const forkThread = useCallback(async (endpointNodeId) => {
    if (!rootThreadContainerRef.current) {
      debugLogger.log('error', '[ChatContext] rootThreadContainer is null');
      return null;
    }

    let newThreadId;
    let newThread;
    updateRootThreadContainer(draft => {
      const threadNode = NodeUtils.getLowestAncestorOfType(draft, endpointNodeId, 'content.chat.thread');
      if (!threadNode) {
        debugLogger.log('error', '[ChatContext] Thread not found for endpoint', { endpointNodeId });
        return;
      }

      const parentThreadContainer = NodeUtils.findParentThreadContainer(draft, threadNode.node_id);
      if (!parentThreadContainer) {
        debugLogger.log('error', '[ChatContext] Parent thread container not found', { threadId: threadNode.node_id });
        return;
      }

      newThread = cloneThreadUpToNodeId(threadNode, endpointNodeId, parentThreadContainer.node_id);
      NodeUtils.addNodeToParent(parentThreadContainer, parentThreadContainer.node_id, newThread);
      newThreadId = newThread.node_id;
    });

    const lastChildIdInClonedThread = Array.from(newThread.children.values()).pop().node_id;

    selectNode(lastChildIdInClonedThread);

    return newThreadId;
  }, [updateRootThreadContainer, selectNode]);


  const forkAndResubmitMessage = useCallback(async (nodeId, newMessageText) => {
    const node = getNode(nodeId);
    if (!node) return;

    const previousSiblingId = NodeUtils.findPreviousSiblingId(rootThreadContainerRef.current, nodeId);

    let forkedThreadId;
    if (previousSiblingId) {
      // If there's a previous sibling, fork from it
      forkedThreadId = await forkThread(previousSiblingId);
    } else {
      // If there's no previous sibling, create a new thread
      const parentThreadContainer = NodeUtils.getLowestAncestorOfType(rootThreadContainerRef.current, nodeId, 'content.chat.thread_container');
      if (!parentThreadContainer) {
        console.error('No parent thread container found');
        return;
      }
      const newThread = NodeConstructors.createThread(parentThreadContainer.node_id);
      forkedThreadId = newThread.node_id;
      addNode(parentThreadContainer.node_id, newThread);
    }

    if (!forkedThreadId) return;

    const newMessageId = await addUserMessageToThread(forkedThreadId, {
      content: newMessageText,
      role: 'user',
      type: 'content.chat.message.user'
    });

    return newMessageId;
  }, [getNode, forkThread, addUserMessageToThread, addNode]);




  const addWebsiteToChat = useCallback(async (urlOrSearchResult, parentNodeId = null) => {
    if (!rootThreadContainerRef.current) {
      debugLogger.log('error', '[ChatContext] rootThreadContainer is null');
      return;
    }

    let searchResult;
    if (typeof urlOrSearchResult === 'string') {
      searchResult = { url: urlOrSearchResult };
    } else if (typeof urlOrSearchResult === 'object' && urlOrSearchResult.url) {
      searchResult = urlOrSearchResult;
    } else {
      debugLogger.log('error', '[ChatContext] Invalid input for addWebsiteToChat');
      return;
    }

    const { url } = searchResult;

    if (!parentNodeId) {
      parentNodeId = getExistingThreadId();
    }

    let nodeId;
    updateRootThreadContainer(draft => {
      const parentNode = NodeUtils.findNode(draft, parentNodeId);
      const existingMessage = Array.from(parentNode?.children?.values() || [])
        .find((message) => message.url === url);
      if (existingMessage) {
        debugLogger.log('debug', '[ChatContext] URL already exists in thread', { url });
        return;
      }

      nodeId = generateNodeId(parentNodeId);
      const newNode = {
        node_id: nodeId,
        type: 'content.chat.message.website_fulltext',
        url,
        content: '',
        links: [],
        is_accessible_for_free: false,
        role: 'user',
        n_words: 0,
        isLoading: true,
        timestamp: new Date().toISOString(),
        title: searchResult.title || '',
        width: 800,
        favicon: searchResult.favicon || '',
        description: searchResult.description || '',
        datePublishedDisplayText: searchResult.datePublishedDisplayText || null,
      };
      NodeUtils.addNodeToParent(draft, parentNodeId, newNode);
    });

    try {
      const websiteData = await fetchWebsiteChatMessage(url);
      updateNode(nodeId, {
        ...websiteData,
        isLoading: false,
      });
    } catch (error) {
      debugLogger.log('error', '[ChatContext] Failed to fetch website data', { error });
      updateNode(nodeId, {
        isLoading: false,
        error: 'Failed to fetch website data',
      });
    }
  }, [updateRootThreadContainer, getExistingThreadId, updateNode]);


  const addDocumentToThread = useCallback(async (parentId, file) => {
    debugLogger.log('debug', '[ChatContext] Adding document to thread', { parentId, file });
    const nodeId = generateNodeId(parentId);
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const language = getLanguageFromExtension(fileExtension);

    try {
      const content = await readFile(file);
      const processedContent = processFileContent(content, file.type);

      const newNode = NodeConstructors.createDocumentNode(parentId, file.name, processedContent);
      newNode.node_id = nodeId;
      newNode.size = file.size;
      newNode.fileType = file.type;
      newNode.name = file.name;
      newNode.title = file.name;
      newNode.path = file.webkitRelativePath || file.name;
      newNode.language = language;

      addNode(parentId, newNode);
    } catch (error) {
      debugLogger.log('error', '[ChatContext] Failed to read file', { error });
      const errorNode = {
        node_id: nodeId,
        type: 'content.chat.message.document',
        name: file.name,
        content: 'Error adding document',
        error: 'Failed to read file',
        role: 'user',
        width: 800,
        timestamp: new Date().toISOString(),
      };
      addNode(parentId, errorNode);
    }
  }, [addNode]);

  const handleFileUpload = useCallback((files, parentId) => {
    Array.from(files).forEach(file => addDocumentToThread(parentId, file));
  }, [addDocumentToThread]);


  const handleProjectUpload = useCallback(async (threadId) => {
    try {
      const newNode = await FileProjectService.triggerProjectUpload(threadId);
      addNode(threadId, newNode);
      console.log('Added filtered collection node to thread:', threadId);
    } catch (error) {
      console.error('Error in handleProjectUpload:', error);
    }
  }, [addNode]);


  const clearMessages = useCallback(async () => {
    debugLogger.log('debug', '[ChatContext] Clearing messages');
    updateRootThreadContainer(() => NodeConstructors.initRootThreadContainer());
    return getExistingThreadId();
  }, [updateRootThreadContainer, getExistingThreadId]);


  const executeMap = useCallback(async (collectionNode, promptTemplate) => {
    if (!rootThreadContainerRef.current) {
      debugLogger.log('error', '[ChatContext] rootThreadContainer is null');
      return null;
    }
    debugLogger.log('debug', '[ChatContext] Executing map', { collectionNodeId: collectionNode.node_id, promptTemplate });

    let outerMapResultThreadId;
    let innerThreadContainerId;
    updateRootThreadContainer(draft => {
      const parentThreadContainer = NodeUtils.getLowestAncestorOfType(draft, collectionNode.node_id, 'content.chat.thread_container');
      debugLogger.log('debug', '[ChatContext] Parent thread container', { parentThreadContainer });
      if (!parentThreadContainer) {
        debugLogger.log('error', '[ChatContext] Parent thread container not found for collection', { collectionNodeId: collectionNode.node_id });
        return;
      }

      const outerMapResultThread = NodeConstructors.createThread(parentThreadContainer.node_id);
      const innerThreadContainer = NodeConstructors.createThreadContainerNode(outerMapResultThread.node_id);

      NodeUtils.addNodeToParent(draft, parentThreadContainer.node_id, outerMapResultThread);
      NodeUtils.addNodeToParent(outerMapResultThread, outerMapResultThread.node_id, innerThreadContainer);

      outerMapResultThreadId = outerMapResultThread.node_id;
      innerThreadContainerId = innerThreadContainer.node_id;

    });

    const newThreads = [];
    for (const [childId, childNode] of collectionNode.children.entries()) {
      const childContent = childNode.content;
      const formattedPrompt = promptTemplate.replace('{item}', childContent);

      try {
        const innerMapResultThread = NodeConstructors.createThread(outerMapResultThreadId);
        innerMapResultThread.isLoading = true;

        // Create user message
        const userMessageId = generateNodeId(innerMapResultThread.node_id);
        const userMessage = NodeConstructors.createUserMessage(innerMapResultThread.node_id, formattedPrompt);
        userMessage.node_id = userMessageId;

        // Create assistant message
        const assistantMessageId = generateNodeId(innerMapResultThread.node_id);
        const assistantMessage = NodeConstructors.createAssistantMessage(innerMapResultThread.node_id);
        assistantMessage.node_id = assistantMessageId;
        assistantMessage.isLoading = true;


        innerMapResultThread.children = new Map([
          [userMessageId, userMessage],
          [assistantMessageId, assistantMessage]
        ]);

        updateRootThreadContainer(draft => {
          const container = NodeUtils.findNode(draft, innerThreadContainerId);
          if (container) {
            NodeUtils.addNodeToParent(container, container.node_id, innerMapResultThread);
          }
        });

        await initializeStream(assistantMessageId, innerMapResultThread, 'default_chat');

        newThreads.push({ threadId: innerMapResultThread.node_id, assistantMessageId });
        debugLogger.log('debug', '[ChatContext] New inner thread with messages created and stream initialized', { newThreadId: innerMapResultThread.node_id, assistantMessageId });
      } catch (error) {
        debugLogger.log('error', '[ChatContext] Failed to create thread or initialize stream', { error: error.message, stack: error.stack, childId });
      }
    }

    debugLogger.log('debug', '[ChatContext] Map execution completed', { newThreadCount: newThreads.length });
    return outerMapResultThreadId;
  }, [rootThreadContainerRef, updateRootThreadContainer, initializeStream, NodeUtils]);

  const contextValue = useMemo(() => ({
    systemPrompt,
    setSystemPrompt,
    initialPromptRef,
    getExistingThreadId,
    addNode,
    addThread,
    forkThread,
    forkAndResubmitMessage,
    addUserMessageToThread,
    updateNode,
    deleteNode,
    clearMessages,
    handleFileUpload,
    handleProjectUpload,
    addWebsiteToChat,
    cancelGeneration,
    addDocumentToThread,
    executeMap,
    toggleThreadMinimize,
    getNode,
    getAllThreads,
    addCollection,
    cursorNodeIdRef,
    activeThreadIdRef,
    activeCollectionIdRef,
    activeNodePathIdsRef,
    selectedNodeIdsRef,
    selectNode,
    selectThread: setActiveThread,
    updateRootThreadContainer,
    toggleNodeSelection,
    clearSelection,
    tokenUsageRef,
    isChatReady,
  }), [
    systemPrompt,
    getExistingThreadId,
    addNode,
    addThread,
    forkThread,
    forkAndResubmitMessage,
    addUserMessageToThread,
    updateNode,
    deleteNode,
    clearMessages,
    handleFileUpload,
    handleProjectUpload,
    addWebsiteToChat,
    cancelGeneration,
    addDocumentToThread,
    executeMap,
    toggleThreadMinimize,
    getNode,
    getAllThreads,
    addCollection,
    selectNode,
    setActiveThread,
    updateRootThreadContainer,
    toggleNodeSelection,
    clearSelection,
    isChatReady,
  ]);

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);

export const useCollectionFunctions = () => {
  const {
    addNode,
    updateNode,
    forkThread,
    forkAndResubmitMessage,
    deleteNode,
    toggleThreadMinimize,
    addUserMessageToThread,
    cancelGeneration,
    addWebsiteToChat,
    selectNode,
    executeMap
  } = useChatContext();

  return useMemo(() => ({
    addNode,
    updateNode,
    forkThread,
    forkAndResubmitMessage,
    deleteNode,
    toggleThreadMinimize,
    addUserMessageToThread,
    cancelGeneration,
    addWebsiteToChat,
    selectNode,
    executeMap
  }), [addNode, updateNode, forkThread, forkAndResubmitMessage, deleteNode, toggleThreadMinimize, addUserMessageToThread, cancelGeneration, addWebsiteToChat, selectNode, executeMap]);
};

export const useCollectionActionbarFunctions = () => {
  const {
    addNode,
    selectNode,
    handleFileUpload,
    handleProjectUpload,
    addWebsiteToChat
  } = useChatContext();

  return useMemo(() => ({
    addNode,
    selectNode,
    handleFileUpload,
    handleProjectUpload,
    addWebsiteToChat
  }), [addNode, selectNode, handleFileUpload, handleProjectUpload, addWebsiteToChat]);
};



export const useLeafFunctions = () => {
  const {
    updateNode,
    deleteNode,
    forkThread,
    forkAndResubmitMessage
  } = useChatContext();

  return useMemo(() => ({
    updateNode,
    deleteNode,
    forkThread,
    forkAndResubmitMessage
  }), [updateNode, deleteNode, forkThread, forkAndResubmitMessage]);
};

export const useSlotFunctions = () => {
  const {
    forkThread,
    forkAndResubmitMessage,
    addUserMessageToThread,
    executeMap
  } = useChatContext();

  return useMemo(() => ({
    forkThread,
    forkAndResubmitMessage,
    addUserMessageToThread,
    executeMap
  }), [forkThread, forkAndResubmitMessage, addUserMessageToThread, executeMap]);
};


export const useChatNodeWrapperFunctions = () => {
  const {
    selectNode
  } = useChatContext();

  return useMemo(() => ({
    selectNode
  }), [selectNode]);
};


ChatContext.displayName = 'ChatContext';

export default ChatContext;