import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

import GoogleAuthComponent from './components/GoogleAuthComponent';
import { AuthProvider } from './contexts/AuthContext';
import { UserSessionProvider } from './contexts/UserSessionContext';
import { FeatureFlagsProvider } from './contexts/FeatureFlagsContext';
import { SearchQueryProvider } from './contexts/SearchQueryContext';
import { SearchResultsProvider } from './contexts/SearchResultsContext';
import { UIProvider } from './contexts/UIContext';
import { ChatProvider } from './contexts/ChatContext';
import { RootThreadContainerProvider } from './contexts/RootThreadContainerContext';
import { LLMConfigProvider } from './contexts/LLMConfigContext';
import { DebuggingProvider } from './contexts/DebuggingContext';
import { NodeRegistryProvider } from './contexts/NodeRegistryContext';
import { KeyboardProvider } from './contexts/KeyboardContext';
import SignUpPage from './SignUpPage';
import MainPage from './MainPage';
import LandingPage from './LandingPage';
import ErrorBoundary from './components/common/ErrorBoundary';
import SecureRoute from './components/SecureRoute';
import { CSP_DIRECTIVES } from './constants/csp';

const theme = createTheme({
  palette: {
    mode: 'dark',
    highest: '#121212',
    high: '#121212',
    low: '#121212',
    lowest: '#121212',
  },
});

const App = () => {
  return (
    <AuthProvider>
      <DebuggingProvider>

        <ErrorBoundary>
          <UserSessionProvider>
            <FeatureFlagsProvider>
              <NodeRegistryProvider>
                <UIProvider>
                  <KeyboardProvider>
                    <SearchQueryProvider>
                      <LLMConfigProvider>
                        <RootThreadContainerProvider>
                          <ChatProvider>
                            <SearchResultsProvider>

                              <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Helmet>
                                  <meta http-equiv="Content-Security-Policy" content={CSP_DIRECTIVES} />
                                  <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains" />
                                  <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
                                  <meta http-equiv="X-Content-Type-Options" content="nosniff" />
                                  <meta http-equiv="Referrer-Policy" content="strict-origin-when-cross-origin" />
                                </Helmet>
                                <Router>
                                  <Switch>
                                    <Route exact path="/" component={LandingPage} />
                                    <Route path="/login" component={GoogleAuthComponent} />
                                    <SecureRoute path="/engine" component={MainPage} />
                                    <Route path="/signup" component={SignUpPage} />
                                    <Redirect from="/search" to="/engine" />
                                    <Redirect from="/main" to="/engine" />
                                  </Switch>
                                </Router>
                              </ThemeProvider>

                            </SearchResultsProvider>
                          </ChatProvider>
                        </RootThreadContainerProvider>
                      </LLMConfigProvider>
                    </SearchQueryProvider>
                  </KeyboardProvider>
                </UIProvider>
              </NodeRegistryProvider>
            </FeatureFlagsProvider>
          </UserSessionProvider>
        </ErrorBoundary>
      </DebuggingProvider>
    </AuthProvider>
  );
};

export default App;