import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, IconButton, Autocomplete } from '@mui/material';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

import { UIContext } from '../../contexts/UIContext';
import { SearchQueryContext } from '../../contexts/SearchQueryContext';
import { fetchSuggestions } from '../../services/searchSuggestionService';


const SearchBarWrapper = styled.div`
  display: inline;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-height: 3rem;
  box-sizing: border-box;
`;

const SearchForm = styled.form`
  width: 100%;
  display: inline;
  align-items: center;
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  width: 100%;
  background-color: #1e1e1e;
  border-radius: 8px;
  border: 2px solid transparent;
  &:focus-within {
    border: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
    border-radius: 6px;  
  }
`;

const SearchTextField = styled(TextField)`
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
  padding-left: 5px;

  & .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInputBase-adornedEnd"][class*="MuiInputBase-filled"] {
    background-color: transparent;
  }

 & .MuiInputBase-root {
    background-color: transparent !important;
    color: #fff !important; // Set the default text color to white
    
    &:focus, &:hover, &:active {
      background-color: transparent !important;
    }

    & input {
      background-color: transparent !important;
      color: inherit !important; // Inherit the text color from parent
      -webkit-box-shadow: 0 0 0 30px transparent inset !important;
      transition: background-color 5000s ease-in-out 0s;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: #fff !important;
        box-shadow: 0 0 0 30px transparent inset !important;
        transition: background-color 5000s ease-in-out 0s;
      }
    }
  }

  & .MuiInput-underline:before {
    border-bottom: none;
  }

  & .MuiInput-underline:after {
    border-bottom: none;
  }
`;


const LandingSearchBar = ({ onSearch }) => {
  const { isFounderModeEnabled, isMobile } = useContext(UIContext);
  const [localQuery, setLocalQuery] = useState('');
  const { setQuery } = useContext(SearchQueryContext);

  const [suggestions, setSuggestions] = useState([]);
  const [open, setOpen] = useState(false);

  const enableSuggestions = isFounderModeEnabled;

  const inputRef = useRef(null);

  const handleInputChange = async (event, value, reason) => {
    if (reason === 'input') {
      setLocalQuery(value);
      if (enableSuggestions && value.length > 0) {
        const newSuggestions = await fetchSuggestions(value);
        if (newSuggestions.length > 0) {
          setOpen(true);
          setSuggestions(newSuggestions);
        } else {
          setOpen(false);
          setSuggestions([]);
        }
      }
    } else if (reason === 'clear') {
      setLocalQuery('');
      setOpen(false);
      setSuggestions([]);
    }
  };

  // TODO: not sure if launching with localquery is correct and fine, like maybe there will be issues where query is expected in mainpage?

  // Also claude said this, but I'm not sure it matters:
  // When a suggestion is selected, onSearch is called with the selected value.
  // However, the form submission event is not prevented here, so the form still gets submitted,
  // triggering the handleSearch function.
  const handleOptionSelected = async (event, value, reason) => {
    console.log('landingsearchbar handleOptionSelected - value:', value);
    if (reason === 'selectOption') {
      event.preventDefault();
      setLocalQuery(value);
      setOpen(false);
      onSearch(value);
    }

    if (isMobile && inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleSearch = (event) => {
    console.log('landingsearchbar handleSearch - localQuery:', localQuery);
    event.preventDefault();
    setQuery(localQuery);
    onSearch(localQuery);
    if (isMobile && inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <SearchBarWrapper>
      <SearchForm onSubmit={handleSearch}>
        <SearchBarContainer>
          {enableSuggestions && (
            <Autocomplete
              value={localQuery}
              filterOptions={(x) => x}
              options={suggestions || []}
              open={open}
              onInputChange={handleInputChange}
              onChange={handleOptionSelected}
              onClose={() => setOpen(false)}
              sx={{
                width: '100%',
                '& .MuiAutocomplete-paper': {
                  width: '100%',
                },
              }}
              disableClearable
              clearOnEscape={true}
              renderInput={(params) => (
                <SearchTextField
                  {...params}
                  id="outlined-basic"
                  variant="standard"
                  autoFocus
                  inputRef={inputRef}
                  placeholder="Enter a query..." // Added placeholder
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    endAdornment: null,
                    inputProps: {
                      ...params.inputProps,
                      enterKeyHint: 'go',
                    },
                    onKeyDown: (event, value, reason) => {
                      if (event.key === 'Enter') {
                        handleSearch(event);
                      }
                    },
                  }}
                />
              )}
            />
          )}

          {!enableSuggestions && (
            <SearchTextField
              id="outlined-basic"
              variant="standard"
              autoFocus
              value={localQuery}
              onChange={(event) => setLocalQuery(event.target.value)}
              placeholder="Enter a query..." // Added placeholder
              InputProps={{
                disableUnderline: true,
                endAdornment: null,
              }}
            />
          )}
          <IconButton type="submit" color="inherit">
            <SearchIcon />
          </IconButton>
        </SearchBarContainer>
      </SearchForm>
    </SearchBarWrapper>
  );
};

export default LandingSearchBar;