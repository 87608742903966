import { threadToList } from '../lib/threadUtils';

export const ChatDownloadService = {
    getDownloadableThreadJson: (thread) => {
        return JSON.stringify(thread, null, 2);
    },

    getDownloadableThreadTxt: (thread) => {
        const messages = threadToList(thread);
        let formattedText = '';

        for (const message of messages) {
            formattedText += `[${message.role}]\n${message.content}\n\n`;
        }

        return formattedText;
    },

    downloadThreadAsJson: (thread) => {
        const jsonData = ChatDownloadService.getDownloadableThreadJson(thread);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }).replace(/:/g, "-");
        link.href = url;
        link.download = `stickshift_thread_${thread.node_id}_${currentTime}.json`;
        link.click();
    },

    downloadThreadAsTxt: (thread) => {
        const formattedText = ChatDownloadService.getDownloadableThreadTxt(thread);
        const blob = new Blob([formattedText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }).replace(/:/g, "_");
        link.href = url;
        link.download = `stickshift_thread_${thread.node_id}_${currentTime}.md`;
        link.click();
    }
};

export default ChatDownloadService;