import React, { useContext } from 'react';
import Receipt from './Receipt';
import CustomArgsComponent from './CustomArgsComponent';
import { Box, Card } from '@mui/material';

import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import { UIContext } from '../../../contexts/UIContext';
import ContactInfo from './ContactInfo';
import ForbiddenComponent from './ForbiddenComponent'; // Import ForbiddenComponent

import BoostPanel from './BoostPanel/BoostPanel';
import SidebarPanel from './SidebarPanel';
import UserFeedback from './UserFeedback';

const LeftSidebar = () => {
  const { featureFlags } = useContext(FeatureFlagsContext);
  const { isMobile } = useContext(UIContext);

  return (
    <Box sx={{
      bgcolor: 'background.default',
      height: '100%', // Adjust this value based on the actual height of your header/footer
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      overflowY: 'auto', // Allow vertical scrolling
      overflowX: 'hidden', // Prevent horizontal scrolling      
      backgroundColor: 'transparent' // Keep the background color
    }}>
      <Box sx={{}}>
        <BoostPanel />

        {featureFlags.receipt && (
          <SidebarPanel>
            <Card sx={{ maxHeight: '55%', overflow: 'unset', padding: '1%', background: '#121212' }}><Receipt /></Card>
          </SidebarPanel>
        )}

        {featureFlags.customArgsComponent && (
          <SidebarPanel><CustomArgsComponent /></SidebarPanel>

        )}

        <SidebarPanel>
          <UserFeedback /> {/* Add the UserFeedback component */}
        </SidebarPanel>

        {!isMobile && (
          <SidebarPanel>
            <ForbiddenComponent />
          </SidebarPanel>
        )}



        <SidebarPanel>
          <ContactInfo />
        </SidebarPanel>

      </Box>

    </Box >
  );
};

export default LeftSidebar;