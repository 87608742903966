import { useState, useEffect, useCallback, useRef } from 'react';
import { throttle } from 'lodash';

/*

Discussion on necessity of IntersectionObserver:

After this analysis, we can conclude:
The Intersection Observer is not purely redundant. It provides unique benefits:
1. It can detect changes that aren't caused by scrolling.
2. It provides built-in information about the visibility ratio of the element.
3. It's more efficient for tracking multiple elements simultaneously.
4. It can detect when an element enters or leaves the viewport without additional calculations.

However, for your specific use case of positioning a portal relative to another element, a scroll listener might be sufficient if:

1. You're only concerned with changes due to scrolling.
2. You're willing to perform additional calculations to determine visibility.
You're only tracking one or a few elements.
3. The Intersection Observer becomes more valuable when:
You need to know precisely when an element enters or leaves the viewport.
You're tracking many elements simultaneously.
You want to detect position changes caused by factors other than scrolling.
You need to know the exact visibility ratio of an element without extra calculations.

1. The "visible portion" calculation:
In the original implementation, this calculation was used to center the portal on the visible part of the element, especially when the element is partially out of view.
What the Intersection Observer provides:
Information about whether the element is intersecting the viewport (or a specified root).
The intersection ratio (how much of the element is visible).
The intersection rect (the rectangle representing the visible portion of the element).
What a scroll listener provides:
Notification that a scroll event has occurred.
No direct information about element visibility or intersection.
Analysis:
Calculating visible portion without Intersection Observer:
We can calculate the visible portion using the element's bounding rectangle and the viewport (or scrollable container) dimensions:


Intersection observer runs off the main thread apparently?

Could still potentially accomplish this with a scroll listener and listeners for other size/position changes if we want to simplify and accomplish the smooth scroll positioning

*/

export const useSlotPortalPositioning = (targetRef, position = 'right') => {
    // State for portal position and visibility
    const [portalPosition, setPortalPosition] = useState({ top: 0, left: 0 });
    const [isVisible, setIsVisible] = useState(false);

    // Ref for manual update triggering
    const updateTriggerRef = useRef(0);

    // Ref to track if initial positioning has occurred
    const initialPositionRef = useRef(false);

    // Ref to store cleanup functions
    const cleanupFunctionsRef = useRef([]);

    // Function to find the scrollable ancestor
    const findScrollableAncestor = useCallback(() => {
        if (!targetRef.current) return null;
        let element = targetRef.current.parentElement;
        while (element && element !== document.body) {
            const { overflowY } = window.getComputedStyle(element);
            if (overflowY === 'auto' || overflowY === 'scroll') {
                return element;
            }
            element = element.parentElement;
        }
        return window;
    }, [targetRef]);

    // Function to calculate the visible portion of the element
    const calculateVisiblePortion = useCallback(() => {
        if (!targetRef.current) return null;

        const targetRect = targetRef.current.getBoundingClientRect();
        const scrollableAncestor = findScrollableAncestor();

        let viewportRect;
        if (scrollableAncestor === window) {
            viewportRect = {
                top: 0,
                left: 0,
                bottom: window.innerHeight,
                right: window.innerWidth
            };
        } else {
            viewportRect = scrollableAncestor.getBoundingClientRect();
        }

        const visibleTop = Math.max(targetRect.top, viewportRect.top);
        const visibleBottom = Math.min(targetRect.bottom, viewportRect.bottom);
        const visibleLeft = Math.max(targetRect.left, viewportRect.left);
        const visibleRight = Math.min(targetRect.right, viewportRect.right);

        return {
            top: visibleTop,
            bottom: visibleBottom,
            left: visibleLeft,
            right: visibleRight,
            height: visibleBottom - visibleTop,
            width: visibleRight - visibleLeft
        };
    }, [targetRef, findScrollableAncestor]);

    // Function to calculate and set portal position
    const updatePosition = useCallback(() => {
        if (!targetRef.current) return;

        const visiblePortion = calculateVisiblePortion();
        if (!visiblePortion) return;

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        let top, left;

        if (position === 'right') {
            top = visiblePortion.top + scrollTop + (visiblePortion.height / 2);
            left = visiblePortion.right + scrollLeft;
        } else if (position === 'top') {
            top = visiblePortion.top + scrollTop;
            left = visiblePortion.left + scrollLeft + (visiblePortion.width / 2);
        }

        setPortalPosition({ top, left });

        // Mark initial positioning as complete
        if (!initialPositionRef.current) {
            initialPositionRef.current = true;
        }
    }, [targetRef, position, calculateVisiblePortion]);

    // Throttled version of updatePosition for frequent updates
    const throttledUpdatePosition = useCallback(
        throttle(updatePosition, 0),
        [updatePosition]
    );

    const throttledCheckPosition = useCallback(
        throttle(() => {
            updatePosition();
            requestAnimationFrame(throttledCheckPosition);
        }, 0), // Throttle
        [updatePosition]
    );

    useEffect(() => {
        const rafId = requestAnimationFrame(throttledCheckPosition);
        return () => cancelAnimationFrame(rafId);
    }, [throttledCheckPosition]);

    // Effect for Intersection Observer (visibility detection only)
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    setIsVisible(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        updatePosition();
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0
            }
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        cleanupFunctionsRef.current.push(() => observer.disconnect());

        return () => observer.disconnect();
    }, [targetRef, updatePosition]);

    // Effect for ResizeObserver (target and scrollable ancestor)
    useEffect(() => {
        const scrollableAncestor = findScrollableAncestor();
        if (!targetRef.current || !scrollableAncestor) return;

        const resizeObserver = new ResizeObserver(() => {
            updatePosition();
        });

        resizeObserver.observe(targetRef.current);
        if (scrollableAncestor !== window) {
            resizeObserver.observe(scrollableAncestor);
        }

        cleanupFunctionsRef.current.push(() => resizeObserver.disconnect());

        return () => resizeObserver.disconnect();
    }, [targetRef, findScrollableAncestor, updatePosition]);

    // Effect for MutationObserver
    useEffect(() => {
        if (!targetRef.current) return;

        const mutationObserver = new MutationObserver(() => {
            throttledUpdatePosition();
        });
        mutationObserver.observe(targetRef.current, {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true
        });

        cleanupFunctionsRef.current.push(() => mutationObserver.disconnect());

        return () => mutationObserver.disconnect();
    }, [targetRef, throttledUpdatePosition]);

    // Effect for scroll events
    useEffect(() => {
        const handleScroll = () => {
            throttledUpdatePosition();
        };
        window.addEventListener('scroll', handleScroll, true);

        cleanupFunctionsRef.current.push(() => window.removeEventListener('scroll', handleScroll, true));

        return () => window.removeEventListener('scroll', handleScroll, true);
    }, [throttledUpdatePosition]);

    // Effect for manual update triggering
    useEffect(() => {
        updatePosition();
    }, [updateTriggerRef.current, updatePosition]);

    // Cleanup effect
    useEffect(() => {
        return () => {
            cleanupFunctionsRef.current.forEach(cleanup => cleanup());
            cleanupFunctionsRef.current = [];
        };
    }, []);

    // Function to manually trigger an update
    const triggerUpdate = useCallback(() => {
        updateTriggerRef.current += 1;
    }, []);

    return {
        portalPosition,
        isVisible,
        triggerUpdate,
        isInitiallyPositioned: initialPositionRef.current
    };
};

export default useSlotPortalPositioning;