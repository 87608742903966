import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Highlight, themes } from 'prism-react-renderer';
import { useTextSelection } from '../../../hooks/useTextSelection';

const CodeBlockWrapper = styled.div`
  position: relative;
  max-width: calc(100% - 0.5rem); // TODO make this sizing more precise
  margin-bottom: 1rem;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${themes.vsDark.plain.backgroundColor};
  border: 2px solid #252526; // Added border with the same color as the header
  margin-left: 0.5rem;
  transition: all 0.1s;
`;

const CodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
  background-color: #252526;
  color: #d4d4d4;
  font-size: 0.9rem;
  font-family: 'Fira Code', monospace;
  overflow: hidden;
`;

const Pre = styled.pre`
  margin: 0;
  padding: 1em;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.1s;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #424242;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #1e1e1e;
  }
`;

const Line = styled.div`
  display: table-row;
  overflow: hidden;
`;

const LineContent = styled.span`
  display: table-cell;
  overflow: hidden;
`;

const WrappedLine = styled(Line)`
  white-space: pre-wrap;
  word-break: break-word;
`;

const WrappedLineContent = styled(LineContent)`
  display: inline;
`;

const CopyButton = styled(IconButton)`
  color: #d4d4d4;
  padding: 4px;
  &:hover {
    background-color: rgba(60, 60, 60, 0.8);
  }
  overflow: hidden;
`;

const CopyButtonIcon = styled(FileCopyIcon)`
  width: 1.2rem;
  height: 1.2rem;
  overflow: hidden;
`;

// TODO: this code is fucking awful. we should consolidate this shit.

const MemoizedToken = React.memo(({ getTokenProps, token }) => (
  <span {...getTokenProps({ token })} />
));

const MemoizedLineContent = React.memo(({ line, getTokenProps }) => (
  <WrappedLineContent>
    {line.map((token, key) => (
      <MemoizedToken key={key} getTokenProps={getTokenProps} token={token} />
    ))}
  </WrappedLineContent>
));

const MemoizedLine = React.memo(({ line, lineProps, getTokenProps }) => {
  const { key, ...restLineProps } = lineProps;
  return (
    <WrappedLine key={key} {...restLineProps}>
      <MemoizedLineContent line={line} getTokenProps={getTokenProps} />
    </WrappedLine>
  );
});

const MemoizedHighlight = React.memo(({ theme, code, language }) => (
  <Highlight theme={theme} code={code} language={language}>
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <Pre className={className} style={style}>
        {tokens.map((line, i) => (
          <MemoizedLine
            key={i}
            line={line}
            lineProps={getLineProps({ line, key: i })}
            getTokenProps={getTokenProps}
          />
        ))}
      </Pre>
    )}
  </Highlight>
));

const CodeBlock = React.memo(({ rawContent, className, isVisible }) => {
  const codeRef = useRef(null);
  const { saveSelection } = useTextSelection(codeRef);

  const { language, code } = useMemo(() => {
    const match = /language-(\w+)/.exec(className || '');
    return {
      language: match ? match[1] : 'text',
      code: String(rawContent).replace(/\n$/, ''),
    };
  }, [rawContent, className]);

  const handleCopyToClipboard = useCallback((event) => {
    event.preventDefault();
    if (code) {
      navigator.clipboard.writeText(code).then(
        () => console.log("Copied to clipboard"),
        (error) => console.error("Failed to copy: ", error)
      );
    }
  }, [code]);

  const handleMouseUp = useCallback(() => {
    saveSelection();
  }, [saveSelection]);

  const highlightedCode = useMemo(() => (
    <MemoizedHighlight theme={themes.vsDark} code={code} language={language} />
  ), [code, language]);

  return (
    <CodeBlockWrapper>
      <CodeHeader>
        <span>{language}</span>
        <CopyButton onClick={handleCopyToClipboard} className="copy-button">
          <CopyButtonIcon />
        </CopyButton>
      </CodeHeader>
      <div ref={codeRef} onMouseUp={handleMouseUp}>
        {isVisible ? highlightedCode : <Pre>{code}</Pre>}
      </div>
    </CodeBlockWrapper>
  );
});

MemoizedToken.displayName = 'MemoizedToken';
MemoizedLineContent.displayName = 'MemoizedLineContent';
MemoizedLine.displayName = 'MemoizedLine';
MemoizedHighlight.displayName = 'MemoizedHighlight';
CodeBlock.displayName = 'CodeBlock';

export default CodeBlock;