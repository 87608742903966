import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  console.log('AuthProvider rendering');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dummyMode, setDummyMode] = useState(true); // Set to true for dummy mode

  useEffect(() => {
    // Check if a token exists in local storage
    const token = localStorage.getItem('token');
    if (token) {
      // Verify the token and set the authentication state
      verifyToken(token);
    } else {
      setLoading(false);
    }
  }, []);

  const login = async (email, password) => {
    if (dummyMode) {
      // Dummy mode: set authentication state without making an API call
      setIsAuthenticated(true);
      setUser({ name: 'John Doe', email });
      localStorage.setItem('token', 'dummy-token');
    } else {
      try {
        // Make an API call to login and obtain the token
        const response = await axios.post('/api/login', { username: email, password });
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
        setIsAuthenticated(true);
        // Fetch user details using the token
        const userResponse = await axios.get('/api/users/me', {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        setUser(userResponse.data);
      } catch (error) {
        console.error('Login error:', error);
        // Handle login error, e.g., display an error message
      }
    }
  };

  const register = async (userData) => {
    if (dummyMode) {
      // Dummy mode: set authentication state without making an API call
      setIsAuthenticated(true);
      setUser({ name: userData.name, email: userData.email });
      localStorage.setItem('token', 'dummy-token');
    } else {
      try {
        // Make an API call to register the user
        await axios.post('/api/register', userData);
        // Automatically log in the user after successful registration
        await login(userData.email, userData.password);
      } catch (error) {
        console.error('Registration error:', error);
        // Handle registration error, e.g., display an error message
      }
    }
  };

  const logout = () => {
    // Remove the token from local storage and reset the authentication state
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
  };

  const verifyToken = async (token) => {
    if (dummyMode) {
      // Dummy mode: set authentication state without making an API call
      setIsAuthenticated(true);
      setUser({ name: 'John Doe', email: 'john@example.com' });
    } else {
      try {
        // Make an API call to verify the token and fetch user details
        const response = await axios.get('/api/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsAuthenticated(true);
        setUser(response.data);
      } catch (error) {
        console.error('Token verification error:', error);
        // Handle token verification error, e.g., display an error message
        logout();
      }
    }
    setLoading(false);
  };

  const forgotPassword = async (email) => {
    if (dummyMode) {
      // Dummy mode: simulate password reset process
      console.log('Password reset email sent to:', email);
    } else {
      try {
        // Make an API call to initiate the password reset process
        await axios.post('/api/forgot-password', { email });
        // Handle success, e.g., display a success message to the user
      } catch (error) {
        console.error('Forgot password error:', error);
        // Handle error, e.g., display an error message to the user
      }
    }
  };

  const resetPassword = async (token, newPassword) => {
    if (dummyMode) {
      // Dummy mode: simulate password reset process
      console.log('Password reset successful');
    } else {
      try {
        // Make an API call to reset the password
        await axios.post('/api/reset-password', { token, newPassword });
        // Handle success, e.g., display a success message to the user
      } catch (error) {
        console.error('Password reset error:', error);
        // Handle error, e.g., display an error message to the user
      }
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, register, logout, forgotPassword, resetPassword, loading }}>
      {children}
    </AuthContext.Provider>
  );
};