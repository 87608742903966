import React, { createContext, useState } from 'react';

const FeatureFlagsContext = createContext();

const FeatureFlagsProvider = ({ children }) => {
  console.log('FeatureFlagsProvider rendering');
  const [featureFlags, setFeatureFlags] = useState({
    // Sidebar
    technicalScoreRankAdjuster: false,
    datePublishedRankAdjuster: true,
    clusteredRankAdjuster: true,
    familyFriendlyRankAdjuster: false,
    llmJudgementRankAdjuster: false,
    customSubqueryRankAdjuster: false,
    withinClusterMMRRankAdjuster: true,
    probeRankAdjuster: false,
    receipt: false,
    customArgsComponent: false,

    clusteringSwitch: false,
    highlightWords: false,
    chat: true,

    viewMetaButton: true,
    showFullURL: false,
    showTotalBoostImpact: false,

    middleActionBar: false,
  });

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags, setFeatureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsContext, FeatureFlagsProvider };