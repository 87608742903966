export function generateDebuggingPrompt(prompt, diff, changed_files, referencing_files, logs, includeTimestamp) {
    const formattedLogs = logs
        .map((log) => {
            const { level, timestamp, message, component, logCount } = log;
            const timestampPrefix = includeTimestamp ? `[${timestamp}] ` : '';
            return `[Log #${logCount}] ${timestampPrefix}[${level.toUpperCase()}] [${component}] ${message}`;
        })
        .join('\n');

    return `
      ${prompt ? `Prompt:\n${prompt}\n\n` : ''}
      ${diff ? `Diff:\n<diff>\n${diff}\n</diff>\n\n` : ''}
      ${changed_files.length > 0 ? `Changed Files:\n<changedFiles>\n${changed_files.join('\n')}\n</changedFiles>\n\n` : ''}
      ${referencing_files.length > 0 ? `Referencing Files:\n<referencingFiles>\n${referencing_files.join('\n')}\n</referencingFiles>\n\n` : ''}
      ${logs.length > 0 ? `Logs (${logs.length} messages):\n<logs>\n${formattedLogs}\n</logs>` : ''}
    `;
}

// Helper function to format individual log entries
function formatLog(log) {
    // Implement your log formatting logic here
    // Example:
    // return `[${log.timestamp}] ${log.level}: ${log.message}`;
    return log;
}