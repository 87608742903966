import React, { useCallback, useContext, useMemo, useRef } from 'react';

const NodeRegistryContext = React.createContext();

export const NodeRegistryProvider = ({ children }) => {
    console.log('NodeRegistryProvider rerendering');
    const nodeRefsRef = useRef(new Map());

    const registerNode = useCallback((nodeId, ref) => {
        nodeRefsRef.current.set(nodeId, ref);
    }, []);

    const unregisterNode = useCallback((nodeId) => {
        nodeRefsRef.current.delete(nodeId);
    }, []);

    const getNodeRef = useCallback((nodeId) => {
        return nodeRefsRef.current.get(nodeId);
    }, []);

    const value = useMemo(() => ({
        registerNode,
        unregisterNode,
        getNodeRef
    }), [registerNode, unregisterNode, getNodeRef]);

    return (
        <NodeRegistryContext.Provider value={value}>
            {children}
        </NodeRegistryContext.Provider>
    );
};

export const useNodeRegistry = () => useContext(NodeRegistryContext);

NodeRegistryContext.displayName = 'NodeRegistryContext';