import React, { createContext, useContext, useState, useEffect, useRef, useTransition, useCallback } from 'react';

import { debounce, stubTrue } from 'lodash';

import { FeatureFlagsContext } from './FeatureFlagsContext';

import ResultsFetcherWorker from 'worker-loader!../workers/resultsFetcher.worker.js';
import { rerank } from '../lib/reranking';
import { logEvent } from '../lib/logger';

export const SearchResultsContext = createContext();

export const SearchResultsProvider = ({ children }) => {
  // Only apply boosts based on attrs in here
  // If nullBetterThanLow == false and the boost is applied, a negative boost will be applied if the corresponding presence attr is 0
  // TODO: maybe specify a null value? could see llm_judgement being null at random
  const rerankingAttrConfigs = {
    "pointwise": {
      "literal_score": {},
      "technical_score": {},
      "is_family_friendly": {},
      "is_cluster_member": {},
      "date_published_score": {
        "presenceAttr": "has_date_published",
        "nullBetterThanLow": false,
      },
      "passes_llm_judgement": {
        "presenceAttr": "has_llm_judgement",
        "nullBetterThanLow": true,
      },
    },
    "listwise_within_cluster": {
      "within_cluster_mmr_score": {},

    },

  }

  // TODO: we should have a single unified config for the boosts that gets read in here and boostPanel
  const defaultRankAdjusterStates = {
    pointwise: { ...Object.keys(rerankingAttrConfigs.pointwise).reduce((acc, key) => ({ ...acc, [key]: 0 }), {}) },
    listwise_within_cluster: { ...Object.keys(rerankingAttrConfigs.listwise_within_cluster).reduce((acc, key) => ({ ...acc, [key]: 0 }), {}) },
  };
  defaultRankAdjusterStates.pointwise.is_cluster_member = 2;
  defaultRankAdjusterStates.listwise_within_cluster.within_cluster_mmr_score = 10;

  const { featureFlags } = useContext(FeatureFlagsContext);

  const [urls, setUrls] = useState([]);
  const [receipt, setReceipt] = useState("");
  const [meta, setMeta] = useState({});
  const [results, setResults] = useState([]); // Search results are clustered by topic, so the result list is a list of clusters, each with child results
  const [rankAdjustmentWeights, setRankAdjustmentWeights] = useState(defaultRankAdjusterStates);
  const [isLoading, setIsLoading] = useState(false); // Tracks if search results are loading after query
  const [isRankAdjusterLoading, setIsRankAdjusterLoading] = useState(
    Object.keys(rerankingAttrConfigs).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  ); // Tracks if dynamic rank adjusters are loading
  const [rankAdjusterImpact, setRankAdjusterImpact] = useState(0); // Tracks the actual impact of each rank adjuster with spearman rank correlation
  const clusterRefs = useRef([]);
  const [rerankLogged, setRerankLogged] = useState(false);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    console.log('results in SearchResultsContext: ', results.length);
  }, [results.length, meta]);

  // todo get rid of these- we need to traverse the tree and set the state of each cluster
  const expandAllClusters = () => {
    clusterRefs.current.forEach(clusterRef => {
      clusterRef.expandCluster();
    });
  };

  const collapseAllClusters = () => {
    clusterRefs.current.forEach(clusterRef => {
      clusterRef.collapseCluster();
    });
  };

  const updateRankAdjustmentWeights = (key, value) => {
    setRankAdjustmentWeights(prevState => ({ ...prevState, [key]: value }));
  };

  const updateIsRankAdjusterLoading = (key, value) => {
    setIsRankAdjusterLoading(prevState => ({ ...prevState, [key]: value }));
  };


  const fetchResultsAndRerank = useCallback(async (query, customArgs) => {
    // TODO what's the deal with this function? Why are we establishing this on mount? 
    console.log('fetchResultsAndRerank', query);
    setIsLoading(true);
    setRerankLogged(false); // Reset rerankLogged when a new query is made
    // TODO add message to chat, or add it to thread, whatever, then setShouldAddNewQueriesToChat to false. Later we will control this with a toggle switch

    // Initialize the workers
    const fetchWorker = new ResultsFetcherWorker();

    // Send the fetch request to the fetch worker
    fetchWorker.postMessage({
      type: 'fetchResults',
      payload: { query, customArgs }
    });

    // Handle messages from the fetch worker
    fetchWorker.onmessage = (event) => {
      const { type, data } = event.data;
      switch (type) {
        case 'clustered_results':
          setMeta(data.meta);
          setResults(rerank(data.clustered_results, data.meta, rankAdjustmentWeights, rerankingAttrConfigs, featureFlags));
          setIsLoading(false);
          setReceipt(data.meta.receipt);
          break;
        case 'cluster_summary_token':
          // Update specific cluster description based on token
          startTransition(() => {
            setResults((prevResults) => {
              const updatedResults = prevResults.map((cluster) => {
                if (cluster.cluster_num === data.cluster_num) {
                  return {
                    ...cluster,
                    description: (cluster.description || '') + data.token,
                  };
                }
                return cluster;
              });
              return updatedResults;
            });
          });
          setIsLoading(false);
          break;
        case 'meta':
          startTransition(() => {
            setMeta(data);
            setUrls(data.urls);
          });
          setReceipt(data.receipt);
          setIsLoading(false);
          break;
        case 'scored_website_chunks':
          // Handle the scored chunks data
          console.log('Received scored chunks:', data);
          // TODO: Integrate the scored chunks into the search results
          break;
        case 'error':
          console.error(data.message);
          setIsLoading(false);
          break;
        case 'closed':
          console.log('Fetch worker has closed the connection');
          break;
        default:
          console.error(`Received unknown data type from WebSocket: ${type}, ${JSON.stringify(data)}`);
      }
    };

    // Cleanup workers after they are done
    fetchWorker.onclose = () => {
      console.log('Fetch worker has finished and is closing.');
      fetchWorker.terminate();
    };
  }, []);


  const debouncedRerank = debounce(() => {
    // TODO: need to make sure this doesnt override cluster tokens in progress, need to merge changes together
    setResults(rerank(results, meta, rankAdjustmentWeights, rerankingAttrConfigs, featureFlags));

    if (!rerankLogged) {
      logEvent(
        'INFO',
        'user_rerank',
        'debouncedRerank triggered',
        {}
      );
      setRerankLogged(true); // Set rerankLogged to true after logging
    }

  }, 150); // ms debounce time

  useEffect(() => {
    if (results.length > 0) {
      // Listen for rank adjuster changes
      const DISABLE_SCROLL = true;
      if (DISABLE_SCROLL || window.scrollY > 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Wait for 300ms after scrolling to the top before running debouncedUpdate
        setTimeout(() => {
          debouncedRerank();
        }, 300);
      } else {
        // If already at the top, just run debouncedUpdate without delay
        debouncedRerank();
      }
      // Cleanup function to cancel any outstanding debounced calls
      return () => {
        debouncedRerank.cancel();
      };
    }
  }, [rankAdjustmentWeights]);


  return (
    <SearchResultsContext.Provider value={{
      rerankingAttrConfigs,
      urls,
      setUrls,
      meta,
      setMeta,
      receipt,
      setReceipt,
      results,
      setResults,
      rankAdjustmentWeights,
      setRankAdjustmentWeights,
      updateRankAdjustmentWeights,
      rankAdjusterImpact,
      setRankAdjusterImpact,
      isLoading,
      setIsLoading,
      isRankAdjusterLoading,
      updateIsRankAdjusterLoading,
      fetchResultsAndRerank,
      clusterRefs,
      expandAllClusters,
      collapseAllClusters,
    }}>
      {children}
    </SearchResultsContext.Provider>
  );
};

// This context includes all the state and functions related to search results.
// It's important to note that the rerank function and other functions that manipulate the state should be defined inside this context provider.
