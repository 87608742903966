import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
`;

const ArrowIcon = styled(({ isPositiveBoost, isNegativeBoost, ...props }) => (
    isPositiveBoost ? <ArrowUpwardIcon {...props} /> : isNegativeBoost ? <ArrowDownwardIcon {...props} /> : null
))`
  font-size: 0.8rem;
  color: ${({ isPositiveBoost, isNegativeBoost }) =>
        isPositiveBoost ? 'green' : isNegativeBoost ? 'red' : 'transparent'};
`;

const BoostText = styled(Typography)`
  font-size: 0.7rem;
  font-weight: bold;
  transition: color 0.3s;
  color: ${({ isPositiveBoost, isNegativeBoost }) =>
        isPositiveBoost ? 'green' : isNegativeBoost ? 'red' : 'transparent'};
`;

const BoostIndicatorTag = ({ result, speed = 0.3 }) => {
    const [displayBoost, setDisplayBoost] = useState(0);

    const totalAdjustment = result['ranking']['total_rank_adjustment'];

    useEffect(() => {
        let animationFrameId;
        const animate = () => {
            setDisplayBoost((prevBoost) => {
                const diff = totalAdjustment - prevBoost;
                if (Math.abs(diff) < 0.1) {
                    return totalAdjustment;
                }
                return prevBoost + diff * speed;
            });
            animationFrameId = requestAnimationFrame(animate);
        };
        animationFrameId = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animationFrameId);
    }, [totalAdjustment, speed]);

    const isPositiveBoost = totalAdjustment > 0;
    const isNegativeBoost = totalAdjustment < 0;

    return (
        <Container sx={{ visibility: totalAdjustment === 0 ? 'hidden' : 'visible' }}>
            <Box display="flex" alignItems="center">
                <ArrowIcon isPositiveBoost={isPositiveBoost} isNegativeBoost={isNegativeBoost} />
                <BoostText isPositiveBoost={isPositiveBoost} isNegativeBoost={isNegativeBoost}>
                    {`${Math.abs(displayBoost).toFixed(0)}`}
                </BoostText>
            </Box>
        </Container>
    );
};

export default BoostIndicatorTag;