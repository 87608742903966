import { useContext, useCallback } from 'react';
import { debounce } from 'lodash';

import { SearchResultsContext } from '../contexts/SearchResultsContext';

export const useSearchResults = () => {
  const {
    flatResults,
    clusteredResults,
    urls,
    receipt,
    results,
    isLoading,
    scoreStats,
  } = useContext(SearchResultsContext);

  // Return only the necessary parts of the context
  return {
    flatResults,
    clusteredResults,
    urls,
    receipt,
    results,
    isLoading,
    scoreStats,
  };
};