import React, { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import styled from '@emotion/styled';

const LandingChatBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #1e1e1e;
  color: #fff;
  width: 100%;
  min-height: 3rem;

  &:focus-within {
    border: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
    border-radius: 6px;  
  }
`;

const LandingChatBar = ({ onSubmit }) => {
    const [prompt, setPrompt] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(prompt);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <LandingChatBarContainer>
            <TextField
                multiline
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Enter a prompt..."
                variant="outlined"
                fullWidth
                maxRows={30}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        color: '#fff',
                        backgroundColor: '#1e1e1e',
                        borderRadius: '4px',
                        padding: '10px',
                        '& fieldset': {
                            border: 'none',
                        },
                    },
                    '& .MuiInputBase-input': {
                        overflowY: 'auto',
                    },
                }}
            />
            <IconButton onClick={handleSubmit} sx={{ color: '#fff' }}>
                <SendIcon />
            </IconButton>
        </LandingChatBarContainer>
    );
};

export default LandingChatBar;