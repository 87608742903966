import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { TextField, Box, Button, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TopActionBar from './TopActionBar';
import { useLeafFunctions } from '../../../contexts/ChatContext';

const NodeWrapper = styled(Box)`
   max-width: 100%;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  border-radius: 6px;
  border: 1px solid #555;
  position: relative;
  transition: all 0.1s;
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  border-bottom: 1px solid #555;
`;

const Title = styled.div`
  font-size: 0.9em;
  // font-weight: bold;
  font-weight: 500;
  color: #d6d6dc;
`;

const EditInput = styled(TextField)`
  background-color: #1e1e1e;
  color: #d6d6dc;

  .MuiOutlinedInput-root {
    fieldset {
      border: none; // Remove the border
    }
    &:hover fieldset {
      border: none; // Remove hover border
    }
    &.Mui-focused fieldset {
      border: none; // Remove focus border
    }
  }

  .MuiInputBase-input {
    color: #d6d6dc;
    padding: 0.3rem; // TODO: Is this doing anything?
  }

  .MuiOutlinedInput-root {
    padding: 2px;
  }
`;

const ToggleBar = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e1e1e; // Matches MessageWrapper background
  cursor: pointer;
  border-radius: 0 0 4px 4px; // Rounded bottom corners
  
`;

const DocumentNode = React.memo(({ node }) => {
  // console.log('DocumentNode will re-render');
  const { updateNode, deleteNode } = useLeafFunctions();
  const [editedMessage, setEditedMessage] = useState(node.content);
  const [isMinimized, setIsMinimized] = useState(true);

  const displayTitle = useMemo(() =>
    node.title + (node.language ? ` (${node.language})` : ''),
    [node.title, node.language]);

  useEffect(() => {
    setEditedMessage(node.content);
  }, [node.content]);

  const handleMessageChange = useCallback((event) => {
    const newContent = event.target.value;
    setEditedMessage(newContent);
    updateNode(node.node_id, { content: newContent });
  }, [updateNode, node.node_id]);

  const handleDeleteMessage = useCallback(() => {
    deleteNode(node.node_id);
  }, [deleteNode, node.node_id]);

  const getJsonData = useCallback(() => {
    return JSON.stringify(node, null, 2);
  }, [node]);

  const toggleMinimize = useCallback(() => {
    setIsMinimized(!isMinimized);
  }, [isMinimized]);

  const memoizedTopActionBar = useMemo(() => (
    <TopActionBar
      onDelete={handleDeleteMessage}
      getJsonData={getJsonData}
    />
  ), [handleDeleteMessage, getJsonData]);

  return (
    <NodeWrapper>
      <MessageWrapper>
        <MessageHeader>
          <Title>{displayTitle}</Title>
          {memoizedTopActionBar}
        </MessageHeader>
        <EditInput
          multiline
          fullWidth
          autoFocus
          variant="outlined"
          value={editedMessage}
          onChange={handleMessageChange}
          minRows={3}
          maxRows={isMinimized ? 5 : 40}
          InputProps={{
            style: {
              maxHeight: isMinimized ? '150px' : '1000px',
              overflow: 'auto',
            },
          }}
          inputProps={{
            scrollTop: 0
          }}
        />
        <ToggleBar onClick={toggleMinimize}>
          {isMinimized ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </ToggleBar>
      </MessageWrapper>
    </NodeWrapper>
  );
}, (prevProps, nextProps) => {
  return prevProps.node.width === nextProps.node.width &&
    prevProps.node.content === nextProps.node.content &&
    prevProps.node.title === nextProps.node.title &&
    prevProps.node.language === nextProps.node.language;
});

DocumentNode.displayName = 'DocumentNode';

export default DocumentNode;