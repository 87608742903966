import React, { useState } from 'react';
import { Box, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const QueryBuilder = ({ fields, onQueryChange }) => {
    const [query, setQuery] = useState([{ field: 'domain', operator: 'eq', value: '' }]);

    const handleAddCriteria = () => {
        setQuery([...query, { field: fields[0], operator: 'eq', value: '' }]);
    };

    const handleFieldChange = (index, field) => {
        const newQuery = [...query];
        newQuery[index].field = field;
        setQuery(newQuery);
    };

    const handleOperatorChange = (index, operator) => {
        const newQuery = [...query];
        newQuery[index].operator = operator;
        setQuery(newQuery);
    };

    const handleValueChange = (index, value) => {
        const newQuery = [...query];
        newQuery[index].value = value;
        setQuery(newQuery);
        onQueryChange(newQuery);
    };

    const handleAddChip = (index) => {
        const newQuery = [...query];
        const value = newQuery[index].value.trim();
        if (value) {
            const chips = newQuery[index].chips || [];
            newQuery[index].chips = [...chips, value];
            newQuery[index].value = '';
            setQuery(newQuery);
            onQueryChange(newQuery);
        }
    };

    const handleRemoveChip = (index, chip) => {
        const newQuery = [...query];
        newQuery[index].chips = newQuery[index].chips.filter((c) => c !== chip);
        setQuery(newQuery);
        onQueryChange(newQuery);
    };

    const handleRemoveCriteria = (index) => {
        const newQuery = [...query];
        newQuery.splice(index, 1);
        setQuery(newQuery);
        onQueryChange(newQuery);
    };

    return (
        <Box sx={{
            '& .MuiFormControl-root': { m: 0.5, minWidth: '100px' },
            '& .MuiTextField-root': { m: 0.5, width: '100%' },
            '& .MuiInputBase-root': { fontSize: '0.8rem', padding: '4px 8px', height: '32px', backgroundColor: 'background.paper', color: 'text.primary', borderRadius: 1 },
            '& .MuiChip-root': { fontSize: '0.7rem', height: '20px', color: 'text.primary' },
            '& .MuiButton-root': { fontSize: '0.8rem' },
            '& .MuiInputLabel-root': { color: 'text.secondary' },
            '& .MuiMenuItem-root': { color: 'text.primary' }
        }}>
            {query.map((criteria, index) => (
                <Box key={index} mb={1} display="flex" alignItems="center" p={1} bgcolor={alpha('#fff', 0.08)} borderRadius={1}>
                    <Box flexGrow={1}>
                        <Box display="flex" alignItems="center">
                            <FormControl size="small" variant="outlined" sx={{ mr: 1, flexGrow: 1 }}>
                                <InputLabel>Field</InputLabel>
                                <Select
                                    value={criteria.field}
                                    onChange={(e) => handleFieldChange(index, e.target.value)}
                                    label="Field"
                                >
                                    {fields.map((field) => (
                                        <MenuItem key={field} value={field}>
                                            {field}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl size="small" variant="outlined" sx={{ flexGrow: 1 }}>
                                <InputLabel>Operator</InputLabel>
                                <Select
                                    value={criteria.operator}
                                    onChange={(e) => handleOperatorChange(index, e.target.value)}
                                    label="Operator"
                                >
                                    <MenuItem value="eq">Equals</MenuItem>
                                    <MenuItem value="ne">Not Equals</MenuItem>
                                    <MenuItem value="in">In</MenuItem>
                                    <MenuItem value="nin">Not In</MenuItem>
                                    <MenuItem value="sw">Starts With</MenuItem>
                                    <MenuItem value="ew">Ends With</MenuItem>
                                    <MenuItem value="con">Contains</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={criteria.value}
                            onChange={(e) => handleValueChange(index, e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ',') {
                                    e.preventDefault();
                                    handleAddChip(index);
                                }
                            }}
                            InputProps={{
                                startAdornment: (criteria.chips || []).map((chip, i) => (
                                    <Chip
                                        key={i}
                                        label={chip}
                                        onDelete={() => handleRemoveChip(index, chip)}
                                        size="small"
                                        sx={{ m: 0.25 }}
                                    />
                                )),
                            }}
                        />
                    </Box>
                    <IconButton size="small" onClick={() => handleRemoveCriteria(index)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            ))}
            <Button
                startIcon={<AddIcon />}
                onClick={handleAddCriteria}
                size="small"
                sx={{ mt: 1, borderRadius: 2, textTransform: 'none', fontWeight: 'bold' }}
                variant="contained"
                color="primary"
            >
                Add Criteria
            </Button>
        </Box>
    );
};

export default QueryBuilder;

/*

This component is used to execute SQL queries over the search results (which is JSON data). It uses the alaSQL library to execute the queries.

It uses react and material UI to accept input for the SQL query, with an execute button to execute the query and update the results.

It uses searchResultsContext to execute the query, which in turn imports and uses the local_queries.js service.

It has a toggle switch to toggle results between fullFlatResults and filteredFlatResults or fullClusteredResults and filteredClusteredResults.
The toggle switch is in the top right corner of the component. It is used to toggle between the full results and the filtered results.

Below toggle switch is a "execute filter" button. When clicked, it executes the SQL query and updates the results in the searchResultsContext.
The switch and button are stacked on top of each other in a column to the right of the input field.


Follow the react-querybuilder example here:
```
https://react-querybuilder.js.org/docs/intro
```

Follow the alaSQL example here:

```
<script src="https://cdnjs.cloudflare.com/ajax/libs/alasql/1.0.1/alasql.min.js"></script>
<script>
// Example JSON data
const jsonData = [
  { id: 1, name: 'Item 1', value: 100 },
  { id: 2, name: 'Item 2', value: 200 },
  // Add more items as needed
];

// Executing an SQL query over JSON data
const result = alasql('SELECT * FROM ? WHERE value > 150', [jsonData]);

console.log(result);
</script>
```

TODO:
- figure out how to store the new results in the searchResultsContext while keeping the original results to future queries
    (maybe double up: add fullFlatResults, fullClusteredResults to the searchResultsContext and update the fullFlatResults and fullClusteredResults 
        with the new results)
- write local_queries.js service to execute the queries
- write this component to use use the context and/or service to execute the queries




*/