import { generateNodeId } from './idGenerator';


// TODO: eventually these should accept attrs, maybe with required attrs

/*
TODO:
- these should not accept or pass parentId, can just call with no args

*/

export const createDirectoryNode = (parentId, directoryName, fullPath) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.collection',
        directoryName,
        fullPath,
        title: directoryName, // We can use directoryName as the title for now
        children: new Map(),
        isCollapsed: false,
        isSelected: false,
        width: 900,
        timestamp: new Date().toISOString(),
        direction: 'row',
    };
};

export const createFileNode = (parentId, fileName, content, fullPath) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.message.document',
        fileName,
        title: fileName,
        content,
        fullPath,
        role: 'user',
        children: new Map(), // Empty children map, as it's a leaf node
        isCollapsed: false,
        isSelected: false,
        width: 850,
        timestamp: new Date().toISOString(),
        direction: 'row',
    };
};


export const createCollection = (parentId, title = 'New Collection') => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.collection',
        children: new Map(),
        role: 'user',
        title,
        content: '',
        width: 900,
        isCollapsed: false,
        isSelected: false,
        timestamp: new Date().toISOString(),
        direction: 'row',
    };
};

export const createFilteredCollectionNode = (parentId, directoryName) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.filtered_collection',
        directoryName,
        children: new Map(),
        unfilteredChildren: new Map(),
        isCollapsed: false,
        isSelected: false,
        width: 900,
        timestamp: new Date().toISOString(),
        direction: 'row',
    };
};

export const createDocumentNode = (parentId, fileName, content) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.message.document',
        fileName,
        content,
        role: 'user',
        width: 850,
        timestamp: new Date().toISOString(),
        isSelected: false,
        direction: 'row',
    };
};


export const createBlankDocumentNode = (parentId) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.message.document',
        content: '',
        role: 'user',
        title: 'New Document',
        width: 850,
        timestamp: new Date().toISOString(),
        isSelected: false,
        direction: 'row',
    };
};

export const createUserMessage = (parentId, content) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        content,
        role: 'user',
        timestamp: new Date().toISOString(),
        type: 'content.chat.message.user',
        width: 850,
        isSelected: false,
        direction: 'row',
    };
};

export const createAssistantMessage = (parentId, content = '') => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        content,
        role: 'assistant',
        timestamp: new Date().toISOString(),
        type: 'content.chat.message.assistant',
        isLoading: true,
        width: 850,
        isSelected: false,
        direction: 'row',
    };
};

export const createThread = (parentId, width = 950, isMinimized = false) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        children: new Map(),
        type: 'content.chat.thread',
        width,
        isMinimized,
        timestamp: new Date().toISOString(),
        isSelected: false,
        direction: 'row',
    };
};

export const createThreadContainerNode = (parentId, width = 1000, isMinimized = false) => {
    const nodeId = generateNodeId(parentId);
    return {
        node_id: nodeId,
        id: nodeId,
        parent: parentId,
        type: 'content.chat.thread_container',
        children: new Map(),
        width,
        isMinimized,
        timestamp: new Date().toISOString(),
        isSelected: false,
        direction: 'column',
    };
};

export const initRootThreadContainer = () => {
    const rootContainer = createThreadContainerNode('', 1200);
    const initialThread = createThread(rootContainer.node_id);
    rootContainer.children = new Map([[initialThread.node_id, initialThread]]);
    return rootContainer;
};