import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box, FormControlLabel, Checkbox, Switch, Tabs, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { generateDebuggingPrompt } from '../../lib/debugging';

import { DebuggingContext } from '../../contexts/DebuggingContext';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 90%;
    height: 90%;
    max-width: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)};
  height: calc(100% - 64px); // Adjust for DialogTitle height
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const RightActionBar = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
`;

const CheckboxGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

function DebuggingPromptModal({ open, onClose }) {
  const { logs, debuggingData, layoutProfiles, forceLayoutCapture } = useContext(DebuggingContext);
  const { diff, changed_files, referencing_files, prompt } = debuggingData;
  const [includeTimestamp, setIncludeTimestamp] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [selectedLogLevels, setSelectedLogLevels] = useState({
    user: true,
    info: true,
    warning: true,
    error: true,
  });

  const [selectedSections, setSelectedSections] = useState({
    prompt: false,
    diff: false,
    changed_files: true,
    referencing_files: true,
    logs: true,
    layoutProfiles: true,
  });

  const [selectedComponents, setSelectedComponents] = useState(
    Object.keys(layoutProfiles).reduce((acc, key) => ({ ...acc, [key]: true }), {})
  );

  useEffect(() => {
    if (open) {
      forceLayoutCapture();
    }
  }, [open, forceLayoutCapture]);

  const handleLogLevelChange = (level) => {
    setSelectedLogLevels((prevLevels) => ({
      ...prevLevels,
      [level]: !prevLevels[level],
    }));
  };

  const handleSectionChange = (section) => {
    setSelectedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleComponentChange = (component) => {
    setSelectedComponents((prevComponents) => ({
      ...prevComponents,
      [component]: !prevComponents[component],
    }));
  };

  const promptText = useMemo(() => {
    const filteredLogs = logs.filter((log) => {
      if (log.level === 'log' || log.level === 'info' || log.level === 'debug' || log.level === 'table' || log.level === 'trace' || log.level === 'group' || log.level === 'groupCollapsed') {
        return selectedLogLevels.user;
      } else if (log.level === 'info' || log.level === 'debug' || log.level === 'verbose') {
        return selectedLogLevels.info;
      } else if (log.level === 'warn') {
        return selectedLogLevels.warning;
      } else if (log.level === 'error' || log.level === 'assert') {
        return selectedLogLevels.error;
      }
      return false;
    });

    const filteredLayoutProfiles = Object.entries(layoutProfiles)
      .filter(([key]) => selectedComponents[key])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    return generateDebuggingPrompt(
      selectedSections.prompt ? prompt : '',
      selectedSections.diff ? diff : '',
      selectedSections.changed_files ? changed_files : [],
      selectedSections.referencing_files ? referencing_files : [],
      selectedSections.logs ? filteredLogs : [],
      includeTimestamp,
      selectedSections.layoutProfiles ? filteredLayoutProfiles : {}
    );
  }, [diff, changed_files, referencing_files, prompt, logs, layoutProfiles, selectedLogLevels, selectedSections, selectedComponents, includeTimestamp]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(promptText);
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>Debugging Prompt</DialogTitle>
      <StyledDialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            <Tab label="Debugging Prompt" />
            <Tab label="Layout Profiles" />
          </Tabs>
          {activeTab === 0 && (
            <StyledTextField
              multiline
              rows={40}
              variant="outlined"
              value={promptText}
              InputProps={{ readOnly: true }}
            />
          )}
          {activeTab === 1 && (
            <StyledTextField
              multiline
              rows={40}
              variant="outlined"
              value={JSON.stringify(layoutProfiles, null, 2)}
              InputProps={{ readOnly: true }}
            />
          )}
        </Box>

        <RightActionBar>
          <Button variant="contained" color="primary" onClick={handleCopyToClipboard}>
            Copy to Clipboard
          </Button>

          <CheckboxGroup>
            <FormControlLabel
              control={<Checkbox checked={selectedSections.prompt} onChange={() => handleSectionChange('prompt')} />}
              label="Prompt"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedSections.diff} onChange={() => handleSectionChange('diff')} />}
              label="Diff"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedSections.changed_files} onChange={() => handleSectionChange('changed_files')} />}
              label="Changed Files"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedSections.referencing_files} onChange={() => handleSectionChange('referencing_files')} />}
              label="Referencing Files"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedSections.logs} onChange={() => handleSectionChange('logs')} />}
              label="Logs"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedSections.layoutProfiles} onChange={() => handleSectionChange('layoutProfiles')} />}
              label="Layout Profiles"
            />
          </CheckboxGroup>

          <CheckboxGroup>
            <FormControlLabel
              control={<Checkbox checked={selectedLogLevels.user} onChange={() => handleLogLevelChange('user')} />}
              label="User"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedLogLevels.info} onChange={() => handleLogLevelChange('info')} />}
              label="Info"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedLogLevels.warning} onChange={() => handleLogLevelChange('warning')} />}
              label="Warning"
            />
            <FormControlLabel
              control={<Checkbox checked={selectedLogLevels.error} onChange={() => handleLogLevelChange('error')} />}
              label="Error"
            />
          </CheckboxGroup>

          <CheckboxGroup>
            {Object.keys(layoutProfiles).map((component) => (
              <FormControlLabel
                key={component}
                control={<Checkbox checked={selectedComponents[component]} onChange={() => handleComponentChange(component)} />}
                label={component}
              />
            ))}
          </CheckboxGroup>

          <FormControlLabel
            control={<Switch checked={includeTimestamp} onChange={() => setIncludeTimestamp(!includeTimestamp)} />}
            label="Include Timestamp"
          />
        </RightActionBar>
      </StyledDialogContent>
    </StyledDialog>
  );
}

export default DebuggingPromptModal;