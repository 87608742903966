import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import QueryBuilder from './QueryBuilder';

const Root = styled(Box)({
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    padding: '8px',
    minHeight: '200px',
    overflow: 'auto',
});

const FlexBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    paddingRight: '5px',
});

const TextColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    minHeight: '100%',
});

const Title = styled(Typography)({
    fontSize: '0.9rem',
    paddingLeft: '8px',
    alignItems: 'center',
});

const QueryBuilderWrapper = styled(Box)({
    marginTop: '16px',
    flexGrow: 1,
    '& .e-query-builder': {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '8px',
    },
    '& .e-rule-container': {
        padding: '8px',
    },
    '& .e-rule-field, & .e-rule-operator, & .e-rule-value': {
        margin: '8px',
    },
    '& .e-rule-delete': {
        marginLeft: '8px',
    },
});

const SubqueryRankAdjuster = ({ value, onChange }) => {
    const [sliderValue, setSliderValue] = useState(value);

    const handleSliderChange = (newValue) => {
        setSliderValue(newValue);
        onChange(newValue);
    };

    return (
        <Root>
            <FlexBox>
                <TextColumn>
                    <Title variant="h6">
                        Custom Subquery
                    </Title>
                    <QueryBuilderWrapper>
                        <QueryBuilder
                            fields={['datePublished', 'domain', 'title', 'snippet']}
                            onQueryChange={(query) => console.log(query)}
                        />
                    </QueryBuilderWrapper>
                </TextColumn>
                {/* <VerticalSlider value={sliderValue} onChange={handleSliderChange} /> */}
            </FlexBox>
        </Root>
    );
};

export default SubqueryRankAdjuster;