import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { getParentId } from '../../../../../lib/nodeUtils';
import { generateNodeId } from '../../../../../graph/utils/idGenerator';

import CursorChatBar from './CursorChatBar';
import FollowupButton from './FollowupButton';
import { useSlotFunctions } from '../../../../../contexts/ChatContext';

const FollowupTreeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;`;

const FollowupTreeWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: 80%;
  margin-right: 24px;
`;

const FollowupTree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;
  position: relative;
`;

const ChatBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  min-width: 20%;
  max-width: 500px;
`;

// Memoize the entire component
export const FollowupsTreeNode = React.memo(({ node }) => {
  const [clickedFollowupId, setClickedFollowupId] = useState(null);
  const [hoveredFollowupId, setHoveredFollowupId] = useState(null);
  const [selectedFollowupId, setSelectedFollowupId] = useState(null);
  const { addUserMessageToThread } = useSlotFunctions();

  const chatBarRef = useRef(null);
  const containerRef = useRef(null);

  // Memoize defaultFollowups
  const defaultFollowups = useMemo(
    () => [
      { content: "Thanks! Now can you elaborate on all that?", displayText: "Elaborate", role: "user" },
      { content: "Thanks! Now can you provide a concise summary of all that?", displayText: "Summarize", role: "user" },
      { content: "Thanks! Now, can you synthesize new ideas from what we have so far?", displayText: "Synthesize ideas", role: "user" },
      { content: "Thanks! Now, let's be rigorous. What are some compelling counter-arguments?", displayText: "Counter-arguments?", role: "user" },
      { content: "Thanks! Now, let's dive deeper. Can you articulate the details?", displayText: "More details", role: "user" },
    ].map(followup => ({ ...followup, node_id: generateNodeId(getParentId(node.node_id)) })),
    [node.node_id]
  );

  // Memoize suggestedFollowups
  const suggestedFollowups = useMemo(
    () => (node.suggestedFollowups || []).map(followup => ({
      ...followup,
      node_id: generateNodeId(getParentId(node.node_id))
    })),
    [node.node_id, node.suggestedFollowups]
  );

  // Memoize event handlers
  const handleFollowupClick = (event, followupId) => {
    event.stopPropagation(); // Prevent the click from bubbling up
    if (clickedFollowupId === followupId) {
      // If clicking the same followup, deselect it
      resetSelectedFollowup();
    } else {
      setClickedFollowupId(followupId);
      // Keep the hovered state to maintain focus
      setHoveredFollowupId(followupId);
      focusChatBar();
    }
  };

  const handleFollowupHover = useCallback((followupId) => {
    if (!clickedFollowupId) {
      setHoveredFollowupId(followupId);
    }
  }, [clickedFollowupId]);

  const handleFollowupLeave = useCallback(() => {
    // Disable for now until we add logic to allow you to move the cursor to the chatbar
    // if (!clickedFollowupId) {
    //   setHoveredFollowupId(null);
    // }
  }, []);

  const resetSelectedFollowup = useCallback(() => {
    setClickedFollowupId(null);
    setHoveredFollowupId(null);
  }, []);

  const handleContainerMouseLeave = useCallback(() => {
    if (!clickedFollowupId) {
      setHoveredFollowupId(null);
    }
  }, [clickedFollowupId]);

  const handleContainerClick = useCallback((event) => {
    // If clicking outside of followup buttons and chat bar, reset selection
    if (
      !event.target.closest('.followup-button') &&
      (!chatBarRef.current || !chatBarRef.current.contains(event.target))
    ) {
      resetSelectedFollowup();
    }
  }, [chatBarRef, resetSelectedFollowup]);

  useEffect(() => {
    setSelectedFollowupId(clickedFollowupId || hoveredFollowupId);
  }, [clickedFollowupId, hoveredFollowupId]);

  useEffect(() => {
    if (selectedFollowupId) {
      focusChatBar();
    }
  }, [selectedFollowupId]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const focusChatBar = useCallback(() => {
    if (chatBarRef.current) {
      setTimeout(() => {
        chatBarRef.current.focus();
      }, 0);
    }
  }, [chatBarRef]);

  const handleClickOutside = useCallback((event) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      chatBarRef.current &&
      !chatBarRef.current.contains(event.target)
    ) {
      resetSelectedFollowup();
    }
  }, [containerRef, chatBarRef, resetSelectedFollowup]);

  return (
    <FollowupTreeContainer
      ref={containerRef}
      onMouseLeave={handleContainerMouseLeave}
      onClick={handleContainerClick}
    >
      <FollowupTreeWrapper>
        <div style={{ display: 'flex' }}>
          <FollowupTree>
            {defaultFollowups.map((followup) => (
              <FollowupButton
                key={followup.node_id}
                followup={followup}
                isSelected={selectedFollowupId === followup.node_id}
                onClick={(event) => handleFollowupClick(event, followup.node_id)}
                onMouseEnter={() => handleFollowupHover(followup.node_id)}
                onMouseLeave={handleFollowupLeave}
              />
            ))}
          </FollowupTree>
          {suggestedFollowups.length > 0 && (
            <FollowupTree>
              {suggestedFollowups.map((followup) => (
                <FollowupButton
                  key={followup.node_id}
                  followup={followup}
                  isSelected={selectedFollowupId === followup.node_id}
                  onClick={(event) => handleFollowupClick(event, followup.node_id)}
                  onMouseEnter={() => handleFollowupHover(followup.node_id)}
                  onMouseLeave={handleFollowupLeave}
                />
              ))}
            </FollowupTree>
          )}
        </div>
      </FollowupTreeWrapper>
      <ChatBarWrapper>
        {selectedFollowupId && (
          <CursorChatBar
            key={selectedFollowupId + '_chatbar'}
            ref={chatBarRef}
            appendsMessagesTo={getParentId(node.node_id)}
            selectedFollowup={[...defaultFollowups, ...suggestedFollowups].find(f => f.node_id === selectedFollowupId)}
            onSend={resetSelectedFollowup}
          />
        )}
      </ChatBarWrapper>
    </FollowupTreeContainer>
  );
}, (prevProps, nextProps) => {
  return prevProps.node.node_id === nextProps.node.node_id &&
    prevProps.node.suggestedFollowups === nextProps.node.suggestedFollowups;
});
FollowupsTreeNode.displayName = 'FollowupsTreeNode';

export default FollowupsTreeNode;