import { LeafRightActionBar } from './RightActionBar/LeafRightActionBar';
import { CollectionRightActionBar } from './RightActionBar/CollectionRightActionBar';
import { FollowupsTreeNode } from './FollowupButtons/FollowupsTreeNode';
// import { OutgoingLinkButtons } from './OutgoingLinkButtons/OutgoingLinkButtons';

export const slotComponentMap = {
    'content.chat.message.user': {
        right: LeafRightActionBar,
    },
    'content.chat.message.assistant': {
        right: LeafRightActionBar,
        bottom: FollowupsTreeNode,
    },
    'content.chat.message.website_fulltext': {
        right: LeafRightActionBar,
        // bottom: OutgoingLinkButtons,
    },
    'content.chat.message.document': {
        right: LeafRightActionBar,
    },
    'content.chat.collection': {
        right: CollectionRightActionBar,
    },
    'content.chat.filtered_collection': {
        right: CollectionRightActionBar,
    },
};