import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


const RankIndicatorTag = ({ ranking, speed = 0.3 }) => {
    const [displayRank, setDisplayRank] = useState(0);
    const netWithinClusterRank = ranking.ranking_scores.net_within_cluster_rank;

    useEffect(() => {
        let animationFrameId;
        const animate = () => {
            setDisplayRank((prevRank) => {
                const diff = netWithinClusterRank - prevRank;
                if (Math.abs(diff) < 0.3) {
                    return netWithinClusterRank;
                }
                return prevRank + diff * speed;
            });
            animationFrameId = requestAnimationFrame(animate);
        };
        animationFrameId = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(animationFrameId);
    }, [netWithinClusterRank, speed]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: 'fit-content',
            height: 'fit-content',
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <TrendingUpIcon sx={{ fontSize: '0.8rem', color: '#484b51', marginRight: '0.1rem' }} />
                <Typography sx={{ fontSize: '0.7rem', fontWeight: 'bold', color: '#484b51' }}>{`${displayRank.toFixed(0)}`}</Typography>
            </Box>
        </Box>
    );
};

export default RankIndicatorTag;