import * as NodeConstructors from '../graph/utils/nodeConstructors';
import * as NodeUtils from '../lib/nodeUtils';



export async function triggerProjectUpload(parentId) {
    try {
        const directoryHandle = await window.showDirectoryPicker();
        console.log('Directory selected:', directoryHandle.name);

        const newNode = await convertFilesToNodeTree(directoryHandle, parentId);
        console.log('Converted directory to node tree:', newNode);

        return newNode;
    } catch (error) {
        console.error('Error uploading project:', error);
        throw error;
    }
}

export async function triggerFileUpload(parentId, file) {
    try {
        const content = await this.readFile(file);
        const docNode = NodeConstructors.createDocumentNode(parentId, file.name, content);
        console.log('Created document node:', docNode);
        return docNode;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}

export const convertFilesToNodeTree = async (directoryHandle, parentId, isRoot = true, basePath = '') => {
    console.log('Converting files to node tree:', directoryHandle, parentId, isRoot, basePath);
    const node = isRoot
        ? NodeConstructors.createFilteredCollectionNode(parentId, directoryHandle.name)
        : NodeConstructors.createDirectoryNode(parentId, directoryHandle.name, basePath + directoryHandle.name + '/');

    const childrenContainer = isRoot ? node.unfilteredChildren : node.children;

    for await (const entry of directoryHandle.values()) {
        if (entry.name.startsWith('.')) continue; // Skip hidden files
        const entryPath = basePath + directoryHandle.name + '/' + entry.name;

        if (entry.kind === 'file') {
            const file = await entry.getFile();
            const content = await readFile(file);
            const fileNode = NodeConstructors.createFileNode(node.node_id, entry.name, content, entryPath);
            childrenContainer.set(fileNode.node_id, fileNode);

        } else if (entry.kind === 'directory') {
            const subDirNode = await convertFilesToNodeTree(entry, node.node_id, false, entryPath + '/');
            childrenContainer.set(subDirNode.node_id, subDirNode);
        }
    }

    return node;
};

export async function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsText(file);
    });
}