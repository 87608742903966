import React, { createContext, useState, useEffect } from 'react';

export const SearchQueryContext = createContext();


export const SearchQueryProvider = ({ children }) => {
  const [query, setQuery] = useState('');
  const [customArgs, setCustomArgs] = useState('');

  const updateQueryFromUrl = (urlQuery) => {
    setQuery(urlQuery);
  };

  const value = React.useMemo(() => ({ query, setQuery, customArgs, setCustomArgs, updateQueryFromUrl }), [query, customArgs]);

  return (
    <SearchQueryContext.Provider value={value}>
      {children}
    </SearchQueryContext.Provider>
  );
};