import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from '@mui/material';
import CursorActionButton from './CursorActionButton';
import MapBuilderPopover from '../../MapBuilderPopover';
import { useSlotFunctions } from '../../../../../contexts/ChatContext';

const ActionBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 900;
`;

export const CollectionRightActionBar = ({ node }) => {
    const [mapAnchorEl, setMapAnchorEl] = useState(null);

    const handleMapClick = (event) => {
        setMapAnchorEl(event.currentTarget);
    };

    const handleMapClose = () => {
        setMapAnchorEl(null);
    };

    return (
        <ActionBarContainer>
            <CursorActionButton
                icon={
                    <Icon className="material-symbols-outlined" sx={{ color: 'white' }}>
                        function
                    </Icon>
                }
                text="Map"
                backgroundColor="#4a90e2"
                color="white"
                onClick={handleMapClick}
            />
            <MapBuilderPopover
                anchorEl={mapAnchorEl}
                open={Boolean(mapAnchorEl)}
                onClose={handleMapClose}
                node={node}
            />
        </ActionBarContainer>
    );
};

CollectionRightActionBar.displayName = 'CollectionRightActionBar';

export default CollectionRightActionBar;