import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import ResultSiteInfo from './ResultSiteInfo';

const flashGreen = keyframes`
  0% {
    background-color: rgba(0, 255, 0, 0.5);
  }
  100% {
    background-color: inherit;
  }
`;

const flashRed = keyframes`
  0% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  100% {
    background-color: inherit;
  }
`;

const StyledCard = styled(Card)`
  background-color: rgb(240, 240, 244);
  width: 100%;
  box-shadow: 0 0 2px 2px rgb(10, 10, 10, 0.06);
  border-radius: 6px;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.8;
  animation: ${(props) => (props.flashGreen ? flashGreen : props.flashRed ? flashRed : 'none')} 1s;
`;

const StyledCardContent = styled(CardContent)`
  padding: 0px 6px;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  // align-items: space-between;
  &:last-child {
    padding-bottom: 0px;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 0.9rem;
  margin: 0;
  padding: 0px;
  color: #1b0daa;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;

const ResultCollapsedCard = ({ result }) => {
  const [flashGreen, setFlashGreen] = useState(false);
  const [flashRed, setFlashRed] = useState(false);

  useEffect(() => {
    if (result.ranking && result.ranking.position) {
      const { previous, current } = result.ranking.position;
      if (previous !== null && current !== null) {
        if (current < previous) {
          setFlashGreen(true);
        } else if (current > previous) {
          setFlashRed(true);
        }
        const timer = setTimeout(() => {
          setFlashGreen(false);
          setFlashRed(false);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [result.ranking]);

  return (
    <StyledCard flashGreen={flashGreen} flashRed={flashRed}>
      <StyledCardContent>
        <StyledTypography gutterBottom>
          <a
            href={result.url}
            target="_blank"
            rel="noopener noreferrer"
            dangerouslySetInnerHTML={{
              __html: result.title,
            }}
          ></a>
        </StyledTypography>
        {/* <ResultSiteInfo result={result} showDate={false} /> */}

      </StyledCardContent>
    </StyledCard>
  );
};
export default ResultCollapsedCard;