import React, { useState, useEffect, useRef, useContext } from 'react';
import Box from '@mui/material/Box';
import { Typography, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Flipper, Flipped } from 'react-flip-toolkit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { UIContext } from '../../../../contexts/UIContext';
import SearchResultNode from '../ResultNode/SearchResultNode';


const ClusterContainer = ({ cluster }) => {
    const initialVisibleResults = cluster.numVisibleResults;
    const [visibleResults, setVisibleResults] = useState(initialVisibleResults);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [height, setHeight] = useState('auto');
    const [collapseLastN, setCollapseLastN] = useState(Math.min(2, cluster.numVisibleResults));
    const initialShouldCollapseLastResults = visibleResults < cluster.children.length;
    const [shouldCollapseLastResults, setShouldCollapseLastResults] = useState(initialShouldCollapseLastResults);
    const [isFullyExpanded, setIsFullyExpanded] = useState(false);
    const { isMobile, isFounderModeEnabled } = useContext(UIContext);

    const flipKey = `${visibleResults}_${isFullyExpanded}_${cluster.children.map(r => r.url).join('_')}`;

    useEffect(() => {
        setVisibleResults(cluster.numVisibleResults);
    }, [cluster.numVisibleResults]);

    useEffect(() => {
        const newShouldCollapse = visibleResults < cluster.children.length && visibleResults > 2;
        setShouldCollapseLastResults(newShouldCollapse);

        const newCollapseLastN = Math.min(2, cluster.children.length - visibleResults);
        setCollapseLastN(newCollapseLastN);

        const newIsFullyExpanded = visibleResults === cluster.children.length;
        setIsFullyExpanded(newIsFullyExpanded);
    }, [visibleResults]);

    const handleCollapseToggle = () => {
        if (!isCollapsed) {
            setIsAnimating(true);
            let count = visibleResults;
            const timer = setInterval(() => {
                count--;
                setVisibleResults(count);
                if (count <= 0) {
                    clearInterval(timer);
                    setIsCollapsed(true);
                    setIsFullyExpanded(false);
                    setIsAnimating(false);
                    setHeight('0px');
                }
            }, 50);
        } else {
            setIsCollapsed(false);
            setHeight('auto');
            setVisibleResults(initialVisibleResults);
        }
    };

    const handleSeeMore = () => {
        if (!isFullyExpanded) {
            const newVisibleResults = Math.min(visibleResults + 5, cluster.children.length);
            setVisibleResults(newVisibleResults);

            if (newVisibleResults === cluster.children.length) {
                setIsFullyExpanded(true);
            }
        }
    };

    const handleSeeLess = () => {
        const newVisibleResults = Math.max(visibleResults - 5, initialVisibleResults);
        setVisibleResults(newVisibleResults);
        setIsFullyExpanded(false);
    };
    const handleContainerTitleClick = () => {
        if (visibleResults != initialVisibleResults) {
            handleCollapseToggle();
        }
        else if (!isFullyExpanded) {
            const newVisibleResults = Math.min(visibleResults + 5, cluster.children.length);
            setVisibleResults(newVisibleResults);

            if (newVisibleResults === cluster.children.length) {
                setIsFullyExpanded(true);
            }
        }
        else {
            setVisibleResults(initialVisibleResults);
            setIsFullyExpanded(false);
            handleCollapseToggle();
        }
    }

    const handleKebabClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const showSeeMoreButton = !isFullyExpanded && visibleResults < cluster.children.length && !isCollapsed && !isAnimating;
    const showSeeLessButton = visibleResults > initialVisibleResults && !isCollapsed && !isAnimating;
    const shouldReduceMargin = isCollapsed || !showSeeMoreButton;

    const excludedKeywords = ['children', 'outlier_children'];

    const replacer = (key, value) => {
        if (excludedKeywords.includes(key)) {
            return undefined;
        }
        return value;
    };

    return (
        <Box sx={{
            position: 'relative',
            marginBottom: shouldReduceMargin ? '1.0rem' : '1.5rem',
            marginTop: '1.0rem',
        }}>
            <Box sx={{
                backgroundColor: 'rgb(233, 233, 237)',
                borderRadius: '12px',
                boxShadow: '0 0 2px 3px rgb(10, 10, 10, 0.10)',
                marginBottom: '0.15rem',
                overflow: 'hidden',
            }}>
                {cluster.children.length > 1 && (
                    <div
                        onClick={handleContainerTitleClick}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            paddingX: '5px',
                        }}>
                        <Box
                            sx={{
                                textAlign: 'left',
                                borderRadius: '6px',
                                padding: '2px 4px',
                                maxWidth: 'fit-content',
                                marginLeft: '8px',
                                marginTop: '8px',
                                marginBottom: isCollapsed ? '8px' : '0px',
                                backgroundColor: '#dfe0ed',
                                boxShadow: '0 0 2px 2px rgb(10, 10, 10, 0.1)',
                            }}>
                            <Typography variant="h5" sx={{
                                fontSize: isMobile ? '0.95rem' : '1.0rem',
                                color: 'rgb(48, 49, 54)',
                                fontWeight: 500,
                            }}>
                                {isFounderModeEnabled ? (
                                    `${cluster.cluster_num}: ${cluster.cluster_num === -1 ? "Mix of results" : cluster.description}`
                                ) : (
                                    cluster.cluster_num === -1 ? "Mix of results" : cluster.description
                                )}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={handleCollapseToggle} sx={{ padding: '0px', color: '#484b51' }}>
                                {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </IconButton>

                            <IconButton onClick={handleKebabClick} sx={{ color: '#484b51' }}>
                                <MoreVertIcon sx={{ fontSize: '50%', padding: '0px' }} />
                            </IconButton>
                        </Box>
                    </div>
                )}

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleOpenDialog}>View JSON</MenuItem>
                </Menu>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{"Cluster JSON"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <pre>{JSON.stringify(cluster, replacer, 2)}</pre>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>

                <div style={{
                    transition: 'height 0.3s ease-in-out',
                    height,
                }}>
                    <Flipper spring={{ stiffness: 260, damping: 26 }} staggerConfig={{ reverse: true, speed: 0.01 }} flipKey={flipKey}>
                        {cluster.children.slice(0, visibleResults).map((result, index) => {
                            const resultIsCollapsed = shouldCollapseLastResults && index >= visibleResults - collapseLastN;
                            return (
                                <Flipped key={result.id} flipId={`${result.id}`}>
                                    <div>
                                        <SearchResultNode
                                            result={{
                                                ...result,
                                                is_collapsed: resultIsCollapsed,
                                            }}
                                        />
                                    </div>
                                </Flipped>
                            );
                        })}
                    </Flipper>
                </div>
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '-8px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 5,
            }}>
                {showSeeLessButton && (
                    <IconButton
                        onClick={handleSeeLess}
                        sx={{
                            height: '1.1rem',
                            width: 'fit-content',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textTransform: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#dfe0ed',
                            color: 'rgb(48, 49, 54)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                backgroundColor: 'rgb(207, 207, 221)',
                            },
                            marginRight: '4px',
                        }}
                    >
                        <ExpandLessIcon />
                    </IconButton>
                )}

                {showSeeMoreButton && (
                    <IconButton
                        onClick={handleSeeMore}
                        sx={{
                            height: '1.1rem',
                            width: 'fit-content',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textTransform: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#dfe0ed',
                            color: 'rgb(48, 49, 54)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                backgroundColor: 'rgb(207, 207, 221)',
                            },
                        }}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default ClusterContainer;