import React from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const ContactInfo = () => {

    const email = "humans@stickshift.ai";

    const handleCopyEmail = async () => {
        if (!navigator.clipboard) {
            console.error('Clipboard API not available');
            return;
        }
        try {
            await navigator.clipboard.writeText(email);
            // Display a success message or toast notification to the user
        } catch (err) {
            console.error('Failed to copy email: ', err);
        }
    };

    return (
        <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', px: { xs: 1, sm: 2 }
        }}>
            {/*Typography for contact info or any other text */}
            <Typography sx={{ fontSize: '0.9rem', width: '100%', maxWidth: '100%', color: 'rgb(212, 215, 221)', backgroundColor: 'transparent' }} color="inherit">
                {email}
            </Typography>

            {/* add this back once we have tls
        <Tooltip title="Copy email">
            <IconButton color="inherit" onClick={handleCopyEmail}>
            <FileCopyIcon />
            </IconButton>
        </Tooltip> */}


        </Box>
    );
};

export default ContactInfo;