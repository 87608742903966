// nodeUtils.js

import { debugLogger } from './debugLogger.js';
import { produce } from 'immer';
import { memoize } from 'lodash';
import { generateNodeId } from '../graph/utils/idGenerator';
import * as NodeConstructors from '../graph/utils/nodeConstructors';
/**
 * nodeUtils.js
 * 
 * This module manages the hierarchical node structure of the chat application, providing utilities
 * for node creation, manipulation, and traversal.
 * 
 * Key Concepts:
 * 1. Example Node Tree Structure:
 *    - rootThreadContainer (top-level)
 *      ├── Thread 1
 *      │   ├── Message 1 (user/assistant)
 *      │   ├── Document 1
 *      │   └── Nested ThreadContainer
 *      │       └── Nested Thread 1
 *      └── Thread 2
 *          ├── Message 2
 *          └── Website 1
 * 
 * 2. Node Types:
 *    - thread_container: Contains multiple threads
 *    - thread: Contains messages and other nodes (always wrapped by a thread_container)
 *    - collection: contains multiple nodes
 *    - message: User or assistant messages
 *    - document: Uploaded file content
 *    - website: Fetched web content
 * 
 * 3. Node IDs:
 *    - Hierarchical structure: parentId.childId (e.g., "root.thread1.message2")
 *    - Allows easy parent identification and tree traversal
 * 
 * 4. Key Operations:
 *    - Node finding: Recursive top-down search
 *    - Node addition/removal: Modifies parent's children Map
 *    - Width updating: Bottom-up recursion to calculate container widths
 * 
 * 5. Recursion Patterns:
 *    - Top-down: Used for searching and simple modifications
 *    - Bottom-up: Used for aggregating data from children to parents (e.g., width calculations)
 *    - Path-building: Used for constructing node paths
 * 
 * 6. Width Calculation Logic:
 *    - Thread widths decrease with nesting depth
 *    - Container widths are the max of total children width or base width
 * 
 * 7. Immutability:
 *    - All operations return new objects/structures to maintain state immutability
 * 
 * 8. Performance Considerations:
 *    - Recursive operations can be costly for deep structures
 *    - Consider optimizations like memoization for frequent operations
 * 
 * This module is crucial for maintaining the chat application's complex data structure.
 * Understanding the node hierarchy and the nature of recursive operations is key to
 * working with and extending this system effectively.
 */


export const getParentId = memoize((nodeId) => {
    const parts = nodeId.split('.');
    return parts.length > 1 ? parts.slice(0, -1).join('.') : null;
}, (nodeId) => nodeId);

export const findPreviousSiblingId = (rootNode, nodeId) => {
    const parentId = getParentId(nodeId);
    const parentNode = findNode(rootNode, parentId);

    if (!parentNode || !parentNode.children) return null;

    let previousSiblingId = null;
    for (const [childId, childNode] of parentNode.children.entries()) {
        if (childId === nodeId) break;
        previousSiblingId = childId;
    }

    return previousSiblingId;
};

export const countMessages = (node) => {
    let count = 0;
    if (node.type.startsWith('content.chat.message')) {
        count++;
    }
    if (node.children) {
        for (const childNode of node.children.values()) {
            count += countMessages(childNode);
        }
    }
    return count;
};

export const getLowestAncestorOfType = (rootNode, nodeId, type) => {
    const path = getNodePathWithTypes(rootNode, nodeId);
    if (!path) return null;

    for (let i = path.length - 1; i >= 0; i--) {
        const [currentNodeId, currentNodeType] = path[i];
        if (currentNodeType === type) {
            return findNode(rootNode, currentNodeId);
        }
    }
    console.log(`No ancestor of type ${type} found`);
    return null;
};

export const getHighestAncestorOfType = (rootNode, nodeId, type) => {
    const path = getNodePathWithTypes(rootNode, nodeId);
    if (!path) return null;

    // Traverse the path from the root down to the node
    for (let i = 0; i < path.length; i++) {
        const [currentNodeId, currentNodeType] = path[i];
        if (currentNodeType === type) {
            return findNode(rootNode, currentNodeId);
        }
    }
    return null;
};


export const getRootNodeId = memoize((nodeId) => {
    return nodeId.split('.')[0];
}, (nodeId) => nodeId);

export const getNodeDepth = memoize((nodeId) => {
    return nodeId.split('.').length - 1;
}, (nodeId) => nodeId);

export const findNode = (rootNode, nodeId) => {
    if (rootNode.node_id === nodeId) {
        return rootNode;
    }
    if (rootNode.children) {
        for (const childNode of rootNode.children.values()) {
            const found = findNode(childNode, nodeId);
            if (found) return found;
        }
    }
    return null;
};

export const findParentThreadContainer = (rootNode, threadId) => {
    const parentId = getParentId(threadId);
    if (!parentId) return rootNode; // If no parent, it's at the root level

    const parentNode = findNode(rootNode, parentId);
    if (parentNode && parentNode.type === 'content.chat.thread_container') {
        return parentNode;
    }

    // If parent is not a thread_container, recursively look for the container
    return findParentThreadContainer(rootNode, parentId);
};

export const getNodePath = (rootNode, nodeId) => {
    if (rootNode.node_id === nodeId) {
        return [nodeId];
    }
    if (rootNode.children) {
        for (const childNode of rootNode.children.values()) {
            const path = getNodePath(childNode, nodeId);
            if (path) {
                return [rootNode.node_id, ...path];
            }
        }
    }
    return null;
};


export const getNodePathWithTypes = (rootNode, nodeId) => {
    if (rootNode.node_id === nodeId) {
        return [[rootNode.node_id, rootNode.type]];
    }
    if (rootNode.children) {
        for (const childNode of rootNode.children.values()) {
            const path = getNodePathWithTypes(childNode, nodeId);
            if (path) {
                return [[rootNode.node_id, rootNode.type], ...path];
            }
        }
    }
    return null;
};




export const addNodeToParent = (rootNode, parentId, newNode) => {
    updateNode(rootNode, parentId, (parent) => {
        if (!parent.children) {
            parent.children = new Map();
        }
        parent.children.set(newNode.node_id, newNode);
    });
};

export const removeNodeFromParent = (rootNode, nodeId) => {
    const parentId = getParentId(nodeId);
    return updateNode(rootNode, parentId, (parent) => {
        if (parent.children) {
            parent.children.delete(nodeId);
        }
    });
};

export const updateNode = (rootNode, nodeId, updateFn) => {
    if (rootNode.node_id === nodeId) {
        Object.assign(rootNode, updateFn(rootNode));
        return true;
    }
    if (rootNode.children) {
        for (const childNode of rootNode.children.values()) {
            if (updateNode(childNode, nodeId, updateFn)) {
                return true;
            }
        }
    }
    return false;
};

export const getAllThreads = (rootNode) => {
    const threads = new Map();
    const collectThreads = (node) => {
        if (node.type === 'content.chat.thread') {
            threads.set(node.node_id, node);
        }
        if (node.children) {
            for (const childNode of node.children.values()) {
                collectThreads(childNode);
            }
        }
    };
    collectThreads(rootNode);
    return threads;
};

const formatDocumentContent = (node) => {
    if (!node.name) return node.content || '';

    const extension = node.name.split('.').pop().toLowerCase();
    const delimiter = (extension === 'md' || extension === 'txt') ? '"""' : '```';
    const language = (delimiter === '```') ? `${node.language || ''}\n` : '';

    return `[${node.name}]\n${delimiter}${language}${node.content || ''}${delimiter}`;
};

const formatWebsiteContent = (node) => {
    const urlInfo = node.url ? ` (${node.url})` : '';
    return `[Website${urlInfo}]\n"""${node.content || node.summary || ''}"""`;
};

const formatCollectionContent = (node) => {
    const childCount = node.children ? node.children.size : 0;
    const childrenContent = node.children
        ? Array.from(node.children.values()).map(getNodeContent).join('\n\n')
        : '';
    return `[Collection (${childCount} items)]\n{${childrenContent}}`;
};

export const getNodeContent = (node) => {
    switch (node.type) {
        case 'content.chat.thread':
        case 'content.chat.thread_container':
            return node.children
                ? Array.from(node.children.values()).map(getNodeContent).join('\n\n')
                : '';
        case 'content.chat.message.user':
        case 'content.chat.message.assistant':
            return node.content || '';
        case 'content.chat.message.website_fulltext':
        case 'content.chat.message.website_summary':
            return `[Website (${node.url})]\n"""${node.content || node.summary || ''}"""`;
        case 'content.chat.message.document':
            return `[${node.name}]\n"""${node.content || ''}"""`;
        case 'content.chat.collection':
            const childCount = node.children ? node.children.size : 0;
            const childrenContent = node.children
                ? Array.from(node.children.values()).map(getNodeContent).join('\n\n')
                : '';
            return `[Collection (${childCount} items)]\n{${childrenContent}}`;
        default:
            console.warn(`Unknown node type: ${node.type}`);
            return '';
    }
};


export const cloneNodeTree = (node, newParentId) => {
    const clonedNode = {
        ...node,
        node_id: generateNodeId(newParentId),
        clonedFrom: node.node_id  // Add this line
    };

    if (node.children) {
        clonedNode.children = new Map();
        for (const [childId, childNode] of node.children) {
            const clonedChild = cloneNodeTree(childNode, clonedNode.node_id);
            clonedNode.children.set(clonedChild.node_id, clonedChild);
        }
    }

    if (node.unfilteredChildren) {
        clonedNode.unfilteredChildren = new Map();
        for (const [childId, childNode] of node.unfilteredChildren) {
            const clonedChild = cloneNodeTree(childNode, clonedNode.node_id);
            clonedNode.unfilteredChildren.set(clonedChild.node_id, clonedChild);
        }
    }

    return clonedNode;
};
