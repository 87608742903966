import React, { useContext, useState, useRef, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Tooltip, Box } from '@mui/material';
import styled from '@emotion/styled';
import { motion, useAnimation } from 'framer-motion';


import LogoComponent from '../WebSearch/Header/LogoComponent';
import MainSearchBar from '../WebSearch/Header/MainSearchBar';
import SearchBarIcon from '../WebSearch/Header/SearchBarIcon';
import UserMenu from '../Account/UserMenu';
import SessionManagementDropdown from './SessionManagementDropdown';
import ChromeShortcutUpsell from './ChromeShortcutUpsell';

import { SearchResultsContext } from '../../contexts/SearchResultsContext';
import { SearchQueryContext } from '../../contexts/SearchQueryContext';
import { ChatContext } from '../../contexts/ChatContext';
import { UIContext } from '../../contexts/UIContext';



const HeaderContainer = styled(Box)`
  box-sizing: border-box;
  height: 55px;
  max-height: 55px;
  width: 100%;
  max-width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #121212;
  opacity: 0.96;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
  position: relative;

  & * {
    box-sizing: border-box;
  }
`;

const LogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;


const SearchBarTrack = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
`;

const SearchBarContainer = styled(motion.div)`
  width: 100%;
  max-width: 600px;
`;

const RightSideContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;


const MainPageHeader = memo(({ handleSearchToggle, handleChatToggle }) => {
  const history = useHistory();
  const { setQuery } = useContext(SearchQueryContext);
  const { clearMessages } = useContext(ChatContext);
  const { setResults } = useContext(SearchResultsContext);
  const { toggleSidebar, isSidebarOpen, isMobile, isSearchOpen, isChatOpen, isFounderModeEnabled } = useContext(UIContext);


  const searchBarControls = useAnimation();
  const searchBarRef = useRef(null);

  const searchBarVariants = {
    open: { marginLeft: 0, width: '100%', opacity: 1 },
    closed: { marginLeft: 0, width: '100px', opacity: 1 },
  };

  useEffect(() => {
    if (isSearchOpen) {
      searchBarControls.start('open');
    } else {
      searchBarControls.start('closed');
    }
  }, [isSearchOpen, searchBarControls]);



  const redirectLanding = () => {
    setResults([]);
    clearMessages();
    setQuery('');
    history.push('/');
  };

  const focusSearchBar = () => {
    if (searchBarRef.current) {
      searchBarRef.current.focus();
    }
  };

  return (
    <HeaderContainer>
      {isSearchOpen && (
        <Tooltip title="Hide search">
          <IconButton onClick={handleSearchToggle}>
            <span className="material-symbols-outlined">splitscreen_left</span>
          </IconButton>
        </Tooltip>
      )}

      {isSidebarOpen && isSearchOpen && (
        <LogoContainer onClick={redirectLanding} sx={{ mr: 2 }}>
          <LogoComponent showText={isSearchOpen} />
        </LogoContainer>
      )}

      <SearchBarTrack>
        <SearchBarContainer
          initial={isSearchOpen ? 'open' : 'closed'}
          variants={searchBarVariants}
          animate={searchBarControls}
          transition={{ duration: 0.3 }}
        >
          {isSearchOpen ? (
            <MainSearchBar autoFocus />
          ) : (
            <SearchBarIcon onClick={handleSearchToggle} />
          )}
        </SearchBarContainer>
      </SearchBarTrack>

      <RightSideContainer>
        {/* <ChromeShortcutUpsell /> */}

        {isFounderModeEnabled && <UserMenu />}

        {isChatOpen && (<SessionManagementDropdown />)}

        <Tooltip title={isChatOpen ? 'Hide chat' : 'Open chat'}>
          <IconButton onClick={handleChatToggle}>
            <span className="material-symbols-outlined">splitscreen_right</span>
          </IconButton>
        </Tooltip>
      </RightSideContainer>
    </HeaderContainer>
  );
});

export default MainPageHeader;