// LLMConfigContext.js

import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';

export const LLMConfigContext = createContext();

export const LLMConfigProvider = ({ children }) => {
    const [llmConfigs, setLLMConfigs] = useState({
        'default_chat': {
            model: 'claude-3-5-sonnet-20240620',
            temperature: 0.6,
            max_tokens: 4096,
        }
    });

    const createLLMConfig = useCallback((configId, config) => {
        setLLMConfigs((prevConfigs) => ({
            ...prevConfigs,
            [configId]: config,
        }));
    }, []);

    const updateLLMConfig = useCallback((configId, config) => {
        setLLMConfigs((prevConfigs) => ({
            ...prevConfigs,
            [configId]: {
                ...prevConfigs[configId],
                ...config,
            },
        }));
    }, []);

    const deleteLLMConfig = useCallback((configId) => {
        setLLMConfigs((prevConfigs) => {
            const { [configId]: _, ...updatedConfigs } = prevConfigs;
            return updatedConfigs;
        });
    }, []);

    const getLLMConfig = useCallback((configId) => {
        return llmConfigs[configId];
    }, [llmConfigs]);

    const contextValue = useMemo(() => ({
        llmConfigs,
        createLLMConfig,
        updateLLMConfig,
        deleteLLMConfig,
        getLLMConfig,
    }), [llmConfigs, createLLMConfig, updateLLMConfig, deleteLLMConfig, getLLMConfig]);

    return (
        <LLMConfigContext.Provider value={contextValue}>
            {children}
        </LLMConfigContext.Provider>
    );
};

export const useLLMConfig = () => useContext(LLMConfigContext);