import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, TextField, Button, Modal } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { logEvent } from '../../../lib/logger';

const Root = styled(Box)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  padding-left: 10px;
`;

const FeedbackButton = styled(Button)`
  border-radius: 7px;
  margin-top: 0.5rem;
  text-transform: none;
  font-size: 0.8rem;
  padding: 2px 8px;
  color: rgb(212, 215, 221);
  background-color: #1e1e1e;
  border: 1px solid rgba(212, 215, 221, 0.7);

  &:hover {
    border: 1px solid #fff;
    color: #fff;
  }
`;

const ModalWrapper = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  padding: ${({ theme }) => theme.spacing(2, 4, 3)};
  outline: none;
  width: 80%;
  max-width: 600px;
  border-radius: 12px;
`;

const SuccessIcon = styled(CheckCircleIcon)`
  color: green;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const ErrorMessage = styled(Typography)`
  color: red;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const UserFeedback = () => {
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        if (submitted) {
            setError(false);
            setFeedback('');
            setSubmitted(false);
        }
    };

    const handleSubmit = async () => {
        try {
            await logEvent('INFO', 'user_feedback_submitted', 'User feedback submitted', { user_feedback_string: feedback });
            console.log('Feedback submitted: ', feedback);
            setSubmitted(true);
            setError(false);
            setTimeout(() => {
                if (open) {
                    handleClose();
                }
            }, 1500);
        } catch (err) {
            setError(true);
        }
    };

    return (
        <Root>
            <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: '500' }}>Feedback</Typography>
            <Box sx={{ paddingLeft: '0px', mb: 1 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>I read everything!</Typography>
                <FeedbackButton variant="outlined" onClick={handleOpen}>
                    Tell me something
                </FeedbackButton>
            </Box>

            <ModalWrapper open={open} onClose={handleClose} className="UserFeedback-modal" BackdropProps={{ onClick: handleClose }}>
                <ModalContent>
                    <Typography variant="h6">Feedback</Typography>
                    <Typography variant="body2">Tell me literally anything! Optionally, include contact info if you wanna chat more about it.</Typography>
                    <TextField
                        multiline
                        rows={6}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                    {submitted && (
                        <>
                            <SuccessIcon />
                            <Typography variant="body2" color="green">
                                Submitted! Thank you!
                            </Typography>
                        </>
                    )}
                    {error && <ErrorMessage variant="body2">Error submitting feedback. Please try again.</ErrorMessage>}
                </ModalContent>
            </ModalWrapper>
        </Root>
    );
};

export default UserFeedback;