import React, { useContext, useState } from 'react';
import { useCollectionFunctions } from '../../../../contexts/ChatContext';
import { Box, IconButton, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from '@emotion/styled';
import { generateNodeId } from '../../../../graph/utils/idGenerator';
import { withLayoutProfiler } from '../../../../HOCs/LayoutProfilerHOC'; // Adjust the import path as needed
import ThreadBottomActionBar from './ThreadBottomActionBar';


const ChatBarContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const ChatInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 10px;
  border: 1px solid rgb(98, 98, 98);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #1e1e1e;
  color: #fff;
  width: 100%;
  min-height: 3.5rem;
  transition: all 0.1s;
  &:focus-within {
    border: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
    border-radius: 6px;  
  }
`;

const ActionBarWrapper = styled(Box)`
  position: absolute;
  top: -2px; // Adjust this value to position it above the ChatInputContainer
  right: 0;
  z-index: 1;
`;


const StyledIconButton = styled(IconButton)`
  padding: 0px;
  margin: 0 8px;
`;


const CancelButton = styled(IconButton)`
  margin-left: 10px;
`;

const ChatBar = React.memo(({ thread }) => {
  const { addUserMessageToThread, cancelGeneration } = useCollectionFunctions();
  const [message, setMessage] = useState('');

  const threadId = thread.node_id;
  const isLoading = thread?.isLoading;
  const lastMessageNodeId = Array.from(thread?.children?.values() || [])
    .filter(node => node?.type?.startsWith('content.chat.message'))
    .pop()?.node_id;
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const nodeId = generateNodeId(threadId);
      console.log('handleSendMessage', threadId, message, nodeId);
      addUserMessageToThread(threadId, {
        content: message,
        role: 'user',
        timestamp: new Date().toISOString(),
        node_id: nodeId,
      });
      setMessage('');
    }
  };

  return (
    <ChatBarContainer>
      <ActionBarWrapper>
        <ThreadBottomActionBar threadId={threadId} />
      </ActionBarWrapper>
      <ChatInputContainer>
        <TextField
          multiline
          value={message}
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          fullWidth
          maxRows={30}
          sx={{
            '& .MuiOutlinedInput-root': {
              color: '#fff',
              backgroundColor: '#1e1e1e',
              borderRadius: '4px',
              padding: '0px',
              '& fieldset': {
                border: 'none',
              },
            },
            '& .MuiInputBase-input': {
              overflowY: 'auto',
              maxHeight: '30vh',
            },
          }}
        />
        <StyledIconButton onClick={handleSendMessage} sx={{ color: '#fff' }}>
          <SendIcon />
        </StyledIconButton>
        {isLoading && (
          <StyledIconButton onClick={() => cancelGeneration(lastMessageNodeId)} sx={{ color: '#fff', marginLeft: '10px' }}>
            <CancelIcon />
          </StyledIconButton>
        )}
      </ChatInputContainer>
    </ChatBarContainer>
  );
}, (prevProps, nextProps) => {
  return prevProps.thread.isLoading === nextProps.thread.isLoading &&
    prevProps.thread.node_id === nextProps.thread.node_id &&
    prevProps.thread.children?.size === nextProps.thread.children?.size &&
    prevProps.thread.children === nextProps.thread.children;
});

ChatBar.displayName = 'ChatBar';
export default ChatBar;