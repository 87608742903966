import React, { useContext, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChatIcon from '@mui/icons-material/Chat';
import CollectionsIcon from '@mui/icons-material/Collections';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SearchResultsContext } from '../../../contexts/SearchResultsContext';
import ActionButton from '../../common/ActionButton';

const StyledSection = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const StyledHeader = styled(Typography)`
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const MiddleActionBar = () => {
    const { expandAllClusters, collapseAllClusters } = useContext(SearchResultsContext);
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: isExpanded ? '200px' : '60px',
            marginLeft: '0.5%',
            marginRight: '0.5%',
            marginTop: '0.5%',
            padding: '16px',
            backgroundColor: '#1e1e1e',
            borderRadius: '8px',
            transition: 'width 0.3s',
        }}>
            <StyledHeader variant="subtitle1">
                {isExpanded ? 'Result Actions' : ''}
                <IconButton onClick={toggleExpand} size="small" sx={{ marginLeft: 'auto', color: '#333' }}>
                    {isExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </StyledHeader>

            {isExpanded && (
                <>
                    <StyledSection>
                        <StyledHeader variant="subtitle2">Cluster Actions</StyledHeader>
                        <ActionButton onClick={expandAllClusters} startIcon={<UnfoldMoreIcon />}>
                            Expand All
                        </ActionButton>
                        <ActionButton onClick={collapseAllClusters} startIcon={<UnfoldLessIcon />}>
                            Collapse All
                        </ActionButton>
                    </StyledSection>

                    <StyledSection>
                        <StyledHeader variant="subtitle2">Select</StyledHeader>
                        <ActionButton startIcon={<FilterListIcon />}>Top 5</ActionButton>
                        <ActionButton startIcon={<FilterListIcon />}>Top 10</ActionButton>
                        <ActionButton startIcon={<FilterListIcon />}>Top 20</ActionButton>
                    </StyledSection>

                    <StyledSection>
                        <StyledHeader variant="subtitle2">Result Actions</StyledHeader>
                        <ActionButton startIcon={<ChatIcon />}>Add to Chat</ActionButton>
                        <ActionButton startIcon={<CollectionsIcon />}>New Collection</ActionButton>
                    </StyledSection>
                </>
            )}
        </Box>
    );
};

export default MiddleActionBar;