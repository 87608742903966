// DexieDB.js
import Dexie from 'dexie';

const db = new Dexie('Stickshift');
db.version(3).stores({
    chatSessions: '++id, createdAt, lastUpdated, title, rootThreadContainer, systemPrompt, messageCount',
    usageData: '++id, sessionId, model, inputTokens, outputTokens, timestamp',
    usageLimits: 'id, dailyLimit, monthlyLimit'
});

export default db;