import React, { createContext, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { debugLogger } from '../lib/debugLogger';

export const UIContext = createContext();


// Use a narrower width multiplier by default on wider screens so the threads aren't crazy wide unless explicitly set by the user
const DEFAULT_WIDTH_MULTIPLIER_INDEX_BREAKPOINTS = [
  { width: 1000, index: 6 },
  { width: 1500, index: 5 },
  { width: 2000, index: 4 },
  { width: Infinity, index: 3 }
];

// Define globalWidthMultiplierOptions here
const globalWidthMultiplierOptions = new Map(
  Array.from({ length: 6 }, (_, i) => {
    const index = i + 1;
    return [index, (index + 1) / 7];
  })
);

export const UIProvider = ({ children }) => {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [isFounderModeEnabled, setIsFounderModeEnabled] = useState(false);
  const [isChatRightActionBarOpen, setIsChatRightActionBarOpen] = useState(!isMobile);
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const baseThreadWidthRef = useRef(800);
  const [chatAvailableWidth, setChatAvailableWidth] = useState(1100);
  const [maxThreadWidth, setMaxThreadWidth] = useState(1500); // This is really more like maxBaseThreadWidth, or maxRootThreadWidth
  const [isResizing, setIsResizing] = useState(false); // TODO: should this be a ref?
  const [chatAvailableHeight, setChatAvailableHeight] = useState(1000);
  const [globalWidthMultiplier, setGlobalWidthMultiplier] = useState(1);
  const [globalWidthMultiplierIndex, setGlobalWidthMultiplierIndex] = useState(6); // Default to 7(full width)

  const minThreadWidth = 300;

  // useEffect(() => {
  //   console.log('[UIContext] Chat available width,  globalWidthMultiplier, baseThreadWidth', { chatAvailableWidth, globalWidthMultiplier, baseThreadWidth: baseThreadWidthRef.current });
  // }, [chatAvailableWidth, globalWidthMultiplier]);


  /*
  TODO: we seem to have some synchronization bugs between UIContext and ChatContext
  Maybe we need to use a ref for chatAvailableWidth and chatAvailableHeight in ChatContext? But we still need to listen to changes and trigger rerenders. Maybe we have some "widthUpdateVersion" that we increment on every change, then if we need to listen to something, we use that?
  Or should we make a new context for width layout stuff?
  Overall, we did notice that as soon as this rerendered, the issue went away, so it seems like we could also accomplish this with a forceUpdate or something

  Also, in ChatContext, we have a bunch of redundant functions for updating widths, trigger width update, etc, and inconsistent use of updateNode vs updateRootThreadContainer with a NodeUtils.updateNode call

  The issue happened with both handleError and add  file from the ThreadBottomActionBar

  FROM THE FUTURE: we ended up patching this with polling and auto resize

  */


  const updateGlobalWidthMultiplier = useCallback((newIndex) => {
    setGlobalWidthMultiplierIndex(newIndex);
    const newMultiplier = globalWidthMultiplierOptions.get(newIndex);
    setGlobalWidthMultiplier(newMultiplier);
  }, []);



  const setBaseThreadWidthWithResize = useCallback((newWidthOrCallback) => {
    const prevWidth = baseThreadWidthRef.current;
    const finalNewWidth = typeof newWidthOrCallback === 'function'
      ? newWidthOrCallback(prevWidth)
      : newWidthOrCallback;

    baseThreadWidthRef.current = Math.min(Math.max(finalNewWidth, minThreadWidth), maxThreadWidth);
    // debugLogger.log('debug', '[UIContext] Updating base thread width', { newWidth: baseThreadWidthRef.current });
  }, [minThreadWidth, maxThreadWidth]);

  const setChatAvailableWidthWithResize = useCallback((newWidthOrCallback) => {
    setChatAvailableWidth(prevWidth => {
      const finalNewWidth = typeof newWidthOrCallback === 'function'
        ? newWidthOrCallback(prevWidth)
        : newWidthOrCallback;

      return Math.max(finalNewWidth, minThreadWidth);
    });
    // debugLogger.log('debug', '[UIContext] Updating chat available width', { newWidth: chatAvailableWidth });
  }, [minThreadWidth, chatAvailableWidth]);

  const setChatAvailableHeightWithResize = useCallback((newHeightOrCallback) => {
    setChatAvailableHeight(prevHeight => {
      const finalNewHeight = typeof newHeightOrCallback === 'function'
        ? newHeightOrCallback(prevHeight)
        : newHeightOrCallback;

      return Math.max(finalNewHeight, 0);
    });
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => !prev);
  }, []);

  const toggleChatRightActionBar = useCallback(() => {
    setIsChatRightActionBarOpen(prev => !prev);
  }, []);

  const contextValue = useMemo(
    () => ({
      isSidebarOpen,
      setIsSidebarOpen,
      toggleSidebar,
      isChatRightActionBarOpen,
      toggleChatRightActionBar,
      isChatOpen,
      setIsChatOpen,
      isSearchOpen,
      setIsSearchOpen,
      baseThreadWidthRef,
      setBaseThreadWidth: setBaseThreadWidthWithResize,
      minThreadWidth,
      maxThreadWidth,
      setMaxThreadWidth,
      chatAvailableWidth,
      setChatAvailableWidth: setChatAvailableWidthWithResize,
      chatAvailableHeight,
      setChatAvailableHeight: setChatAvailableHeightWithResize,
      isMobile,
      isFounderModeEnabled,
      setIsFounderModeEnabled,
      isResizing,
      setIsResizing,
      globalWidthMultiplier,
      globalWidthMultiplierIndex,
      updateGlobalWidthMultiplier,
      globalWidthMultiplierOptions,
    }),
    [isSidebarOpen, isChatRightActionBarOpen, isMobile, isFounderModeEnabled,
      isChatOpen, isSearchOpen, baseThreadWidthRef, chatAvailableWidth,
      minThreadWidth, maxThreadWidth, chatAvailableHeight, isResizing, setBaseThreadWidthWithResize,
      setChatAvailableWidthWithResize, setChatAvailableHeightWithResize, toggleSidebar, toggleChatRightActionBar,
      globalWidthMultiplier, globalWidthMultiplierIndex, updateGlobalWidthMultiplier]
  );

  return (
    <UIContext.Provider value={contextValue}>
      {children}
    </UIContext.Provider>
  );
};

UIContext.displayName = 'UIContext';

export const useUIContext = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUIContext must be used within a UIProvider');
  }
  return context;
};

export default UIProvider;