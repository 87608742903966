import { memoize } from 'lodash';

import { getNodeDepth } from '../../lib/nodeUtils';

// Define the global width limits map
// Define the global width limits map
const DEFAULT_GLOBAL_WIDTH_LIMITS = {
    'content.chat.message.user': 1200,
    'content.chat.message.assistant': 1200,
    'content.chat.message.document': 1200,
    'content.chat.message.website_fulltext': 1200,
    'content.chat.message.website_summary': 1200,
    'content.chat.collection': 1200,
    'content.chat.filtered_collection': 1200,
    'content.chat.thread': 3100,
    'content.chat.thread_container': 3100
};

/*
Now: TODO:
- this needs to accept graph and use that to get node depth, children, etc




Later: TODO:
- add sibling multiplier
- go by node.direction instead of node.type (row or column)

*/

const memoizedDepthAdjustedWidth = memoize(
    (depth, width, paddingUnit) => Math.max(width - (depth * paddingUnit), 0),
    (depth, width, paddingUnit) => `${depth}-${width}-${paddingUnit}`
);

export const updateNodeWidths = ({
    rootThreadContainer,
    node,
    basisWidth = 900,
    globalWidthLimits = DEFAULT_GLOBAL_WIDTH_LIMITS,
    paddingUnit = 30,
    rootAvailableWidth = null
}) => {
    let maxChildWidth = 0;
    let totalChildWidth = 0;

    if (node.children && node.children.size > 0) {
        for (const childNode of node.children.values()) {
            updateNodeWidths({
                rootThreadContainer,
                node: childNode,
                basisWidth,
                globalWidthLimits,
                paddingUnit,
                rootAvailableWidth
            });
            maxChildWidth = Math.max(maxChildWidth, childNode.width);
            totalChildWidth += childNode.width;
        }
    }

    const depth = getNodeDepth(node.node_id);

    if (depth === 0 && node.type === 'content.chat.thread_container' && rootAvailableWidth !== null) {
        node.width = Math.floor(rootAvailableWidth);
        return;
    }

    const adjustedWidthLimit = Math.floor(memoizedDepthAdjustedWidth(depth, globalWidthLimits[node.type], paddingUnit));
    const standardWidth = Math.floor(memoizedDepthAdjustedWidth(depth, basisWidth, paddingUnit)); // target width assuming no wide children

    switch (node.type) {
        case 'content.chat.thread':
        case 'content.chat.collection':
        case 'content.chat.filtered_collection':
            node.width = Math.min(Math.max(standardWidth, maxChildWidth), adjustedWidthLimit);
            break;
        case 'content.chat.thread_container':
            // Horizontal containers can be extra wide

            /// ohhhhh actually the issue is that the parent thread is getting stuck on the width limit
            // okay setting them to have the same width limit fixed it
            // but fundamentally, is it possible to have width limit with bottom up recursion? can we just prevent this case?
            // adding sibling multiplier might help a tiny bit, but not really
            // ultimately, we need to rethink the recursion, or restrict baseThreadWidth so that we don't max these threads out unnecessarily. most of the padding is waste

            // for the sibling multiplier, we need to only apply it to horizontal containers ie thread containers, so need to have the parent node in here

            node.width = Math.floor(Math.min(Math.min(totalChildWidth, adjustedWidthLimit) + paddingUnit, adjustedWidthLimit)); // was supposed to be this, but somewhere  we have an issue with outer containers on thread containre
            // node.width = Math.min(totalChildWidth, adjustedWidthLimit) + paddingUnit; // original code - was supposed to be this, but somewhere  we have an issue with outer containers on thread containre

            break;
        case 'content.chat.message.user':
        case 'content.chat.message.assistant':
        case 'content.chat.message.document':
        case 'content.chat.message.website_fulltext':
        case 'content.chat.message.website_summary':
            node.width = Math.min(standardWidth, adjustedWidthLimit);
            break;
        default:
            console.warn(`Unknown node type: ${node.type}`);
            node.width = standardWidth;
    }
};