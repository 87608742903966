import React, { useCallback, useState, memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ResultSiteInfo from '../Search/ResultNode/ResultSiteInfo';
import xmlFormat from 'xml-formatter';
import { useLeafFunctions } from '../../../contexts/ChatContext';

import TopActionBar from './TopActionBar';

const MessageWrapper = styled(Box)`
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 6px;
  border: 1px solid #555;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 100%;
  transition: all 0.1s;
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(Typography)`
  font-size: 1rem;
  margin: 0;
  padding: 0;
  & a {
    color: rgb(140, 180, 250);
    text-decoration: none;
  }
  & .url {
    color: rgb(150, 150, 150);
    font-size: 0.8rem;
    margin-top: 4px;
  }
`;

const Snippet = styled(Typography)`
  margin: 0;
  padding: 0;
  color: rgb(190, 190, 200);
`;

const Content = styled(Box)`
  margin: 10px 0;
  padding: 0;
  color: #d6d6dc;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.4;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
`;

const LoadingIndicator = styled(CircularProgress)`
  margin-right: 5px;
`;

const Checkmark = styled(CheckCircleIcon)`
  margin-right: 5px;
  color: green;
`;

const ErrorIconStyled = styled(ErrorIcon)`
  margin-right: 5px;
  color: red;
`;

const QuestionIcon = styled(HelpOutlineIcon)`
  margin-right: 5px;
  color: orange;
`;

const ChatSearchResultNode = memo(({ node }) => {
    const { deleteNode, updateNode } = useLeafFunctions();

    const [expanded, setExpanded] = useState(false);

    const isContentNull = node.cleaned_text_is_null ?? true;
    const isContentShort = node.n_words ? node.n_words < 100 : true;

    const handleDeleteMessage = useCallback(async (event) => {
        event.stopPropagation();
        if (!node || !node.node_id) {
            console.error('Cannot delete: Invalid node');
            return;
        }
        try {
            await deleteNode(node.node_id);
        } catch (error) {
            console.error('Failed to delete node:', error);
        }
    }, [deleteNode, node]);

    // const handleMessageClick = useCallback((event) => {
    //     event.stopPropagation();
    //     selectNode(node.node_id);
    // }, [selectNode, node.node_id]);

    const getJsonData = useCallback(() => {
        return JSON.stringify(node, null, 2);
    }, [node]);

    const formatXml = useCallback((xml) => {
        try {
            return xmlFormat(xml, {
                indentation: '  ',
                filter: (node) => node.type !== 'Comment',
                collapseContent: true,
                lineSeparator: '\n',
            });
        } catch (error) {
            console.error('Error formatting XML:', error);
            return xml;
        }
    }, []);

    const memoizedTopActionBar = useMemo(() => (
        <TopActionBar onDelete={handleDeleteMessage} getJsonData={getJsonData} />
    ), [handleDeleteMessage, getJsonData]);

    return (
        <MessageWrapper>
            <MessageHeader>
                <ResultSiteInfo result={node} />
                {memoizedTopActionBar}
            </MessageHeader>
            <Title gutterBottom>
                <a href={node.url} target="_blank" rel="noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: node.title }}></a>
                <div className="url">{node.url}</div>
            </Title>
            <Box display="flex" alignItems="center">
                {node.isLoading ? (
                    <LoadingIndicator size={20} />
                ) : isContentNull ? (
                    <Tooltip title="Website processing failed- some sites don't allow bots">
                        <ErrorIconStyled />
                    </Tooltip>
                ) : isContentShort ? (
                    <Tooltip title="Website text seems too short- might have pulled incomplete text">
                        <QuestionIcon />
                    </Tooltip>
                ) : (
                    <Tooltip title="Website loaded successfully.">
                        <Checkmark />
                    </Tooltip>
                )}
                <Snippet dangerouslySetInnerHTML={{ __html: node.snippet }} />
            </Box>
            {expanded && (
                <Content>
                    {formatXml(node.content)}
                </Content>
            )}
        </MessageWrapper>
    );
});

ChatSearchResultNode.displayName = 'ChatSearchResultNode';

export default ChatSearchResultNode;