import React, { useMemo, useState, useEffect } from 'react';
import { Box, Chip, Tooltip } from '@mui/material';

import BoostIndicatorTag from './BoostIndicatorTag';
import RankIndicatorTag from './RankIndicatorTag';

const RankingDetailsChip = ({ result }) => {

    const totalAdjustment = result.ranking.total_rank_adjustment;

    const isPositiveBoost = totalAdjustment > 0;
    const isNegativeBoost = totalAdjustment < 0;


    // TODO: figure out why totalAdjustment is null

    const tooltipText = (
        <>
            {isPositiveBoost ? `Boosting result by ${totalAdjustment.toFixed(1)}` :
                isNegativeBoost ? `Downranking result by ${Math.abs(totalAdjustment).toFixed(1)}` : 'No boost applied'}
            <br />
            Total rank adjustment: {totalAdjustment.toFixed(1)}
            {/* {`Final rank = ${result.ranking.base_global_rank.toFixed(1)}  = ${result.ranking.net_global_rank}`} */}
        </>
    );

    return (
        <Tooltip title={tooltipText} placement="right">

            <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                backgroundColor: '#dfe0ed', borderRadius: '6px', width: 'fit-content', padding: '0px 4px 0px 2px', gap: '8px',
                height: '1.0rem'
            }}>
                {Math.abs(totalAdjustment) > 0 && <BoostIndicatorTag result={result} />}
                {/* <BoostIndicatorTag attrs={result.attrs} /> */}
                <RankIndicatorTag ranking={result.ranking} />
            </Box>
        </Tooltip>
    );
};

export default RankingDetailsChip;