import React, { useState, useMemo, memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Box, Checkbox, Typography, IconButton } from '@mui/material';
import { Folder, InsertDriveFile, ArrowRight, ArrowDropDown } from '@mui/icons-material';

const ExplorerWrapper = styled(Box)`
  background-color: #1e1e1e;
  border-radius: 4px;
  padding: 8px;
  max-height: 800px;
  overflow-y: auto;
`;

const FileItem = styled(Box)`
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ExpandButton = styled(IconButton)`
  padding: 0;
  color: #d6d6dc;
  visibility: ${props => props.isDirectory ? 'visible' : 'hidden'};
`;

const FileExplorer = memo(({ files, searchTerm, onSelect, onDeselect, selectedNodes, selectAllChildren }) => {
    console.log('Rendering FileExplorer');
    const [expandedDirs, setExpandedDirs] = useState(new Set());

    const toggleDir = (nodeId) => {
        setExpandedDirs(prev => {
            const newSet = new Set(prev);
            if (newSet.has(nodeId)) {
                newSet.delete(nodeId);
            } else {
                newSet.add(nodeId);
            }
            return newSet;
        });
    };

    const isNodeSelected = useCallback((file) => {
        if (file.type === 'content.chat.filtered_collection') {
            const allChildren = selectAllChildren(file);
            return Array.from(allChildren).every(childId => selectedNodes.has(childId));
        }
        return selectedNodes.has(file.node_id);
    }, [selectedNodes, selectAllChildren]);

    const getNodeDisplayName = (node) => {
        return node.directoryName || node.fileName || node.title || 'Unnamed Item';
    };

    const renderFile = (file, depth = 0) => {
        const isSelected = isNodeSelected(file);
        const name = getNodeDisplayName(file);
        const isVisible = name.toLowerCase().includes(searchTerm.toLowerCase());

        if (!isVisible) return null;

        const isDirectory = file.type === 'content.chat.filtered_collection' || file.type === 'content.chat.collection';
        const isExpanded = expandedDirs.has(file.node_id);

        return (
            <React.Fragment key={file.node_id}>
                <FileItem style={{ paddingLeft: `${depth * 16}px` }}>
                    <ExpandButton
                        onClick={() => toggleDir(file.node_id)}
                        size="small"
                        isDirectory={isDirectory}
                    >
                        {isDirectory ? (isExpanded ? <ArrowDropDown /> : <ArrowRight />) : <ArrowRight />}
                    </ExpandButton>
                    <Checkbox
                        checked={isSelected}
                        onChange={() => isSelected ? onDeselect(file) : onSelect(file)}
                        sx={{ color: '#d6d6dc' }}
                    />
                    {isDirectory ? (
                        <Folder sx={{ color: '#d6d6dc', marginRight: '8px' }} />
                    ) : (
                        <InsertDriveFile sx={{ color: '#d6d6dc', marginRight: '8px' }} />
                    )}
                    <Typography color="#d6d6dc">{name}</Typography>
                </FileItem>
                {isDirectory &&
                    isExpanded &&
                    (file.unfilteredChildren || file.children) &&
                    Array.from((file.unfilteredChildren || file.children).values()).map((childFile) =>
                        renderFile(childFile, depth + 1)
                    )}
            </React.Fragment>
        );
    };

    const filteredFiles = useMemo(() => {
        return Array.from(files.values()).filter(file => {
            const name = getNodeDisplayName(file);
            return name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [files, searchTerm]);

    return (
        <ExplorerWrapper>
            {filteredFiles.length > 0 ? (
                filteredFiles.map((file) => renderFile(file))
            ) : (
                <Typography color="#d6d6dc">No files to display</Typography>
            )}
        </ExplorerWrapper>
    );
});

export default FileExplorer;