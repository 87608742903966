export const fetchWebsiteChatMessage = async (url) => {
    try {
        const fetchUrl = `${process.env.REACT_APP_WEBSITE_DATA_FETCHER_BASE_URL}/website_data_fetcher/api/fetch_website_data`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ url }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseData = await response.json();
        const websiteText = responseData.cleaned_text;
        if (websiteText === null) {
            console.log(`Website text is null: ${responseData}`);
        }

        const websiteContextPrefix = `[The user attached a website]\n`;
        const { cleaned_text, author, links, is_accessible_for_free, n_words, title, article_fields } = responseData;

        return {
            content: websiteContextPrefix + cleaned_text,
            cleaned_text_is_null: cleaned_text === null,
            author: author,
            links: links,
            is_accessible_for_free: is_accessible_for_free,
            n_words: n_words,
            article_fields: article_fields,
        };
    } catch (error) {
        console.error("Failed to fetch website data:", error);
        throw error;
    }
};