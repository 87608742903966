import React, { useContext, memo, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton, Modal, Typography, TextField } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

import { useLeafFunctions } from '../../../contexts/ChatContext';
import TopActionBar from './TopActionBar';
import CodeBlock from './CodeBlock';

// TODO: use MDX

const NodeWrapper = styled(Box)`
  max-width: 100%;
  will-change: ${props => props.isLoading ? 'contents, height' : 'auto'};
  transform: translateZ(0);
  transition: all 0.1s;
`;

const MessageWrapper = styled(Box)`
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 6px;
  border: 1px solid #555;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;


  &:hover .edit-icon {
    visibility: visible;
  }

  &:hover .delete-button {
    visibility: visible;
  }

  transition: all 0.1s;

`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Role = styled.div`
  font-size: 0.9em;
  font-weight: bold;
`;

const Content = styled.div`
  font-size: 1.1em;
  color: #d6d6dc;
  word-wrap: break-word;
  overflow-wrap: break-word;
  transition: all 0.1s;
  p {
    margin: 0.5rem 0;
  }

  pre {
    margin: 0;
    overflow-x: auto;
    max-width: 100%;
    transition: all 0.1s;
  }

  code {
    background-color: #1e1e1e;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    transition: all 0.1s;
  }

  p code {
    display: inline;
  }
`;

const EditInput = styled(TextField)`
  background-color: #1e1e1e;
  color: #d6d6dc;
  margin-top: 10px;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: #555;
    }
    &:hover fieldset {
      border-color: #777;
    }
    &.Mui-focused fieldset {
      border-color: #888;
    }
  }

  .MuiInputBase-input {
    color: #d6d6dc;
  }
`;

const EditButtons = styled(Box)`
  margin-top: 10px;
`;


const AssistantMessageNode = memo(({ node, isVisible = true }) => {
  const { updateNode, deleteNode } = useLeafFunctions();
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(node.content);


  const getJsonData = useCallback(() => {
    return JSON.stringify(node, null, 2);
  }, [node]);

  const handleDeleteMessage = useCallback(async (event) => {
    event.stopPropagation();
    if (!node || !node.node_id) {
      console.error('Cannot delete: Invalid node');
      return;
    }
    try {
      await deleteNode(node.node_id);
    } catch (error) {
      console.error('Failed to delete node:', error);
    }
  }, [node, deleteNode]);

  const handleCopyToClipboard = useCallback((event) => {
    event.preventDefault();
    try {
      navigator.clipboard.writeText(String(node.content).replace(/\n$/, ''));
      console.log("Copied to clipboard.");
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  }, [node.content]);

  const handleEditMessage = useCallback(() => {
    setEditedMessage(node.content);
    setIsEditing(true);
  }, [node.content]);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setEditedMessage(node.content);
  }, [node.content]);

  const handleUpdateMessage = useCallback(() => {
    if (editedMessage.trim()) {
      updateNode(node.node_id, { content: editedMessage });
      setIsEditing(false);
    }
  }, [node.node_id, editedMessage, updateNode]);

  const memoizedContent = useMemo(() => {
    if (!node.content || typeof node.content !== 'string') {
      return null;
    }

    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        components={{
          code: ({ node, inline, className, children, ...props }) => {
            if (inline) {
              return <code className={className} {...props}>{children}</code>;
            }
            return <CodeBlock rawContent={String(children)} className={className} isVisible={isVisible} />;
          },
        }}
      >
        {node.content}
      </ReactMarkdown>
    );
  }, [node.content, isVisible]);

  const memoizedTopActionBar = useMemo(() => (
    <TopActionBar
      onCopy={handleCopyToClipboard}
      onDelete={handleDeleteMessage}
      onEdit={handleEditMessage}
      getJsonData={getJsonData}
    />
  ), [handleCopyToClipboard, handleDeleteMessage, handleEditMessage, getJsonData]);

  if (!node.content) {
    return null;
  }

  return (
    <NodeWrapper isLoading={node.isLoading}>
      <MessageWrapper>
        <MessageHeader>
          <Role>Stickshift Bot</Role>
          {memoizedTopActionBar}
        </MessageHeader>
        <Content>
          {isEditing ? (
            <>
              <EditInput
                multiline
                maxRows={20}
                fullWidth
                variant="outlined"
                value={editedMessage}
                onChange={(e) => setEditedMessage(e.target.value)}
              />
              <EditButtons>
                <IconButton onClick={handleUpdateMessage} sx={{ color: '#4dff88', fontSize: '1.0rem' }}>
                  Save
                </IconButton>
                <IconButton onClick={handleCancelEdit} sx={{ color: '#ff4d4d', fontSize: '1.0rem' }}>
                  Cancel
                </IconButton>
              </EditButtons>
            </>
          ) : (
            memoizedContent
          )}
        </Content>
      </MessageWrapper>
    </NodeWrapper>
  );
}, (prevProps, nextProps) => {
  return prevProps.node.content === nextProps.node.content &&
    prevProps.isVisible === nextProps.isVisible &&
    prevProps.node.width === nextProps.node.width;
});

AssistantMessageNode.displayName = 'AssistantMessageNode';

export default AssistantMessageNode;