import React, { useState, useContext, useEffect } from 'react';
import { Box, Collapse, Button, Typography, Switch, FormControlLabel, Modal, Paper } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import styled from '@emotion/styled';
import { SearchResultsContext } from '../../../contexts/SearchResultsContext';
import { FeatureFlagsContext } from '../../../contexts/FeatureFlagsContext';
import { UIContext } from '../../../contexts/UIContext';
import DebuggingPromptModal from '../../common/DebuggingPromptModal';

import ScienceIcon from '@mui/icons-material/Science';

const StyledButton = styled(Button)`
    color: #fff;
    text-transform: none;
`;

const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledPaper = styled(Paper)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.palette.background.paper};
    padding: ${({ theme }) => theme.spacing(2)};
    box-shadow: ${({ theme }) => theme.shadows[5]};
    overflow: auto;
    max-height: 90%;
    max-width: 90%;
`;

const ForbiddenComponent = () => {
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [debuggingModalOpen, setDebuggingModalOpen] = useState(false);

    const { meta, rankAdjustmentWeights, receipt } = useContext(SearchResultsContext);
    const { featureFlags } = useContext(FeatureFlagsContext);

    const { isFounderModeEnabled, setIsFounderModeEnabled } = useContext(UIContext);
    const [isLocalFounderModeEnabled, setIsLocalFounderModeEnabled] = useState(isFounderModeEnabled);


    useEffect(() => {
        setIsLocalFounderModeEnabled(isFounderModeEnabled);
    }, [isFounderModeEnabled]);

    const handleExpandClick = () => {
        setOpen(!open);
    };

    const handleFounderModeChange = (event) => {
        setIsLocalFounderModeEnabled(event.target.checked);
        setIsFounderModeEnabled(event.target.checked);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleOpenDebuggingModal = () => {
        setDebuggingModalOpen(true);
    };

    const handleCloseDebuggingModal = () => {
        setDebuggingModalOpen(false);
    };

    return (
        <Box sx={{ color: '#fff', borderRadius: 2, boxSizing: 'border-box', paddingLeft: '1rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <ScienceIcon sx={{ marginRight: '0.5rem', fontSize: '1.25rem' }} />
                <Typography variant="h6" sx={{ fontSize: '0.95rem' }} color="textPrimary">Forbidden</Typography>
                <StyledButton
                    startIcon={open ? <ExpandLess /> : <ExpandMore />}
                    onClick={handleExpandClick}
                >
                </StyledButton>
            </Box>

            {/* Items in component */}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: '1rem' }}>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={isLocalFounderModeEnabled}
                                onChange={handleFounderModeChange}
                                color="primary"
                            />
                        }
                        label="Founder Mode"
                    />
                    {isFounderModeEnabled && (
                        <Typography sx={{ mt: 2, color: 'error.main' }}>
                            Janky, incomplete features will be enabled. Nothing is guaranteed to work.
                        </Typography>
                    )}
                    {featureFlags.viewMetaButton && (
                        <StyledButton onClick={handleOpenModal}>
                            Metadata
                        </StyledButton>
                    )}

                    {isFounderModeEnabled && (
                        <StyledButton onClick={handleOpenDebuggingModal}>
                            Debugging Prompt
                        </StyledButton>
                    )}
                </Box>
            </Collapse>

            <StyledModal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="meta-modal-title"
                aria-describedby="meta-modal-description"
            >
                <StyledPaper>
                    <Typography id="meta-modal-title" variant="h6" component="h2">
                        Metadata
                    </Typography>
                    <pre id="meta-modal-description">
                        {JSON.stringify(rankAdjustmentWeights, null, 2)}
                        <br />
                        {receipt.replace('\n', '<br/>')}
                        <br />
                        {JSON.stringify(meta, null, 2)}
                    </pre>
                </StyledPaper>
            </StyledModal>

            <DebuggingPromptModal
                open={debuggingModalOpen}
                onClose={handleCloseDebuggingModal}
            />
        </Box>
    );
};

export default ForbiddenComponent;