import React from 'react';
import styled from '@emotion/styled';

const StyledFollowupButton = styled.button`
  cursor: pointer;
  margin-bottom: 6px;
  width: auto;
  padding: 0.2rem;
  font-size: 1rem;
  border: 1px solid #555;
  background-color: transparent;
  outline: none;
  border-radius: 4px;
  color: rgb(190, 190, 200);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -12px;
    border-top: 1px solid #4a90e2;
    width: 12px;
    height: 1px;
  }

  &:hover {
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    border-left: 1px solid #4a90e2;
    height: 50%;
  }

  &:last-child::after {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    border-left: 1px solid #4a90e2;
    height: 50%;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    border: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.6);
  `}
`;

const FollowupButton = ({ followup, isSelected, onClick, onMouseEnter, onMouseLeave }) => (
  <StyledFollowupButton
    className="followup-button"
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    isSelected={isSelected}
  >
    {followup.displayText || followup.content}
  </StyledFollowupButton>
);

export default FollowupButton;