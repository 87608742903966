import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, } from '@mui/material';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';

import * as NodeConstructors from './graph/utils/nodeConstructors';

import { UIContext } from './contexts/UIContext';
import { ChatContext } from './contexts/ChatContext';
import { FeatureFlagsContext } from './contexts/FeatureFlagsContext';
import { SearchResultsContext } from './contexts/SearchResultsContext';
import { SearchQueryContext } from './contexts/SearchQueryContext';
import { useUserSessionContext } from './contexts/UserSessionContext';
import { useChatContext } from './contexts/ChatContext';

import ChatComponent from './components/Chat/ChatComponent';
import WebSearchRoot from './components/WebSearch/WebSearchRoot';
import MainPageHeader from './components/MainPage/MainPageHeader';



const MainPage = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode') || 'search_plus_chat'; // Use 'search_plus_chat' as default if mode is not set
    const urlQuery = searchParams.get('query');
    const prevUrlQueryRef = useRef('');
    const urlInitialPrompt = searchParams.get('initialPrompt');

    const { isSessionReady } = useUserSessionContext();
    const { isChatReady, addUserMessageToThread, getExistingThreadId } = useChatContext();
    const { featureFlags } = useContext(FeatureFlagsContext);
    const { isMobile, isSearchOpen, setIsSearchOpen, isChatOpen, setIsChatOpen } = useContext(UIContext);

    const { fetchResultsAndRerank } = useContext(SearchResultsContext);
    const { query, setQuery } = useContext(SearchQueryContext);
    const { initialPromptRef } = useContext(ChatContext);
    const prevInitialPromptRef = useRef('');

    const { updateQueryFromUrl } = useContext(SearchQueryContext);


    const getInitialPanelSizes = (mode, isMobile) => {
        if (mode === 'search') {
            return { searchSize: 100, chatSize: 0 };
        } else if (mode === 'chat') {
            return { searchSize: 0, chatSize: 100 };
        } else if (mode === 'search_plus_chat') {
            return { searchSize: 50, chatSize: 50 };
        } else {
            if (isMobile) {
                return { searchSize: 100, chatSize: 0 };
            } else {
                return { searchSize: 50, chatSize: 50 };
            }
        }
    };
    const { searchSize: initialSearchSize, chatSize: initialChatSize } = getInitialPanelSizes(mode, isMobile);
    const [searchSize, setSearchSize] = useState(initialSearchSize);
    const [chatSize, setChatSize] = useState(initialChatSize);

    // // TODO: the UIContext is kinda fucky now, maybe we should put all the toggling/sizing logic in there, or at least some of it. Right now its kinda distributed across here and the context and MainPageHeader

    const searchPanelRef = useRef(null);
    const chatPanelRef = useRef(null);

    useEffect(() => {
        if (urlInitialPrompt && urlInitialPrompt !== initialPromptRef.current) {
            initialPromptRef.current = urlInitialPrompt;
        }
    }, [urlInitialPrompt]);

    useEffect(() => {
        if (isSessionReady && isChatReady && mode === 'chat' && initialPromptRef.current && initialPromptRef.current !== prevInitialPromptRef.current) {
            const threadId = getExistingThreadId();
            if (threadId) {
                const message = NodeConstructors.createUserMessage(threadId, initialPromptRef.current);
                console.log('adding user message to thread: ', threadId, message);
                addUserMessageToThread(threadId, message);
                prevInitialPromptRef.current = initialPromptRef.current;
            }
        }
    }, [isSessionReady, isChatReady, mode, initialPromptRef.current, getExistingThreadId, addUserMessageToThread]);

    useEffect(() => {
        if (urlQuery && mode.includes('search') && urlQuery !== prevUrlQueryRef.current) {
            updateQueryFromUrl(urlQuery);
            fetchResultsAndRerank(urlQuery);

            // if (mode === 'search_plus_chat') {
            //     const threadId = getExistingThreadId();
            //     const message = NodeConstructors.createUserMessage(threadId, urlQuery);
            //     console.log('Search+chat: adding message: ', message, 'to thread: ', threadId)
            //     addUserMessageToThread(threadId, message);
            // }

            prevUrlQueryRef.current = urlQuery;
        }
    }, [urlQuery, mode, updateQueryFromUrl, fetchResultsAndRerank, addUserMessageToThread]);

    // Update panel sizes when searchSize or chatSize change
    useEffect(() => {
        searchPanelRef.current.resize(searchSize);
        chatPanelRef.current.resize(chatSize);
        setIsSearchOpen(searchSize > 0);
        setIsChatOpen(chatSize > 0);
    }, [searchSize, chatSize, isMobile]);

    const animatePanel = (currentSize, targetSize, setSize, setOppositeSize, setOpen) => {
        const duration = 200; // Animation duration in milliseconds
        const startTime = Date.now();

        const animate = () => {
            const elapsedTime = Date.now() - startTime;
            const progress = Math.min(elapsedTime / duration, 1);

            const size = currentSize + (targetSize - currentSize) * progress;
            setSize(size);
            setOppositeSize(100 - size);

            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setOpen(targetSize !== 0);
            }
        };

        requestAnimationFrame(animate);
    };

    const handleSearchToggle = () => {
        console.log('MainPage - handleSearchToggle - searchSize:', searchSize);
        const newMode = searchSize === 0 ? (isMobile ? 'search' : 'search_plus_chat') : 'chat';
        const targetSize = searchSize === 0 ? (isMobile ? 100 : 50) : 0;
        animatePanel(searchSize, targetSize, setSearchSize, setChatSize, setIsSearchOpen);
        history.replace(`/engine?query=${encodeURIComponent(query)}&mode=${newMode}`);
    };

    const handleChatToggle = () => {
        console.log('MainPage - handleChatToggle - chatSize:', chatSize);
        const newMode = chatSize === 0 ? (isMobile ? 'chat' : 'search_plus_chat') : 'search';
        const targetSize = chatSize === 0 ? (isMobile ? 100 : 50) : 0;
        animatePanel(chatSize, targetSize, setChatSize, setSearchSize, setIsChatOpen);
        history.replace(`/engine?query=${encodeURIComponent(query)}&mode=${newMode}`);
    };

    const handlePanelResize = (sizes) => {
        setSearchSize(sizes[0]);
        setChatSize(sizes[1]);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <MainPageHeader
                handleSearchToggle={handleSearchToggle}
                handleChatToggle={handleChatToggle}
            />
            <PanelGroup direction="horizontal" style={{ height: '100%' }} onResize={handlePanelResize}>
                <Panel ref={searchPanelRef} size={searchSize} >
                    {searchSize > 0 && (
                        <Box sx={{ overflowY: 'auto', height: '100%' }}>
                            <WebSearchRoot />
                        </Box>
                    )}
                </Panel>
                {searchSize > 0 && chatSize > 0 && (
                    <PanelResizeHandle
                        style={{
                            cursor: 'col-resize',
                            '&:hover': {
                                cursor: 'col-resize',
                            },
                        }}
                    />
                )}
                <Panel ref={chatPanelRef} size={chatSize}>
                    {chatSize > 0 && (
                        <Box sx={{ height: '100%', overflowY: 'auto' }}>
                            <ChatComponent />
                        </Box>
                    )}
                </Panel>
            </PanelGroup>

        </Box>
    );
};

MainPage.displayName = 'MainPage';

export default MainPage;