import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${props => props.backgroundColor || '#4a90e2'};
  color: ${props => props.color || 'white'};
  border: none;
  border-radius: 4px;
  padding: 0px 6px 0px 0px;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    filter: brightness(90%);
  }
  min-height: 22px;
`;

const IconWrapper = styled.span`
  margin-right: 2px;
  font-size: 18px;
`;

const CursorActionButton = ({ icon, text, color, backgroundColor, onClick }) => {
  return (
    <StyledButton color={color} backgroundColor={backgroundColor} onClick={onClick}>
      <IconWrapper className="material-symbols-outlined">{icon}</IconWrapper>
      {text}
    </StyledButton>
  );
};

CursorActionButton.displayName = 'CursorActionButton';

export default CursorActionButton;
