import { useEffect, useCallback, useState } from 'react';

export function useTextSelection(ref) {
    const [selectedText, setSelectedText] = useState('');
    const [selectionRect, setSelectionRect] = useState(null);

    const saveSelection = useCallback(() => {
        if (!ref.current) return;

        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (ref.current.contains(range.commonAncestorContainer)) {
                const selectedContent = range.toString();
                setSelectedText(selectedContent);
                setSelectionRect(range.getBoundingClientRect());
            } else {
                setSelectedText('');
                setSelectionRect(null);
            }
        } else {
            setSelectedText('');
            setSelectionRect(null);
        }
    }, [ref]);

    const restoreSelection = useCallback(() => {
        if (!ref.current || !selectedText) {
            return;
        }

        const textContent = ref.current.textContent;
        const start = textContent.indexOf(selectedText);
        if (start >= 0) {
            const end = start + selectedText.length;
            const range = document.createRange();
            const textNodes = getTextNodes(ref.current);
            let startNode, startOffset, endNode, endOffset;

            let charIndex = 0;
            for (let i = 0; i < textNodes.length; i++) {
                const node = textNodes[i];
                const nextCharIndex = charIndex + node.length;

                if (!startNode && start >= charIndex && start < nextCharIndex) {
                    startNode = node;
                    startOffset = start - charIndex;
                }

                if (!endNode && end >= charIndex && end <= nextCharIndex) {
                    endNode = node;
                    endOffset = end - charIndex;
                    break;
                }

                charIndex = nextCharIndex;
            }

            if (startNode && endNode) {
                range.setStart(startNode, startOffset);
                range.setEnd(endNode, endOffset);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }, [ref, selectedText]);

    useEffect(() => {
        if (!ref.current) return;

        const handleSelectionChange = () => {
            requestAnimationFrame(saveSelection);
        };

        document.addEventListener('selectionchange', handleSelectionChange);
        ref.current.addEventListener('mouseup', handleSelectionChange);
        ref.current.addEventListener('keyup', handleSelectionChange);

        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
            if (ref.current) {
                ref.current.removeEventListener('mouseup', handleSelectionChange);
                ref.current.removeEventListener('keyup', handleSelectionChange);
            }
        };
    }, [ref, saveSelection]);

    return { saveSelection, restoreSelection, selectedText, selectionRect };
}

function getTextNodes(node) {
    const textNodes = [];
    if (node.nodeType === Node.TEXT_NODE) {
        textNodes.push(node);
    } else {
        const children = node.childNodes;
        for (let i = 0; i < children.length; i++) {
            textNodes.push(...getTextNodes(children[i]));
        }
    }
    return textNodes;
}