import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import { Flipper, Flipped } from 'react-flip-toolkit';

import { useSearchResults } from '../../hooks/useSearchResults';
import { SearchQueryContext } from '../../contexts/SearchQueryContext';

import ContentNode from '../StickshiftNode/ContentNode';



const ResultListContainer = styled.div`
  max-width: ${(props) => props.maxWidth};

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const SkeletonWrapper = styled(Skeleton)`
  margin: 1%;
  width: 500px;
  height: 150px;
`;

const MessageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  height: 150px;
  margin: 50px auto 0;
  border-radius: 6px;
  background-color: rgb(240, 240, 244);
  box-shadow: 0 0 2px 2px rgb(10, 10, 10, 0.06);
  padding: 1.5rem;
`;

const MessageText = styled(Typography)`
  color: #484b51;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  line-height: 1.5;
  margin-right: 1rem;
  white-space: pre-line;
`;

const CheemsImage = styled.img`
  width: 130px;
  height: 130px;
  object-fit: contain;
`;

const ResultList = ({ maxWidth }) => {
  const { results, isLoading } = useSearchResults();
  //const { isLoading } = useContext(SearchResultsContext);
  const { query } = useContext(SearchQueryContext);

  let messageContent;

  if ((!results || results.length === 0) && query && !isLoading) {
    messageContent = 'Oops... ye that did not work';
  } else if ((!results || results.length === 0) && (!query || query.length == 0) && !isLoading) {
    messageContent = 'Search engine is open -\nenter a query';
  }

  // if (messageContent) {
  //   return (
  //     <MessageBox>
  //       {messageContent}
  //     </MessageBox>
  //   );
  // }


  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          margin: '0.5%',
        }}
      >
        <SkeletonWrapper animation="wave" />
        <SkeletonWrapper animation="wave" />
        <SkeletonWrapper animation="wave" />
      </Box>
    );
  }

  else if ((!results || results.length === 0) && !isLoading) {
    return (
      <MessageBox>
        <MessageText variant="body1">{messageContent}</MessageText>
        <CheemsImage src="/cheems.jpg" alt="Cheems" />

      </MessageBox>
    );
  }


  return (
    <ResultListContainer maxWidth={maxWidth}>
      <Box
        sx={{
          borderRadius: '12px',
          backgroundColor: 'rgb(232, 232, 236)',
          padding: '0.5rem',
        }}
      >
        <Flipper
          spring={{ stiffness: 260, damping: 26 }}
          staggerConfig={{ reverse: true, speed: 0.01 }}
          flipKey={results.map((cluster) => `${cluster.cluster_num}`).join('_')}
        >
          {results.map((cluster) => {
            const id = `${cluster.cluster_num}_c`;
            return (
              <Flipped key={id} flipId={id}>
                <div>
                  <ContentNode
                    node={cluster.children.length === 1 ? cluster.children[0] : cluster}
                  />
                </div>
              </Flipped>
            );
          })}
        </Flipper>
      </Box>
    </ResultListContainer>
  );
};

export default ResultList;