import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Section = styled(Box)`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  transition: all 0.1s;
`;

const SectionHeader = styled(Box)`
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition: all 0.1s;
`;

const SectionContent = styled(Box)`
  padding: 0 0.5rem;
  width: 100%;
  transition: all 0.1s;
`;

const CollapsibleSection = React.memo(({ title, children, openByDefault = false }) => {
  const [isOpen, setIsOpen] = useState(openByDefault);

  return (
    <Section>
      <SectionHeader onClick={() => setIsOpen(!isOpen)}>
        {title}
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </SectionHeader>
      {isOpen && <SectionContent>{children}</SectionContent>}
    </Section>
  );
});

export default CollapsibleSection;