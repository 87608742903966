import React, { useState, useContext } from 'react';
import { Typography, Slider, TextField, MenuItem, Select } from '@mui/material';
import { LLMConfigContext } from '../../../contexts/LLMConfigContext';
import { UIContext } from '../../../contexts/UIContext';
import CollapsibleSection from './CollapsibleSection';

const LLMControls = React.memo(() => {
    const { updateLLMConfig, llmConfigs } = useContext(LLMConfigContext);
    // const { isFounderModeEnabled } = useContext(UIContext);
    const [temperature, setTemperature] = useState(llmConfigs['default_chat'].temperature);
    const [model, setModel] = useState(llmConfigs['default_chat'].model);

    const handleTemperatureChange = (event, newValue) => {
        setTemperature(newValue);
        updateLLMConfig('default_chat', { temperature: newValue });
    };

    const handleModelChange = (event) => {
        const newValue = event.target.value;
        setModel(newValue);
        updateLLMConfig('default_chat', { model: newValue });
    };

    return (
        <CollapsibleSection title="LLM controls" openByDefault={true}>
            <Typography>Temperature: {temperature.toFixed(2)}</Typography>
            <Slider
                value={temperature}
                min={0}
                max={1.5}
                step={0.02}
                onChange={handleTemperatureChange}
                sx={{
                    color: '#4a90e2', // Using the blue color from ChatNode
                    '& .MuiSlider-thumb': {
                        '&:hover, &.Mui-focusVisible': {
                            boxShadow: '0 0 0 8px rgba(74, 144, 226, 0.16)',
                        },
                    },
                    '& .MuiSlider-rail': {
                        opacity: 0.32,
                    },
                }}
            />
            {temperature > 1.0 && (
                <Typography color="error" variant="caption" sx={{ mt: 1, mb: 1 }}>
                    Temperature {'>'}1.0 will generate gibberish with OpenAI models, and will straight up not work with Anthropic models.
                </Typography>
            )}

            <Typography>Model</Typography>
            <Select
                value={model}
                onChange={handleModelChange}
                fullWidth
                sx={{
                    '& .MuiSelect-select': {
                        padding: '8px 14px',
                    },
                }}>

                <MenuItem value="claude-3-haiku-20240307">claude-3-haiku</MenuItem>
                <MenuItem value="claude-3-sonnet-20240229">claude-3-sonnet</MenuItem>
                <MenuItem value="claude-3-opus-20240229">claude-3-opus</MenuItem>
                <MenuItem value="claude-3-5-sonnet-20240620">claude-3.5-sonnet</MenuItem>
                <MenuItem value="gpt-4-turbo">gpt-4-turbo</MenuItem>
                <MenuItem value="gpt-4o">gpt-4o</MenuItem>
                <MenuItem value="gpt-4-turbo-2024-04-09">gpt-4-turbo-2024-04-09</MenuItem>
            </Select>
        </CollapsibleSection>
    );
});

export default LLMControls;