import React, { useContext, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Drawer, SwipeableDrawer, Icon } from '@mui/material';
import styled from '@emotion/styled';
import { ToggleButton } from '@mui/material';

import LogoComponent from './Header/LogoComponent';
import MainSearchBar from './Header/MainSearchBar';
import ResultList from './ResultList';
import LeftSidebar from './LeftSidebar/LeftSidebar';
import MiddleActionBar from './MiddleActionBar/MiddleActionBar';

import { UIContext } from '../../contexts/UIContext';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';


const SIDEBAR_WIDTH_DESKTOP = '270px';
const SIDEBAR_WIDTH_MOBILE = '70.5vw';


const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  maxWidth: 800px;
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;


const SidebarContainer = styled(Box)`
  width: ${({ isMobile, isSidebarOpen }) =>
    isSidebarOpen ? (isMobile ? SIDEBAR_WIDTH_MOBILE : SIDEBAR_WIDTH_DESKTOP) : '0'};
  transition: width 0.3s ease-in-out;
  overflow: hidden;
`;

const ResultListContainer = styled(Box)`
  flex-grow: 1;
  justify-content: flex-start;
  margin: 5px;
  position: relative;
  overflow-y: scroll;
  width: ${({ isMobile, isSidebarOpen }) =>
    isMobile
      ? '100%'
      : `calc(100% - ${isSidebarOpen ? SIDEBAR_WIDTH_DESKTOP : 0})`};
`;


const MainContent = styled(Box)`
display: flex;
width: 100%;
// height: ${({ isMobile }) => (isMobile ? 'auto' : 'calc(100vh - 55px)')}; // 55px for MainPageHeader
height: calc(100vh - 55px); // 55px for MainPageHeader
`;

const WebSearchRoot = () => {
  const { isSidebarOpen, toggleSidebar, isMobile, setIsSidebarOpen } = useContext(UIContext);
  const { featureFlags } = useContext(FeatureFlagsContext);
  const containerRef = useRef(null);

  const resultListMaxWidth = isMobile ? '100%' : '800px';

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      if (width < 900) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [setIsSidebarOpen]);



  return (
    <Container ref={containerRef}>
      <Content>


        <MainContent isMobile={isMobile}>
          {isMobile ? (
            <SwipeableDrawer
              variant="temporary"
              open={isSidebarOpen}
              onClose={toggleSidebar}
              onOpen={toggleSidebar}
              sx={{
                '& .MuiDrawer-paper': {
                  width: '70.5vw',
                  bgcolor: 'transparent',
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: '6px',
                  position: 'relative',
                },
              }}
            >
              <LeftSidebar />
            </SwipeableDrawer>
          ) : (
            <SidebarContainer isSidebarOpen={isSidebarOpen}>
              <LeftSidebar />
            </SidebarContainer>
          )}

          <ResultListContainer isMobile={isMobile}>
            <ResultList maxWidth={resultListMaxWidth} />
          </ResultListContainer>

          {!isMobile && featureFlags.middleActionBar && <MiddleActionBar />}
        </MainContent>
      </Content>
    </Container>
  );
};

export default WebSearchRoot;