import React from 'react';
import { Box } from '@mui/system';

const SidebarPanel = ({ children }) => {
  return (
    <Box sx={{
      bgcolor: 'background.default',
      opacity: 0.95,
      borderRadius: '6px',
      // borderColor: 'rgba(36, 36, 36, 0.95)', // Adjust the background color as needed

      padding: '1%',
      color: 'text.primary',
      marginBottom: '1%',
      width: '100%',
      maxWidth: '100%',

    }}>
      {children}
    </Box>
  );
};

export default SidebarPanel;