import React, { useState, memo } from 'react';
import styled from '@emotion/styled';
import { IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const EditIconWrapper = styled(EditIcon)`
  margin-left: 10px;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;

  &:hover {
    color: #4dff88;
  }
`;

const DeleteButtonWrapper = styled(CloseIcon)`
  color: #fff;
  width: 1.35rem;
  height: 1.35rem;

  &:hover {
    color: #ff4d4d;
  }
`;

const CopyButtonIcon = styled(FileCopyIcon)`
  width: 1.15rem;
  height: 1.15rem;
  margin-top: -1px;
`;

const CopyButton = styled(IconButton)`
  position: relative;
  width: 1.35rem;
  height: 1.35rem;
  color: #fff;
  &:hover {
    color: #4dff88;
  }
`;

const ViewJsonButton = styled(IconButton)`
  position: relative;
  width: 1.35rem;
  height: 1.35rem;
  color: #fff;
  &:hover {
    color: #4dff88;
  }
`;

const KebabIcon = styled(MoreVertIcon)`
  width: 1.15rem;
  height: 1.15rem;
  margin-top: -1px;
`;

const JsonDialog = styled(Dialog)`
  .MuiDialog-paper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
  }
`;

const JsonDialogContent = styled(DialogContent)`
  flex-grow: 1;
  overflow-y: auto;
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

const TopActionBar = memo(({ onEdit, onCopy, onDelete, getJsonData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    handleClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <ActionButtons>
      {onEdit && <EditIconWrapper className="edit-icon" onClick={onEdit} />}
      {onCopy && (
        <CopyButton className="copy-button" onClick={onCopy}>
          <CopyButtonIcon />
        </CopyButton>
      )}
      {onDelete && <DeleteButtonWrapper className="delete-button" onClick={onDelete} />}
      {getJsonData && (
        <>
          <ViewJsonButton className="view-json-button" onClick={handleClick}>
            <KebabIcon />
          </ViewJsonButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenDialog}>View JSON</MenuItem>
          </Menu>
          <JsonDialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{"JSON Data"}</DialogTitle>
            <JsonDialogContent>
              <DialogContentText component="div">
                <pre>{JSON.stringify(getJsonData(), null, 2)}</pre>
              </DialogContentText>
            </JsonDialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </JsonDialog>
        </>
      )}
    </ActionButtons>
  );
});

export default TopActionBar;