import React, { useState } from 'react';
import { Button } from '@mui/material';
import DebuggingPromptModal from './DebuggingPromptModal';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, isDebuggingModalOpen: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error:', error);
        console.error('Error Info:', errorInfo);
        // You can log the error to an error reporting service like Sentry or Rollbar
    }

    handleOpenDebuggingModal = () => {
        this.setState({ isDebuggingModalOpen: true });
    };

    handleCloseDebuggingModal = () => {
        this.setState({ isDebuggingModalOpen: false });
    };

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h2>Oops! Something went wrong.</h2>
                    <p>Please contact me at humans@stickshift.ai because I'm probably not aware of this issue</p>
                    <p>For the time being, try refreshing, and hopefully your session is saved under the "previous sessions" icon on the header</p>

                    {/* <Button variant="contained" color="primary" onClick={this.handleOpenDebuggingModal}>
                        Debug (DO NOT PRESS )
                    </Button> */}
                    <DebuggingPromptModal
                        open={this.state.isDebuggingModalOpen}
                        onClose={this.handleCloseDebuggingModal}
                    />
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;