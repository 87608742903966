// FilteredCollectionNode.js

import React, { useState, useCallback, useMemo, memo } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Close, ExpandMore, ExpandLess } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import SearchBar from './SearchBar';
import FileExplorer from './FileExplorer';
import ContentNode from '../../ContentNode';
import * as NodeUtils from '../../../../lib/nodeUtils';
import { useCollectionFunctions } from '../../../../contexts/ChatContext';

const Container = styled(Box)`
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #555;
  border-radius: 8px;
  background-color: #2a2a2a;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const SelectedChildrenContainer = styled(Box)`
  margin-bottom: 16px;
  max-height: 1000px;
  overflow-y: auto;
  padding: 10px;
  
`;

const NoFilesSelected = styled(Typography)`
  color: #999;
  font-style: italic;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const AnimatedContainer = styled(motion.div)`
  overflow: hidden;
`;

export const FilteredCollectionNode = memo(({ node }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedNodes, setSelectedNodes] = useState(new Map());
    const { addNode, deleteNode, updateNode } = useCollectionFunctions();

    const itemCount = useMemo(() => {
        return node.children ? node.children.size : 0;
    }, [node.children]);

    const handleSearchChange = useCallback((event) => {
        setSearchTerm(event.target.value);
        console.log('Search term updated:', event.target.value);
    }, []);

    const selectAllChildren = useCallback((file) => {
        const allChildren = new Set();

        const collectChildren = (node) => {
            allChildren.add(node.node_id);
            if (node.unfilteredChildren) {
                for (const child of node.unfilteredChildren.values()) {
                    collectChildren(child);
                }
            }
        };

        collectChildren(file);
        return allChildren;
    }, []);

    const handleSelectNode = useCallback((selectedNode) => {
        // console.log('Selecting node:', selectedNode);
        setSelectedNodes(prev => {
            const newMap = new Map(prev);
            const markNodeAndChildren = (node) => {
                newMap.set(node.node_id, true);
                if (node.children) {
                    for (const child of node.children.values()) {
                        markNodeAndChildren(child);
                    }
                }
                if (node.unfilteredChildren) {
                    for (const child of node.unfilteredChildren.values()) {
                        markNodeAndChildren(child);
                    }
                }
            };
            markNodeAndChildren(selectedNode);
            return newMap;
        });
        const clonedNode = NodeUtils.cloneNodeTree(selectedNode, node.node_id);
        addNode(node.node_id, clonedNode);
    }, [node.node_id, addNode]);

    const handleDeselectNode = useCallback((deselectedNode) => {
        // console.log('Deselecting node:', deselectedNode);
        setSelectedNodes(prev => {
            const newMap = new Map(prev);
            if (deselectedNode.type === 'content.chat.filtered_collection') {
                const allChildren = selectAllChildren(deselectedNode);
                allChildren.forEach(childId => newMap.delete(childId));
            } else {
                newMap.delete(deselectedNode.node_id);
            }
            return newMap;
        });
        deleteNode(deselectedNode.node_id);
    }, [deleteNode, selectAllChildren]);

    const handleToggleCollapse = useCallback(() => {
        // console.log('Toggling collapse state');
        updateNode(node.node_id, { isCollapsed: !node.isCollapsed });
    }, [node.node_id, node.isCollapsed, updateNode]);

    const handleDelete = useCallback(() => {
        deleteNode(node.node_id);
    }, [deleteNode, node.node_id]);

    const handleSearchFocus = useCallback(() => {
        if (node.isCollapsed) {
            updateNode(node.node_id, { isCollapsed: false });
        }
    }, [node.isCollapsed, node.node_id, updateNode]);

    const selectedChildren = useMemo(() => {
        return Array.from(node.children.values());
    }, [node.children]);

    const renderSelectedChildren = useCallback((childNode) => {
        // console.log('Rendering selected child node:', childNode);
        if (childNode.type === 'content.chat.filtered_collection') {
            return (
                <Box key={childNode.node_id} mb={1}>
                    <Typography variant="subtitle1">{childNode.directoryName}</Typography>
                    {Array.from(childNode.children.values()).map(renderSelectedChildren)}
                </Box>
            );
        } else {
            return (
                <ContentNode
                    key={childNode.node_id}
                    node={childNode}
                />
            );
        }
    }, []);

    return (
        <Container>
            <Header>
                <Typography variant="h6">
                    {node.directoryName} ({itemCount} item{itemCount !== 1 ? 's' : ''} selected)
                </Typography>
                <Box>
                    <Tooltip title="Delete">
                        <StyledIconButton onClick={handleDelete} size="small">
                            <Close sx={{ color: 'white', '&:hover': { color: 'red' } }} />
                        </StyledIconButton>
                    </Tooltip>
                    <Tooltip title={node.isCollapsed ? 'Expand' : 'Collapse'}>
                        <IconButton onClick={handleToggleCollapse}>
                            {node.isCollapsed ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Header>
            <AnimatePresence>
                {!node.isCollapsed && (
                    <AnimatedContainer
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        <SelectedChildrenContainer>
                            {selectedChildren.length > 0 ? (
                                selectedChildren.map(renderSelectedChildren)
                            ) : (
                                <NoFilesSelected>No files selected</NoFilesSelected>
                            )}
                        </SelectedChildrenContainer>
                    </AnimatedContainer>
                )}
            </AnimatePresence>
            <SearchBar
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
            />
            <AnimatePresence>
                {!node.isCollapsed && (
                    <AnimatedContainer
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.1 }}
                    >
                        <FileExplorer
                            files={node.unfilteredChildren}
                            searchTerm={searchTerm}
                            onSelect={handleSelectNode}
                            onDeselect={handleDeselectNode}
                            selectedNodes={selectedNodes}
                            selectAllChildren={selectAllChildren}
                        />
                    </AnimatedContainer>
                )}
            </AnimatePresence>
        </Container>
    );
});

export default FilteredCollectionNode;