//scores:
// base_rank
// net_global_rank (includes just pointwise adjustments)
// net_within_cluster_rank (includes listwise adjustments too, is equivalent to net_global_rank before listwise adjustments are applied)

function constructDummyClusterFromList(results) {
    return {
        type: 'content.search.cluster',
        children: results,
        ranking: {
            attrs: {},
            ranking_scores: {},
        },
        is_collapsed: false,
        position: 0,
    };
}


export function rerank(node, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth = 0) {
    /**
     * Recursively reranks a node (cluster or leaf result) and its children.
     * Applies pointwise and listwise reranking adjustments, updates global ranks,
     * and handles delivery aspects for cluster nodes.
     */
    let isOldFormat = false;
    if (!node.children && node.type != 'content.search.result') {
        isOldFormat = true;
        node = constructDummyClusterFromList(node);
    }

    // Update pointwise ranks (ie global ranks) for entire subtree
    node = updatePointwiseRanks(node, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth + 1);

    // Update listwise ranks (ie within cluster ranks) for entire subtree
    node = updateListwiseRanks(node, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth + 1);

    // Update delivery params like position, is_collapsed, etc
    const looserSlack = 8;
    const tighterSlack = 6;
    const maxInitialFullyVisibleResults = 5;
    const maxInitialTotalVisibleResults = 8;
    node = updateVisibilityParams(node, looserSlack, tighterSlack, maxInitialFullyVisibleResults, maxInitialTotalVisibleResults);

    node = updateNodePositions(node);

    if (isOldFormat) {
        return node.children;
    } else {
        return node;
    }
}

function updatePointwiseRanks(node, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth) {
    if (node.type === 'content.search.result') {
        // update leaf
        node = updateLeafResultPointwise(node, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth);
        return node;

    } else if (node.type === 'content.search.cluster') {
        // recursive traverse postorder
        const updatedChildren = node.children.map(child => updatePointwiseRanks(child, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth + 1));
        node.children = updatedChildren.slice().sort((a, b) => b.ranking.ranking_scores.net_global_rank - a.ranking.ranking_scores.net_global_rank);
        const clusterDataFromChildren = getClusterDataFromChildren(node);
        node.ranking.ranking_scores = {
            base_rank: clusterDataFromChildren.base_rank,
            net_global_rank: clusterDataFromChildren.net_global_rank,
            net_within_cluster_rank: clusterDataFromChildren.net_global_rank,
        }
        node.matrix_index = clusterDataFromChildren.matrix_index;



        // TODO: update cluster ranking data (base_rank, net_global_rank, matrix index, etc)
        // We will get the net_within_cluster_rank later once the pointwise adjustments are done for the entire tree, since listwise only depends on the pointwise scores of siblings

        return node;
    } else {
        // error invalid type
        throw new Error('Invalid node type in rerank');

    }
}

function updateLeafResultPointwise(result, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth) {
    const pointwiseAttrConfigs = rerankingAttrConfigs["pointwise"];

    // compute each rank adjustment term based on the attr, weight, and config
    const globalPointwiseRankAdjustments = Object.entries(pointwiseAttrConfigs).reduce((acc, [attr, config]) => {
        const adjustmentWeight = adjustmentWeights.pointwise[attr];
        if (adjustmentWeight === 0 || adjustmentWeight === null || adjustmentWeight === undefined || isNaN(adjustmentWeight)) {
            acc[attr] = 0;
            return acc;
        }

        const pageAttrValue = result.ranking.attrs[attr];
        let isAttrMissing = false;
        let nullBetterThanLow = false;

        if (config.hasOwnProperty('presenceAttr') && config.hasOwnProperty('nullBetterThanLow')) {
            const presenceAttr = config.presenceAttr;
            nullBetterThanLow = config.nullBetterThanLow;
            isAttrMissing = (result.ranking.attrs[presenceAttr] === 0) || (pageAttrValue === null);
        } else {
            isAttrMissing = (pageAttrValue === null);
        }

        if (isAttrMissing) {
            acc[attr] = nullBetterThanLow ? 0 : -Math.abs(adjustmentWeight);
        } else {
            acc[attr] = adjustmentWeight * pageAttrValue;
        }

        return acc;
    }, {});

    const totalRankAdjustment = Object.values(globalPointwiseRankAdjustments).reduce((a, b) => {
        if (b === null || isNaN(b)) b = 0;
        return a + b;
    }, 0);

    return {
        ...result,
        ranking: {
            ...result.ranking,
            rank_adjustments: {
                ...result.ranking.rank_adjustments,
                pointwise_global: globalPointwiseRankAdjustments,
            },
            total_rank_adjustment: totalRankAdjustment,
            ranking_scores: {
                // TODO: note that we're deleting any existing fields here, need to ensure this is okay. But I think we don't need the stale net_within_cluster_rank, etc
                base_rank: result.ranking.ranking_scores.base_rank,
                net_global_rank: result.ranking.ranking_scores.base_rank + totalRankAdjustment,
                net_within_cluster_rank: result.ranking.ranking_scores.base_rank + totalRankAdjustment,
            }
        }
    };
}

function getClusterDataFromChildren(cluster) {

    const childWithMaxGlobalRank = cluster.children.reduce((max, child) => max.ranking.ranking_scores.net_global_rank > child.ranking.ranking_scores.net_global_rank ? max : child, cluster.children[0]);
    return {
        base_rank: childWithMaxGlobalRank.ranking.ranking_scores.base_rank,
        net_global_rank: childWithMaxGlobalRank.ranking.ranking_scores.net_global_rank,
        net_within_cluster_rank: childWithMaxGlobalRank.ranking.ranking_scores.net_within_cluster_rank,
        matrix_index: getMatrixIndex(cluster),
    };

}

function getMatrixIndex(node) {
    // Assumes node is leaf search result or pre-sorted cluster
    if (node.matrix_index) {
        return node.matrix_index;
    } else if (node.children) {
        // TODO: not 100% sure this is the best logic. Bold of us to assume we correctly sorted it ahead of time, also this requires us to go by rank instead of representativeness. However, results in a cluster are already similar, so maybe this is fine
        return node.children[0].matrix_index;
    } else {
        throw new Error('Node must have children or matrix_index');
    }

}

function updateListwiseRanks(node, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth) {
    if (node.type === 'content.search.cluster') {

        // Apply listwise ranking adjustments to the cluster's children (both leaf search results and child clusters)
        // TODO: maybe this should accept/return entire node?
        node = rerankWithinClusterListwise(node, meta, adjustmentWeights, featureFlags);

        // // Update the cluster's children with the listwise-ranked children
        // node.children = rankedChildren;

        // Recursively update listwise ranks for each child cluster
        node.children = node.children.map(child => {
            if (child.type === 'content.search.cluster') {
                return updateListwiseRanks(child, meta, adjustmentWeights, rerankingAttrConfigs, featureFlags, depth + 1);
            }
            return child;
        });


        return node;
    } else if (node.type === 'content.search.result') {
        // No need to update listwise ranks for leaf search results here, as they are handled by rerankWithinClusterListwise
        return node;
    } else {
        // Error: invalid node type
        throw new Error('Invalid node type in updateListwiseRanks');
    }
}


function rerankWithinClusterListwise(cluster, meta, adjustmentWeights, featureFlags) {
    if (!featureFlags.withinClusterMMRRankAdjuster) return cluster;

    const updatedChildren = applyWithinClusterMMR(cluster.children, adjustmentWeights, meta.similarity_matrix);
    return {
        ...cluster,
        children: updatedChildren,

    };
}

export function applyWithinClusterMMR(clusterChildren, adjustmentWeights, similarity_matrix) {
    const updatedClusterChildren = clusterChildren.map((node, index) => {
        const maxSimilarity = computeMaxSimilarity(node, index, clusterChildren, similarity_matrix);
        const minScale = 0.85;
        const maxScale = 1.0;
        let scaledMMRScore;
        if (index === 0) {
            scaledMMRScore = 0;
        } else {
            scaledMMRScore = -((maxSimilarity - minScale) / (maxScale - minScale));
        }
        const mmrAdjustment = adjustmentWeights.listwise_within_cluster['within_cluster_mmr_score'] * scaledMMRScore;

        // Create a new node object
        const updatedNode = { ...node };

        // Update ranking properties
        updatedNode.ranking = { ...node.ranking };
        updatedNode.ranking.attrs = { ...node.ranking.attrs };
        updatedNode.ranking.attrs.within_cluster_mmr_max_similarity = maxSimilarity;
        updatedNode.ranking.attrs.scaled_mmr_score = scaledMMRScore;

        // Initialize rank_adjustments and its sub-properties if not already initialized
        updatedNode.ranking.rank_adjustments = updatedNode.ranking.rank_adjustments || {};
        updatedNode.ranking.rank_adjustments.listwise_within_cluster = updatedNode.ranking.rank_adjustments.listwise_within_cluster || {};

        // Apply MMR adjustment
        updatedNode.ranking.rank_adjustments.listwise_within_cluster.within_cluster_mmr_score = mmrAdjustment;
        updatedNode.ranking.ranking_scores = { ...node.ranking.ranking_scores };
        updatedNode.ranking.ranking_scores.net_within_cluster_rank = node.ranking.ranking_scores.net_within_cluster_rank + mmrAdjustment;
        updatedNode.ranking.total_rank_adjustment = node.ranking.total_rank_adjustment + mmrAdjustment;

        return updatedNode;
    });

    // Sort the updated children based on the new net_within_cluster_rank
    return updatedClusterChildren.sort((a, b) => b.ranking.ranking_scores.net_within_cluster_rank - a.ranking.ranking_scores.net_within_cluster_rank);
}


function computeMaxSimilarity(node, index, children, similarity_matrix) {
    if (index === 0) {
        return 0; // No similarity adjustment for the first result
    }

    const similarityScores = children.slice(0, index).map((previousNode) => {
        const previousNodeIndex = previousNode.matrix_index;
        const currentNodeIndex = node.matrix_index;
        return similarity_matrix[previousNodeIndex][currentNodeIndex];
    });

    const maxSimilarity = Math.max(...similarityScores);
    return maxSimilarity;
}



function updateVisibilityParams(node, looserSlack, tighterSlack, maxInitialFullyVisibleResults, maxInitialTotalVisibleResults) {
    if (node.type === 'content.search.cluster') {
        // Update visibility params for the current cluster
        const updatedChildren = updateVisibleResultsPerCluster(node.children, looserSlack, tighterSlack, maxInitialFullyVisibleResults, maxInitialTotalVisibleResults);
        node.children = updatedChildren;

        // Recursively update visibility params for each child cluster
        node.children = node.children.map(child => {
            if (child.type === 'content.search.cluster') {
                return updateVisibilityParams(child, looserSlack, tighterSlack, maxInitialFullyVisibleResults, maxInitialTotalVisibleResults);
            }
            return child;
        });
    }
    return node;
}

function updateVisibleResultsPerCluster(clusterChildren, looserSlack, tighterSlack, maxInitialFullyVisibleResults, maxInitialTotalVisibleResults) {
    return clusterChildren.map((cluster, index) => {
        const nextCluster = clusterChildren[index + 1];

        let fullyVisibleResults, totalVisibleResults;

        if (nextCluster) {
            fullyVisibleResults = computeVisibleResultsPerCluster(cluster, nextCluster, tighterSlack);
            totalVisibleResults = computeVisibleResultsPerCluster(cluster, nextCluster, looserSlack);
        } else {
            // If there is no next cluster, make all results in the current cluster fully visible
            fullyVisibleResults = cluster.children ? cluster.children.length : 0;
            totalVisibleResults = cluster.children ? cluster.children.length : 0;
        }

        return {
            ...cluster,
            numFullyVisibleResults: Math.min(fullyVisibleResults, maxInitialFullyVisibleResults),
            numTotalVisibleResults: Math.min(totalVisibleResults, maxInitialTotalVisibleResults),
            numVisibleResults: totalVisibleResults,
        };
    });
}

function computeVisibleResultsPerCluster(cluster, nextCluster, slack) {
    if (!cluster.children || cluster.children.length === 1) {
        return cluster.children ? 1 : 0;
    }
    let numVisible = 0;
    const nextClusterMaxScore = nextCluster && nextCluster.children ? Math.max(...nextCluster.children.map(child => child.ranking.ranking_scores.net_within_cluster_rank)) : -Infinity;

    for (const result of cluster.children) {
        if (result.ranking.ranking_scores.net_within_cluster_rank + slack > nextClusterMaxScore) {
            numVisible++;
        } else {
            break;
        }
    }

    return numVisible;
}

function updateNodePositions(node) {
    // Flatten the tree to get all leaf nodes (search results)
    const leafNodes = getLeafNodes(node);

    // Sort the leaf nodes based on net_within_cluster_rank in descending order
    leafNodes.sort((a, b) => b.ranking.ranking_scores.net_within_cluster_rank - a.ranking.ranking_scores.net_within_cluster_rank);

    // Assign positions to leaf nodes based on their order
    leafNodes.forEach((leafNode, index) => {
        updatePosition(leafNode, index + 1);
    });

    // Assign positions to cluster nodes based on the highest position of their children
    updateClusterPositions(node);

    return node;
}

function getLeafNodes(node) {
    if (node.type === 'content.search.result') {
        return [node];
    } else if (node.type === 'content.search.cluster') {
        return node.children.flatMap(getLeafNodes);
    }
    return [];
}

function updateClusterPositions(node) {
    if (node.type === 'content.search.cluster') {
        const highestChildPosition = Math.max(...node.children.map(child => child.ranking.position.current));
        updatePosition(node, highestChildPosition);

        node.children.forEach(updateClusterPositions);
    }
}

function updatePosition(node, newPosition) {
    const oldPosition = node.ranking.position ? node.ranking.position.current : null;
    node.ranking.position = {
        previous: oldPosition,
        current: newPosition,
    };
}