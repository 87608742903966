import React, { useState, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Close, ExpandMore, ExpandLess } from '@mui/icons-material';
import ContentNode from '../ContentNode';
import ThreadBottomActionBar from './Thread/ThreadBottomActionBar';
import { useCollectionFunctions } from '../../../contexts/ChatContext';

const HEADER_HEIGHT = '1.5rem';

const CollectionWrapper = styled(Box)`
  border: 1px solid #555;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #2a2a2a;
  max-width: 100%;
  position: relative;
  transition: all 0.1s;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT};
  padding: 0 8px;
  background-color: #1e1e1e;
  border-bottom: 1px solid #555;
  border-radius: 8px 8px 0 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #d6d6dc;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const ItemCount = styled.span`
  margin-left: 8px;
  font-size: 0.8rem;
  color: #999;
`;

const ChildrenContainer = styled(Box)`
  padding: 8px 8px 8px 8px;
  overflow-y: auto;
  min-height: 100px;
  align-items: center;
  background-color: #121212;
`;

const ToggleBar = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEADER_HEIGHT};
  background-color: #1e1e1e;
  border-top: 1px solid #555;
  cursor: pointer;
`;

const CollectionNode = React.memo(({ node }) => {
  const { updateNode, deleteNode } = useCollectionFunctions();
  const [isCollapsed, setIsCollapsed] = useState(node.isCollapsed);
  const [height, setHeight] = useState(isCollapsed ? '250px' : '1500px');

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
    setHeight(isCollapsed ? '1500px' : '250px');
    updateNode(node.node_id, { isCollapsed: !isCollapsed });
  }, [isCollapsed, updateNode, node.node_id]);

  const handleDelete = useCallback(() => {
    deleteNode(node.node_id);
  }, [deleteNode, node.node_id]);

  const itemCount = useMemo(() => node.children.size, [node.children.size]);

  const memoizedTitle = useMemo(() => (
    <Title>
      {node.title || 'Untitled Collection'}
      <ItemCount>({itemCount} items)</ItemCount>
    </Title>
  ), [node.title, itemCount]);

  const memoizedDeleteButton = useMemo(() => (
    <Tooltip title="Delete">
      <StyledIconButton onClick={handleDelete} size="small">
        <Close sx={{ color: 'white', '&:hover': { color: 'red' } }} />
      </StyledIconButton>
    </Tooltip>
  ), [handleDelete]);

  const memoizedChildren = useMemo(() => (
    Array.from(node.children.values()).map((child) => (
      <ContentNode key={child.node_id} node={child} />
    ))
  ), [node.children]);

  return (
    <CollectionWrapper>
      <Header>
        {memoizedTitle}
        <Box>
          {memoizedDeleteButton}
        </Box>
      </Header>
      <ChildrenContainer style={{ maxHeight: height }}>
        {memoizedChildren}
      </ChildrenContainer>
      <ThreadBottomActionBar threadId={node.node_id} bottom="24px" right="2px" />
      <ToggleBar onClick={toggleCollapse}>
        {isCollapsed ? <ExpandMore /> : <ExpandLess />}
      </ToggleBar>
    </CollectionWrapper>
  );
}, (prevProps, nextProps) => {
  return prevProps.node.width === nextProps.node.width &&
    prevProps.children?.length === nextProps.children?.length &&
    prevProps.node.isCollapsed === nextProps.node.isCollapsed &&
    prevProps.node.children === nextProps.node.children &&
    prevProps.node.title === nextProps.node.title;
});

CollectionNode.displayName = 'CollectionNode';

export default CollectionNode;