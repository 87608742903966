import React, { createContext, useEffect, useRef, useState, useCallback } from 'react';
import { patchConsole, unpatchConsole } from '../lib/monkeyLogger';

const DebuggingContext = createContext();

const DO_MONKEY_PATCH = false;

/*
Attempt from Claude for layout profiling data with HOC: (https://claude.ai/chat/5b34629b-1f76-40a4-a302-fbd160080bf8)

We've added a forceLayoutCapture function to the DebuggingContext that dispatches a custom event.
The LayoutProfilerHOC now listens for this custom event and captures layout info when it's triggered.
The DebuggingPromptModal uses a useEffect hook to call forceLayoutCapture when it opens.

This approach ensures that:

Layout profiling is triggered when the modal opens.
All components wrapped with withLayoutProfiler will update their layout information.
The modal will display the most up-to-date layout profiles.
*/

function DebuggingProvider({ children }) {
    console.log('DebuggingProvider rendering');
    const logs = useRef([]);
    const debuggingData = useRef({
        diff: '',
        changed_files: [],
        referencing_files: [],
        prompt: '',
    });

    const [layoutProfiles, setLayoutProfiles] = useState({});


    useEffect(() => {
        loadDebuggingData();
    }, []);

    const addLayoutProfile = useCallback((componentId, profile) => {
        setLayoutProfiles(prevProfiles => ({
            ...prevProfiles,
            [componentId]: profile,
        }));
    }, []);

    const forceLayoutCapture = useCallback(() => {
        // This function will be called by components wrapped with withLayoutProfiler
        const event = new Event('forceLayoutCapture');
        window.dispatchEvent(event);
    }, []);


    const addLog = (level, message, component, logCount) => {
        const lastLog = logs.current[logs.current.length - 1];
        if (lastLog && lastLog.message === message) {
            // If the last log message has the same content, increment the count
            const updatedLastLog = {
                ...lastLog,
                count: (lastLog.count || 1) + 1,
            };
            logs.current = [...logs.current.slice(0, -1), updatedLastLog];
        } else {
            // If the message is different, add a new log entry
            const newLog = {
                level,
                timestamp: new Date().toISOString(),
                message,
                component,
                logCount,
                count: 1,
            };
            logs.current = [...logs.current, newLog];
        }
    };

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG_MODE === 'true' && DO_MONKEY_PATCH) {
            const handleConsoleMessage = (level, message, component, logCount) => {
                addLog(level, message, component, logCount);
            };

            patchConsole(handleConsoleMessage);

            return () => {
                unpatchConsole();
            };
        }
    }, []);


    const addDebuggingData = (data) => {
        debuggingData.current = {
            ...debuggingData.current,
            ...data,
        };
    };


    const loadDebuggingData = async () => {
        if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEBUG_MODE === 'true') {
            try {
                const stickshiftModData = await import('./stickshift_mod.json').catch(() => ({
                    default: {},
                }));
                addDebuggingData(stickshiftModData.default);
            } catch (error) {
                console.warn('Failed to load stickshift_mod.json:', error);
            }
        }
    };

    return (
        <DebuggingContext.Provider value={{
            logs: logs.current,
            debuggingData: debuggingData.current,
            layoutProfiles,
            addLayoutProfile,
            forceLayoutCapture,
            addLog,
            addDebuggingData,
        }}>
            {children}
        </DebuggingContext.Provider>
    );
}

export { DebuggingContext, DebuggingProvider };