import React, { useState, useContext } from 'react';
import { Box, Collapse, Button, Typography } from '@mui/material';
import { ExpandMore, ExpandLess, ReceiptLong } from '@mui/icons-material';
import styled from '@emotion/styled';

import { SearchResultsContext } from '../../../contexts/SearchResultsContext';

const StyledButton = styled(Button)`
  color: #fff;
  text-transform: none;
`;

const ReceiptContent = styled(Typography)`
  word-wrap: break-word;
  width: 100%;
  max-width: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 100%;
  max-width: 100%;
`;

const Receipt = () => {
  const [open, setOpen] = useState(false);
  const { receipt } = useContext(SearchResultsContext);

  const handleExpandClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100%',
        color: '#fff',
        borderRadius: 2,
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <ReceiptLong sx={{ margin: '0rem 1rem 0rem 0.85rem', fontSize: '0.95rem' }} />
        <Typography variant="h6" sx={{ fontSize: '0.95rem' }} color="textPrimary">
          Receipt
        </Typography>
        <StyledButton
          startIcon={open ? <ExpandLess sx={{ margin: '0.5rem !important' }} /> : <ExpandMore sx={{ margin: '0.5rem !important' }} />}
          onClick={handleExpandClick}
          sx={{ marginLeft: '7rem', width: '2rem', minWidth: '1rem' }}
        />
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit sx={{ margin: '1rem', maxWidth: '100%' }}>
        <ReceiptContent variant="body2" color="inherit">
          {JSON.stringify(receipt, null, 2)}
        </ReceiptContent>
      </Collapse>
    </Box>
  );
};

export default Receipt;