import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Tooltip, Radio, RadioGroup, FormControl, FormControlLabel, Button, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import ScienceIcon from '@mui/icons-material/Science';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CategoryIcon from '@mui/icons-material/Category';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import RankAdjuster from './RankAdjuster';
import LLMJudgementRankAdjuster from './LLMJudgementRankAdjuster';
import SubqueryRankAdjuster from './SubqueryRankAdjuster';
import ProbeRankAdjuster from './ProbeRankAdjuster';
import SidebarPanel from '../SidebarPanel';
import { FeatureFlagsContext } from '../../../../contexts/FeatureFlagsContext';
import { SearchResultsContext } from '../../../../contexts/SearchResultsContext';


import { debounce } from 'lodash';

const PresetsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: '0.25rem 1rem',
}));

const ResetButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '0.9rem',
}));

const RadioLabel = styled(Typography)({
    fontSize: '0.9rem',
    padding: '0.1rem',
});

const RadioButtonStyled = styled(Radio)({
    color: '#636468',
    padding: '0.25rem',
    '& .MuiSvgIcon-root': {
        fontSize: '0.8rem',
    },
});

const BoostsHeader = styled(Typography)(({ theme }) => ({
    fontSize: '1.2rem',
    fontWeight: '500',
}));

const BoostPanel = () => {
    const { featureFlags } = useContext(FeatureFlagsContext);
    const { setRankAdjustmentWeights, rankAdjusterImpact, rerankingAttrConfigs } = useContext(SearchResultsContext);

    const all_zero_config = Object.entries(rerankingAttrConfigs).reduce((acc, [scoreType, attributes]) => {
        acc[scoreType] = Object.keys(attributes).reduce((scoreTypeAcc, attributeName) => {
            scoreTypeAcc[attributeName] = 0; // Initialize each attribute to zero
            return scoreTypeAcc;
        }, {});
        return acc;
    }, {});

    const [presetConfigurations, setPresetConfigurations] = useState({
        default: {
            pointwise: { ...all_zero_config.pointwise, is_cluster_member: 3 },
            listwise_within_cluster: { ...all_zero_config.listwise_within_cluster, within_cluster_mmr_score: 10 },
        },
        relevance: { ...all_zero_config },
        date_added_positive: {
            pointwise: { ...all_zero_config.pointwise, date_published_score: 500 },
            listwise_within_cluster: { ...all_zero_config.listwise_within_cluster },
        },
        date_added_negative: {
            pointwise: { ...all_zero_config.pointwise, date_published_score: -500 },
            listwise_within_cluster: { ...all_zero_config.listwise_within_cluster },
        },
        custom: {
            pointwise: { ...all_zero_config.pointwise, is_cluster_member: 3 },
            listwise_within_cluster: { ...all_zero_config.listwise_within_cluster, within_cluster_mmr_score: 10 },
        },
    });
    const [localRankAdjustments, setLocalRankAdjustments] = useState(presetConfigurations.default);
    const [preset, setPreset] = useState('default');

    const handleRankAdjusterChange = (type, name, value) => {
        setLocalRankAdjustments(prevState => {
            const updatedRankAdjustments = {
                ...prevState,
                [type]: {
                    ...prevState[type],
                    [name]: value,
                },
            };
            setPresetConfigurations(prevPresetConfigurations => ({
                ...prevPresetConfigurations,
                custom: updatedRankAdjustments,
            }));
            return updatedRankAdjustments;
        });
        setPreset('custom');
    };

    const handleRankAdjusterReset = (type, name) => {
        setLocalRankAdjustments(prevState => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                [name]: 0,
            },
        }));
        setPreset('custom');
    };

    const handleGlobalReset = () => {
        setLocalRankAdjustments(presetConfigurations.relevance);
        setPreset('relevance');
    };

    const handleApplyPreset = (selectedPreset) => {
        setPreset(selectedPreset);
        setLocalRankAdjustments(presetConfigurations[selectedPreset]);
    };

    const updateGlobalRankAdjustments = debounce((localRankAdjustments) => {
        setRankAdjustmentWeights(localRankAdjustments);
    }, 200);

    useEffect(() => {
        updateGlobalRankAdjustments(localRankAdjustments);
    }, [localRankAdjustments, setRankAdjustmentWeights]);

    return (
        <SidebarPanel>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '15px' }}>
                <BoostsHeader variant="h6">Ranking Boosts</BoostsHeader>

                <Tooltip title="Add a positive/negative boost based on a criteria to uprank/downrank results that match">
                    <IconButton>
                        <HelpOutlineIcon sx={{ fontSize: '0.9rem', marginLeft: '2px' }} />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* Add the typography here */}
            <Box sx={{ paddingLeft: '15px', mb: 1 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Positive value = see more
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Negative value = see less
                </Typography>
            </Box>


            {featureFlags.showTotalBoostImpact && (
                <Box sx={{ paddingLeft: '15px', marginBottom: '0.5rem' }}>
                    <Typography sx={{ fontSize: '0.8rem', color: 'rgb(212, 215, 221)' }}>
                        Boost impact: {isNaN(rankAdjusterImpact) ? 0 : parseInt(rankAdjusterImpact * 100)}
                    </Typography>
                </Box>
            )}

            {/* Render the preset menu */}
            <PresetsContainer>
                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>Boost Presets</Typography>
                <FormControl sx={{ paddingLeft: '0.4rem' }}>
                    <RadioGroup value={preset} onChange={(event) => handleApplyPreset(event.target.value)}>
                        <FormControlLabel
                            value="default"
                            control={<RadioButtonStyled />}
                            label={<RadioLabel>Stickshift default</RadioLabel>}
                        />
                        <FormControlLabel
                            value="date_added_positive"
                            control={<RadioButtonStyled />}
                            label={<RadioLabel>Newest first</RadioLabel>}
                        />
                        <FormControlLabel
                            value="date_added_negative"
                            control={<RadioButtonStyled />}
                            label={<RadioLabel>Oldest first</RadioLabel>}
                        />
                        <FormControlLabel
                            value="relevance"
                            control={<RadioButtonStyled />}
                            label={<RadioLabel>No boost</RadioLabel>}
                        />
                        <FormControlLabel
                            value="custom"
                            control={<RadioButtonStyled />}
                            label={<RadioLabel>Custom</RadioLabel>}
                        />
                    </RadioGroup>
                </FormControl>
            </PresetsContainer>

            {/* Render the rank adjusters */}
            <Box>
                {featureFlags.datePublishedRankAdjuster && (
                    <RankAdjuster
                        name="date_published_score"
                        title="Recently published"
                        description="Pages that were published recently"
                        icon={DateRangeIcon}
                        value={localRankAdjustments.pointwise.date_published_score || 0}
                        onChange={(value) => handleRankAdjusterChange('pointwise', 'date_published_score', value)}
                        onReset={() => handleRankAdjusterReset('pointwise', 'date_published_score')}
                    />
                )}

                <RankAdjuster
                    name="literal_score"
                    title="Keyword match"
                    description="Pages that match your exact words"
                    icon={ManageSearchIcon}
                    value={localRankAdjustments.pointwise.literal_score || 0}
                    onChange={(value) => handleRankAdjusterChange('pointwise', 'literal_score', value)}
                    onReset={() => handleRankAdjusterReset('pointwise', 'literal_score')}
                />

                {featureFlags.llmJudgementRankAdjuster && (
                    <LLMJudgementRankAdjuster />
                )}

                {featureFlags.customSubqueryRankAdjuster && (
                    <SubqueryRankAdjuster />
                )}

                {featureFlags.probeRankAdjuster && (
                    <ProbeRankAdjuster />
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2, mb: 1, paddingLeft: '15px' }}>
                    <Typography variant="h6" sx={{ fontSize: '1.2rem' }}>
                        Advanced Boosts
                    </Typography>
                </Box>

                {featureFlags.withinClusterMMRRankAdjuster && (
                    <RankAdjuster
                        name="within_cluster_mmr_score"
                        min={0}
                        max={40}
                        step={2}
                        title="Diversity"
                        description="Results that are different from what came before"
                        icon={ScienceIcon}
                        value={localRankAdjustments.listwise_within_cluster.within_cluster_mmr_score || 0}
                        onChange={(value) => handleRankAdjusterChange('listwise_within_cluster', 'within_cluster_mmr_score', value)}
                        onReset={() => handleRankAdjusterReset('listwise_within_cluster', 'within_cluster_mmr_score')}
                    />
                )}

                {featureFlags.clusteredRankAdjuster && (
                    <RankAdjuster
                        name="is_cluster_member"
                        min={0}
                        max={10}
                        step={1}
                        title="Topic clusters"
                        description="Groups of similar pages"
                        icon={CategoryIcon}
                        value={localRankAdjustments.pointwise.is_cluster_member || 0}
                        onChange={(value) => handleRankAdjusterChange('pointwise', 'is_cluster_member', value)}
                        onReset={() => handleRankAdjusterReset('pointwise', 'is_cluster_member')}
                    />
                )}
            </Box>
        </SidebarPanel>
    );
};

export default BoostPanel;