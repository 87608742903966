import React, { createContext, useContext, useCallback, useRef } from 'react';

const KeyboardContext = createContext(null);

export function KeyboardProvider({ children }) {
    const pressedKeys = useRef(new Set());
    const shortcuts = useRef([]);

    // Function to calculate the specificity of a shortcut
    // More keys and presence of modifier keys increase specificity
    const getSpecificity = (keys) => {
        const modifierKeys = ['Control', 'Alt', 'Shift', 'Meta'];
        return keys.length + keys.filter(k => modifierKeys.includes(k)).length;
    };

    const registerShortcut = useCallback((keys, handler) => {
        const specificity = getSpecificity(keys);

        // Add the new shortcut to our list
        shortcuts.current.push({ keys, handler, specificity });

        // Return a function to unregister this shortcut
        return () => {
            shortcuts.current = shortcuts.current.filter(
                shortcut => shortcut.keys.join(',') !== keys.join(',')
            );
        };
    }, []);

    const handleKeyDown = useCallback((event) => {
        // Add the pressed key to our set of currently pressed keys
        pressedKeys.current.add(event.key.toLowerCase());

        // Find all shortcuts that match the current key combination
        const matchingShortcuts = shortcuts.current.filter(shortcut =>
            shortcut.keys.every(key => pressedKeys.current.has(key.toLowerCase()))
        );

        if (matchingShortcuts.length > 0) {
            event.preventDefault();

            // Find the shortcut with the highest specificity
            const mostSpecificShortcut = matchingShortcuts.reduce((a, b) =>
                a.specificity > b.specificity ? a : b
            );

            // Execute the handler of the most specific shortcut
            mostSpecificShortcut.handler(event);
        }
    }, []);

    const handleKeyUp = useCallback((event) => {
        // Remove the released key from our set of pressed keys
        pressedKeys.current.delete(event.key.toLowerCase());
    }, []);

    // Set up and clean up global event listeners
    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyDown, handleKeyUp]);

    return (
        <KeyboardContext.Provider value={{ registerShortcut }}>
            {children}
        </KeyboardContext.Provider>
    );
}

// Custom hook to use the keyboard context
export function useKeyboard() {
    const context = useContext(KeyboardContext);
    if (!context) {
        throw new Error('useKeyboard must be used within a KeyboardProvider');
    }
    return context;
}