import React from 'react';
import SearchNode from './Search/SearchNode';
import ChatNode from './Chat/ChatNode';
/*
Top level Node switcher component that switches between nodes related to search results and nodes related to chat messages
SearchNode and ChatNode are their own switchers- it's a tree of types, essentially

These nodes are used to render rich structures of search results and chat messages

ref is related to the visibibility intersection observer IIRC
*/
const ContentNode = React.forwardRef(({ node, isVisible = true }, ref) => {
    if (node.type.startsWith('content.search')) {
        return <SearchNode ref={ref} node={node} isVisible={isVisible} />;
    } else if (node.type.startsWith('content.chat')) {
        return <ChatNode ref={ref} node={node} isVisible={isVisible} />;
    } else {
        console.error('Unknown content node type', node.type);
        return <p ref={ref}><strong>Unknown content node type:</strong> {node.type}</p>;
    }
});

export default ContentNode;