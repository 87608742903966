import React, { useCallback, useState, memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton, TextField, Modal, Typography, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import xmlFormat from 'xml-formatter';
import TopActionBar from './TopActionBar';
import ReplayIcon from '@mui/icons-material/Replay';
import CodeBlock from './CodeBlock';
import { useLeafFunctions } from '../../../contexts/ChatContext';

const MessageWrapper = styled(Box)`
  padding: 10px;
  background-color: #1e1e1e;
  border-radius: 6px;
  border: 1px solid #555;
  position: relative;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  transition: all 0.1s;
  &:hover .edit-icon {
    visibility: visible;
  }

  &:hover .delete-button {
    visibility: visible;
  }
`;

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Role = styled.div`
  font-size: 0.9em;
  font-weight: bold;
`;

const Content = styled.div`
  font-size: 1.1em;
  color: #d6d6dc;
  transition: all 0.1s;

  p {
    margin: 0.5rem 0;
  }
`;

const EditInput = styled(TextField)`
  background-color: #1e1e1e;
  color: #d6d6dc;
  margin-top: 10px;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: #555;
    }
    &:hover fieldset {
      border-color: #777;
    }
    &.Mui-focused fieldset {
      border-color: #888;
    }
  }

  .MuiInputBase-input {
    color: #d6d6dc;
  }
`;

const EditButtons = styled(Box)`
  margin-top: 10px;
`;

const ResubmitButton = styled(IconButton)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  opacity: 0.7;
  transition: opacity 0.2s;
  padding: 0;

  &:hover {
    opacity: 1;
  }
`;

const UserMessageNode = memo(({ node }) => {
  const { updateNode, deleteNode, forkAndResubmitMessage } = useLeafFunctions();
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(node.content);
  const type = node.type;

  const getJsonData = useCallback(() => {
    return JSON.stringify(node, null, 2);
  }, [node]);

  const handleEditMessage = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setEditedMessage(node.content);
  }, [node.content]);

  const handleUpdateMessage = useCallback(() => {
    setIsEditing(false);
    if (editedMessage.trim() && editedMessage !== node.content) {
      updateNode(node.node_id, (prevNode) => ({
        ...prevNode,
        content: editedMessage,
        revisions: [...(prevNode.revisions || []), prevNode.content]
      }));
    }
  }, [updateNode, editedMessage, node.content, node.node_id]);

  const handleResubmit = useCallback(async () => {
    const contentToSubmit = isEditing ? editedMessage : node.content;
    await forkAndResubmitMessage(node.node_id, contentToSubmit);
  }, [forkAndResubmitMessage, node.node_id, node.content, isEditing, editedMessage]);

  const handleDeleteMessage = useCallback(() => {
    deleteNode(node.node_id);
  }, [deleteNode, node.node_id]);

  const formatXml = (xml) => {
    return xmlFormat(xml, {
      indentation: '  ',
      filter: (node) => node.type !== 'Comment',
      collapseContent: true,
      lineSeparator: '\n',
    });
  };

  const EditActionButton = ({ iconName, color, onClick, children }) => (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      sx={{
        color: color,
        fontSize: '1.0rem',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
      }}
    >
      <span className="material-symbols-outlined" style={{ marginRight: '4px', fontSize: '1.2rem' }}>
        {iconName}
      </span>
      {children}
    </IconButton>
  );

  const memoizedTopActionBar = useMemo(() => (
    <TopActionBar
      onEdit={handleEditMessage}
      onDelete={handleDeleteMessage}
      getJsonData={getJsonData}
    />
  ), [handleEditMessage, handleDeleteMessage, getJsonData]);

  const memoizedContent = useMemo(() => {
    if (!node.content || typeof node.content !== 'string') {
      return null;
    }

    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        components={{
          code: ({ node, inline, className, children, ...props }) => {
            if (inline) {
              return <code className={className} {...props}>{children}</code>;
            }
            return <CodeBlock rawContent={String(children)} className={className} isVisible={true} />;
          },
        }}
      >
        {node.content}
      </ReactMarkdown>
    );
  }, [node.content]);

  return (
    <MessageWrapper>
      <MessageHeader>
        <Role>You</Role>
        {memoizedTopActionBar}
      </MessageHeader>
      {isEditing ? (
        <>
          <EditInput
            multiline
            maxRows={20}
            fullWidth
            variant="outlined"
            value={editedMessage}
            onChange={(e) => setEditedMessage(e.target.value)}
          />
          <EditButtons>
            <EditActionButton iconName="check" color="#4dff88" onClick={handleUpdateMessage}>
              Save
            </EditActionButton>
            <EditActionButton iconName="cancel" color="#ff4d4d" onClick={handleCancelEdit}>
              Cancel
            </EditActionButton>
            <EditActionButton iconName="subdirectory_arrow_right" color="#4d88ff" onClick={handleResubmit}>
              Resubmit
            </EditActionButton>
          </EditButtons>
        </>
      ) : (
        <>
          <Content>
            {type === 'contextWebsiteText' ? (
              <pre>{formatXml(node.content)}</pre>
            ) : (
              memoizedContent
            )}
          </Content>
        </>
      )}
    </MessageWrapper>
  );
}, (prevProps, nextProps) => {
  return prevProps.node.width === nextProps.node.width &&
    prevProps.node.content === nextProps.node.content;

});

UserMessageNode.displayName = 'UserMessageNode';

export default UserMessageNode;