// components/ui/src/components/GoogleAuthComponent.js
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory
import { AuthContext } from '../contexts/AuthContext';
import { Grid, TextField, Typography, Box } from '@mui/material';
import GoogleSignInButton from './GoogleSignInButton'; // Import the GoogleSignInButton component

const GoogleAuthComponent = () => {
    const history = useHistory(); // Get a reference to the history object
    const { signIn, error } = useContext(AuthContext); // Get the error state
    const [inviteCode, setInviteCode] = useState('');

    const handleInviteCodeChange = (event) => {
        console.log("Invite code input: ", event.target.value);
        setInviteCode(event.target.value);
    };

    const handleSignIn = (googleOAuthResponse, inviteCode) => {
        // console.log("Google OAuth response: ", googleOAuthResponse);
        // console.log("Invite code: ", inviteCode);
        signIn(googleOAuthResponse, inviteCode); // Update the user's information and authentication status

        // Navigate to the /main route
        history.push('/main');
    };

    return (
        <Grid container style={{ minHeight: '100vh', backgroundColor: '#121212' }} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={10} md={8} lg={8} xl={6} style={{ textAlign: 'center' }}>
                <Box display="flex" flexDirection="column" alignItems="center" width="20ch">
                    <img src="/logo.webp" alt="Logo" style={{ maxWidth: '150px', marginBottom: '20px' }} />
                    <Typography variant="h4">Login with google</Typography>
                    <Typography sx={{ marginBottom: '15px' }} >Skip the invite code if you used one already 😎</Typography>
                    <TextField
                        label="Invite Code"
                        variant="outlined"
                        value={inviteCode}
                        onChange={handleInviteCodeChange}
                        style={{ backgroundColor: '#2E2F34', color: 'white', marginBottom: '12px', maxWidth: '20ch' }}
                        fullWidth
                    />
                    {error && <Typography color="error">{error}</Typography>} {/* Display the error message */}
                    <GoogleSignInButton inviteCode={inviteCode} onSignIn={handleSignIn} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default GoogleAuthComponent;