import React, { useCallback, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

import { useUIContext } from '../../../../contexts/UIContext';
import ThreadHeader from './ThreadHeader';
import ThreadContentContainer from './ThreadContentContainer';
import ChatBar from './ChatBar';
import { useCollectionFunctions } from '../../../../contexts/ChatContext';
import ChatDownloadService from '../../../../services/ChatDownloadService';

const ThreadWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}px;
  height: 100%;
  min-height: 300px;
    box-sizing: border-box;
    padding: 1px;
  border: 1px solid #555;
  border-radius: 10px;
  background-color: #1e1e1e;
  transition: background-color 0.1s ease-in-out, border 0.1s ease-in-out, width 0.1s smooth;
  position: relative;
  overflow: visible;
  transform: translateZ(0);

transition: width 0.1s smooth;
  will-change: width, contents;
  transform: translateZ(0);
`;

const ThreadContent = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: visible;
  transition: opacity 0.2s ease-in-out;
  opacity: ${props => props.isResizing ? 0 : 1};
  will-change: opacity;
  transform: translateZ(0);
`;

const ResizePlaceholder = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #888;
  font-style: italic;
  transition: opacity 0.2s ease-in-out;
  opacity: ${props => props.isResizing ? 1 : 0};
  will-change: opacity;
  transform: translateZ(0);
  pointer-events: none;
`;
const ChatBarWrapper = styled(Box)`
  margin: 0 -1px -1px -1px; // Keep negative margin for ChatBar
`;

const ThreadNode = React.memo(({ node }) => {
    const { isResizing, isFounderModeEnabled, isMobile } = useUIContext();

    const { deleteNode, toggleThreadMinimize } = useCollectionFunctions();

    const handleClose = useCallback(() => {
        deleteNode(node.node_id);
    }, [deleteNode, node.node_id]);

    const handleToggleMinimize = useCallback(() => {
        toggleThreadMinimize(node.node_id);
    }, [toggleThreadMinimize, node.node_id]);

    const handleDownload = useCallback(() => {
        ChatDownloadService.downloadThreadAsTxt(node);
    }, [node]);

    const headerProps = useMemo(() => ({
        thread: node,
        onClose: handleClose,
        onToggleMinimize: handleToggleMinimize,
        onDownload: handleDownload,
        isMinimized: node.isMinimized,
        isFounderModeEnabled,
        isMobile
    }), [node, handleClose, handleToggleMinimize, handleDownload, isFounderModeEnabled, isMobile]);

    const renderContent = () => {
        if (node.isMinimized) {
            return null;
        }

        return (
            <>
                <ThreadContent isResizing={isResizing} className="thread-content">
                    <ThreadContentContainer thread={node} />
                </ThreadContent>
                <ResizePlaceholder isResizing={isResizing} className="thread-resize-placeholder">
                    Resizing...
                </ResizePlaceholder>
                <ChatBarWrapper>
                    <ChatBar thread={node} className="thread-chat-bar" />
                </ChatBarWrapper>
            </>
        );
    };

    return (
        <ThreadWrapper
            width={node.width}
            isSelected={node.isSelected}
            className="thread-wrapper"
        >
            <ThreadHeader {...headerProps} className="thread-header" />
            {renderContent()}
        </ThreadWrapper>
    );
});

export default ThreadNode;