import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChatActions from './ChatActions';
import { useChatContext } from '../../../contexts/ChatContext';
import { UIContext } from '../../../contexts/UIContext';
import LLMControls from './LLMControls';
import SystemPromptBuilder from './PromptBuilder/SystemPromptBuilder';

const ActionBarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 10px;
  width: ${({ isChatRightActionBarOpen, actionBarWidth }) => (isChatRightActionBarOpen ? `${actionBarWidth}px` : '50px')};
  transition: width 0.3s;
  position: ${({ isMobile }) => isMobile ? 'fixed' : 'sticky'};
  top: 0;
  right: 0;
  height: ${({ isMobile }) => isMobile ? '100%' : 'calc(100vh - 55px)'};
  max-height: calc(100vh - 55px);
  overflow-y: auto;
  z-index: 1000;
  opacity: 0.9;
`;

const ChatRightActionBar = React.memo(() => {
    const { isMobile, isChatRightActionBarOpen, toggleChatRightActionBar, actionBarWidth, isFounderModeEnabled } = useContext(UIContext);
    const { systemPrompt, setSystemPrompt } = useChatContext();

    return (
        <ActionBarWrapper
            isChatRightActionBarOpen={isChatRightActionBarOpen}
            isMobile={isMobile}
            actionBarWidth={actionBarWidth}
        >
            <IconButton onClick={toggleChatRightActionBar} sx={{ color: '#fff', alignSelf: 'flex-start' }}>
                <MenuIcon />
            </IconButton>

            {isChatRightActionBarOpen && (
                <>
                    <ChatActions />
                    <LLMControls />
                    {isFounderModeEnabled && false && (
                        <SystemPromptBuilder
                            systemPrompt={systemPrompt}
                            setSystemPrompt={setSystemPrompt}
                        />
                    )}
                </>
            )}
        </ActionBarWrapper>
    );
});

export default ChatRightActionBar;