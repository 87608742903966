import React, { useCallback, useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { flushSync } from 'react-dom';
import isEqual from 'lodash/isEqual';

import ThreadNode from './Thread/ThreadNode';
import ThreadContainerNode from './ThreadContainerNode';
import UserMessageNode from './UserMessageNode';
import AssistantMessageNode from './AssistantMessageNode';
import ChatSearchResultNode from './ChatSearchResultNode';
import DocumentNode from './DocumentNode';
import CollectionNode from './CollectionNode';
import FilteredCollectionNode from './FilteredCollection/FilteredCollectionNode';

import { useUIContext } from '../../../contexts/UIContext';
import { useChatNodeWrapperFunctions } from '../../../contexts/ChatContext';
import { slotComponentMap } from './Slots/slotConfig';
import { useSlotPortalPositioning } from '../../../hooks/useSlotPortalPositioning';
import { debugLogger } from '../../../lib/debugLogger';
import { useNodeRegistry } from '../../../contexts/NodeRegistryContext';

const NodeWrapper = styled(Box)`
  margin-bottom: 8px;
  width: ${props => props.width};
  position: relative;
  overflow: visible;
  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  transition: all 0.1s;
  justify-content: center;
  
//   ${props => props.isResizing && `
//     will-change: width, height, opacity;
//     & * {
//       will-change: width, height, opacity;
//     }
//   `}

  will-change: width, opacity, contents;
  transform: translateZ(0);

  * {
    scrollbar-width: thin;
    scrollbar-color: #555 #333;
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #333;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
    border: 2px solid #333;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  position: relative;
  transition: all 0.1s;
  transition: box-shadow 0.2s ease-out;
    box-sizing: border-box;

  outline: 2px solid transparent;
  box-shadow: 0 0 8px rgba(74, 144, 226, 0);
  border-radius: 6px;
//   margin: 8px;
  ${({ isActive }) =>
        isActive &&
        `
    outline: 2px solid #4a90e2;
    box-shadow: 0 0 8px rgba(74, 144, 226, 0.8);
    
  `}
//   ${props => props.isResizing && `
//     will-change: width;
//   `}

transition: width 0.1s smooth;
  will-change: width;
  transform: translateZ(0);
`;

const BottomSlot = styled(motion.div)`
  margin-top: 8px;
  overflow: hidden;
`;

const PortalSlot = React.memo(({ children, targetRef, position = 'right', portalRoot }) => {
    const { isResizing } = useUIContext();
    const { portalPosition, isVisible, triggerUpdate, isInitiallyPositioned } = useSlotPortalPositioning(targetRef, position);

    useEffect(() => {
        if (isResizing) {
            portalRoot.style.display = 'none';
        } else if (targetRef.current && portalRoot && isInitiallyPositioned) {
            portalRoot.style.display = 'block';
            portalRoot.style.position = 'fixed';
            portalRoot.style.top = `${portalPosition.top}px`;
            portalRoot.style.left = `${portalPosition.left}px`;
            portalRoot.style.transform = position === 'right' ? 'translateY(-50%)' : 'translateX(-50%)';
            portalRoot.style.zIndex = '9999';
            portalRoot.style.transition = 'opacity 0.2s ease-out';
            portalRoot.style.opacity = '1';
        }

        // // Trigger an update after resizing is complete
        // if (!isResizing) {
        //     triggerUpdate();
        // }
    }, [targetRef, portalRoot, isResizing, portalPosition, position, triggerUpdate, isInitiallyPositioned]);

    // Only render when initially positioned and visible
    return (isVisible && isInitiallyPositioned && portalRoot) ? ReactDOM.createPortal(
        <div onClick={(e) => e.stopPropagation()}>{children}</div>,
        portalRoot
    ) : null;
}, (prevProps, nextProps) => {
    // Implement a custom comparison function if needed
    return prevProps.targetRef === nextProps.targetRef &&
        prevProps.position === nextProps.position &&
        prevProps.portalRoot === nextProps.portalRoot;
});

PortalSlot.displayName = 'PortalSlot';

const ChatNode = React.memo(({ node, isVisible = true }) => {
    // debugLogger.log('debug', '[ChatNode] Rendering ChatNode', node.type, node.node_id);
    const { registerNode, unregisterNode } = useNodeRegistry();
    const nodeRef = useRef(null);

    const [rightSlotPortal, setRightSlotPortal] = useState(null);
    const portalRef = useRef(null);

    useEffect(() => {
        console.log('Registering node', node.node_id);
        registerNode(node.node_id, nodeRef);
        // return () => unregisterNode(node.node_id);
    }, [node.node_id, registerNode, unregisterNode]);

    const { isResizing, baseThreadWidthRef } = useUIContext();
    const { selectNode } = useChatNodeWrapperFunctions();

    const isActive = useMemo(() =>
        node.isSelected &&
        !(node.type === 'content.chat.message.assistant' && (!node.content || node.content === "")),
        [node.isSelected, node.type, node.content]);

    useLayoutEffect(() => {
        if (isActive && !rightSlotPortal) {
            const newPortal = document.createElement('div');
            document.body.appendChild(newPortal);
            setRightSlotPortal(newPortal);
            portalRef.current = newPortal;
        }
        return () => {
            if (portalRef.current && document.body.contains(portalRef.current)) {
                document.body.removeChild(portalRef.current);
            }
            setRightSlotPortal(null);
            portalRef.current = null;
        };

    }, [isActive, baseThreadWidthRef]);


    const handleClick = useCallback((event) => {
        event.stopPropagation();
        if (!node.isSelected) {
            requestAnimationFrame(() => selectNode(node.node_id));
        }
    }, [node.node_id, node.isSelected, selectNode]);

    const SlotComponents = useMemo(() => slotComponentMap[node.type] || {}, [node.type]);

    const renderContent = useCallback(() => {
        switch (node.type) {
            case 'content.chat.thread':
                return <ThreadNode node={node} />;
            case 'content.chat.thread_container':
                return <ThreadContainerNode node={node} />;
            case 'content.chat.message.user':
                return <UserMessageNode node={node} />;
            case 'content.chat.message.assistant':
                return <AssistantMessageNode node={node} />;
            case 'content.chat.message.website_fulltext':
                return <ChatSearchResultNode node={node} />;
            case 'content.chat.message.document':
                return <DocumentNode node={node} />;
            case 'content.chat.collection':
                return <CollectionNode node={node} />;
            case 'content.chat.filtered_collection':
                return <FilteredCollectionNode node={node} />;
            default:
                console.error('Unknown chat node type', node.type);
                return null;
        }
    }, [node]);

    const isLeafNode = useMemo(() => !node.type.startsWith('content.chat.thread'), [node.type]);

    return (
        <NodeWrapper isVisible={isVisible} width={node.width} isResizing={isResizing} className="chat-node-outer-wrapper">
            {isLeafNode ? (
                <>
                    <InnerWrapper ref={nodeRef} isActive={isActive} onClick={handleClick} isResizing={isResizing} className="chat-node-inner-wrapper">
                        {renderContent()}
                    </InnerWrapper>
                    {isActive && isVisible && SlotComponents.right && rightSlotPortal && nodeRef.current && (
                        <PortalSlot
                            targetRef={nodeRef}
                            position="right"
                            portalRoot={rightSlotPortal}
                            key={node.node_id}
                        >
                            <SlotComponents.right node={node} />
                        </PortalSlot>
                    )}
                    <AnimatePresence>
                        {isActive && isVisible && SlotComponents.bottom && (
                            <BottomSlot
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.09 }}
                                className="chat-node-bottom-slot"
                            >
                                <SlotComponents.bottom node={node} />
                            </BottomSlot>
                        )}
                    </AnimatePresence>
                </>
            ) : (
                renderContent()
            )}
        </NodeWrapper>
    );
});
ChatNode.displayName = 'ChatNode';

export default ChatNode;