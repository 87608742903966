import { useEffect, useState, useRef, useCallback } from 'react';
import { throttle } from 'lodash';

export const useResizeObserver = (ref, options = {}) => {
    const [dimensions, setDimensions] = useState({ width: 1000, height: 1000 });
    const [isResizing, setIsResizing] = useState(false);
    const resizeObserver = useRef(null);
    const { throttleTime = 0, resizeTimeout = 200 } = options;
    const resizeTimeoutRef = useRef(null);

    const throttledSetDimensions = useCallback(
        throttle((width, height) => {
            setDimensions({
                width: Math.round(width),
                height: Math.round(height)
            });
            setIsResizing(true);

            if (resizeTimeoutRef.current) {
                clearTimeout(resizeTimeoutRef.current);
            }

            resizeTimeoutRef.current = setTimeout(() => {
                setIsResizing(false);
            }, resizeTimeout);
        }, throttleTime, { leading: false, trailing: true }),
        [throttleTime, resizeTimeout]
    );

    useEffect(() => {
        let rafId = null;

        resizeObserver.current = new ResizeObserver(entries => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }

            const entry = entries[0];
            const { width, height } = entry.contentRect;

            // Cancel the previous rAF if it hasn't executed yet
            if (rafId) {
                cancelAnimationFrame(rafId);
            }

            // Schedule a new update
            rafId = requestAnimationFrame(() => {
                throttledSetDimensions(width, height);
            });
        });

        if (ref.current) {
            resizeObserver.current.observe(ref.current);
        }

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
            if (rafId) {
                cancelAnimationFrame(rafId);
            }
            if (resizeTimeoutRef.current) {
                clearTimeout(resizeTimeoutRef.current);
            }
            throttledSetDimensions.cancel();
        };
    }, [ref, throttledSetDimensions]);

    return { dimensions, isResizing };
};