import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ResetIcon from '@mui/icons-material/Restore';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import VerticalSlider from './VerticalSlider';

const Root = styled(Box)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(0.625)};
  padding-right: ${({ theme }) => theme.spacing(0.625)};
`;

const TextColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const Title = styled(Typography)`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.palette.text.primary};
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-top: 0.25rem;
  align-items: center;
`;

const Description = styled(Typography)`
  font-size: 0.8rem;
  color: rgb(212, 215, 221);
  padding-left: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const BoostInfo = styled(Typography)`
  font-size: 0.8rem;
  color: rgb(212, 215, 221);
  padding-left: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const Slider = styled(VerticalSlider)`
  height: 120px;
`;

const ResetButton = styled(Button)`
  color: rgb(212, 215, 221);
  border-radius: 7px;
  font-size: 0.7rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  width: 3.6rem;
  height: 1.1rem;
  margin: 0.5rem 0rem 0rem 0.5rem;
  text-transform: none;
  border: 1px solid rgba(212, 215, 221, 0.7);

  &:hover {
    border: 1px solid #fff;
    color: #fff;
  }

  & .MuiSvgIcon-root {
    font-size: 0.7rem;
  }
`;

const debug = false;

const RankAdjuster = ({ name, title, description, value, onChange, onReset, icon: Icon, min = -500, max = 500, step = 100 }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);
  const [sliderValue, setSliderValue] = useState(value);

  let displaySliderValueText;
  if (sliderValue > 0) {
    displaySliderValueText = `Boost: +${sliderValue}`;
  } else if (sliderValue < 0) {
    displaySliderValueText = `Boost: ${sliderValue}`;
  } else {
    displaySliderValueText = "Boost: 0";
  }

  const handleSliderChange = (newValue) => {
    setSliderValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  return (
    <Root>
      <FlexBox>
        <TextColumn>
          <Title variant="h6">
            {Icon && <Icon style={{ marginRight: 8, fontSize: '0.9rem' }} />}
            {`${title}`}
          </Title>
          <Description variant="body2">
            {description}
          </Description>
          <BoostInfo variant="h6" sx={{ marginTop: 0 }}
            style={sliderValue !== 0 ? { color: sliderValue < 0 ? 'red' : 'green' } : {}}>
            {`${displaySliderValueText}`}
          </BoostInfo>
          <ResetButton onClick={onReset} size="small" startIcon={<ResetIcon />}>
            Reset
          </ResetButton>
        </TextColumn>
        <Slider value={sliderValue} onChange={handleSliderChange} min={min} max={max} step={step} />
      </FlexBox>
    </Root>
  );
};

export default RankAdjuster;